import React, { useContext, useMemo } from "react";
import { DetectDeviceContext } from "../../ctx/DetectDeviceContext";
import usePlayer from "../../ctx/PlayerContext";
import { useBooks } from "../../stores/Books.store";
import Box from "./Box";

/*This component takes in a book's name and finds the cover (if it exists) in the book store.*/
export default function Cover() {
    const { isDesktop } = useContext(DetectDeviceContext);
    const BookStore = useBooks()
    const { nameBook, littlePlayer } = usePlayer()

    const findCover = (bookName) => {
        const index = BookStore.booksCovers.findIndex((value) => {
            return bookName === value.book_name;
        });
        if (index !== -1) {
            return BookStore.booksCovers[index].cover_url;
        } else return false;
    };

    const boxW = () => {
        if (isDesktop) return "calc(20.89vh - 5%)";
        else return "26vh";
    };

    const cover = useMemo(() => {
        return findCover(nameBook);
    }, [nameBook])

    return (
        <>
            {!littlePlayer ? (
                cover ? (
                    <img src={cover} alt={nameBook} className="bTitle1" />
                ) : (
                    <Box text={nameBook}
                        width={boxW}
                        height={boxW}
                        fontSize="2.5vh"
                        containerClassName=""
                        className="bTitle1" />
                )
            ) : (
                cover ? (
                    <img src={cover} alt={nameBook} className="bTitle1" />
                ) : (
                    <Box text={nameBook}
                        width={isDesktop ? "4vh" : "6.65vh"}
                        height={isDesktop ? "4vh" : "6.65vh"}
                        fontSize="1vh"
                        containerClassName=""
                        className="bookTitle" />
                ))
            }
        </>
    )
}

import React from 'react';
import { GenericButton } from '../../components/GenericButton'
import '../style/player-speed.scss'

/*This component displays the chapter menu in the player.*/
export default function PlayerChapter(props) {
    return (
        <div className="bigPage" role="dialog" ref={props.dialogRef}>
            <div className='speeds'>
                <div className='lt'>
                    <span></span>
                    <p className='speedTitle'>קטעים</p>
                    <GenericButton
                        style={{ backgroundColor: 'inherit', height: 'fit-content' }}
                        onClick={() => props.setShowChapters(false)}
                        ariaLabel="סגירת חלון בחירת פרק" >
                        <img src="/media/icons/xButton.svg" className='x' alt="אייקון סגירה" />
                    </GenericButton>
                    {/* credits: Icons made by <a href="https://www.flaticon.com/authors/google" title="Google">Google</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a> */}
                </div>
                <div className={props.chapters.length > 12 ? "list long-list" : 'list'}>
                    {props.chapters.map((c, i) => {
                        let p = <p className='num'>קטע {c}</p>
                        if (c === props.chapter) {
                            p = <div className='current'>
                                <p className='num bold'>
                                    קטע {c}
                                </p>
                                <img className='check' src='/media/icons/check.svg' alt="check mark" />
                            </div>;
                            // credits : 
                            // <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                        }
                        return (
                            c ?
                                <GenericButton
                                    key={c}
                                    className="chapter-button"
                                    ariaLabel={`קטע ${c}`}
                                    onClick={() => { props.setChapter(c); props.setShowChapters(false) }} >
                                    {p}
                                    <hr />
                                </GenericButton> :
                                <div key={i + 1}>
                                    <div className='num none'>
                                        {c}
                                    </div>
                                    <hr />
                                </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from "../ctx/UserContext";
import { useObserver } from "mobx-react-lite";
import { useLogout } from "@hilma/auth";
import BooksRead from '../components/BooksRead';
import { useHistory } from "react-router-dom";
import "../style/profile.scss";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { LogOut } from '../components/LogOut';
import { useBooks } from '../stores/Books.store';
import Header from "../components/Header";


/*This is the user profile page. It renders different things depending on whether the user is a student or a teacher*/
export default function Profile() {
  const [phoneName, setPhoneName] = useState("");

  const { name, booksRead, setBooksRead, profileType } = useContext(UserContext);
  const { isDesktop, isTablet } = useContext(DetectDeviceContext);
  const BookStore = useBooks();
  const logout = useLogout();
  const history = useHistory();

  useEffect(() => {
    setBooksRead(BookStore.doneBooks.length);
  }, [BookStore.doneBooks.length]);

  useEffect(() => {
    if (`${name.split(" ")[0]} ${name.split(" ")[1]}` === undefined) return;
    else setPhoneName(name.split(" ")[0]);
  }, [name]);

  const onLogout = () => {
    logout();
    window.location.reload();
  };

  const goToAnotherPage = (route) => {
    history.push(route || "/");
  };

  const handleEditProfile = () => {
    goToAnotherPage("/settings");
  };

  return useObserver(() => (
    <React.Fragment>
      <div className={isDesktop ? "web-version" : "phone-profile"}>
        {!isDesktop ?
          <>
            <Header title="פרופיל" />
            <div className="profile-header">
              <h1 className="profile-title">{name}</h1>
              <button className="profile-buttons" onClick={handleEditProfile}>
                <img className="edit-image" src={`/media/icons/edit-icon-blue.png`} alt="edit profile" />
                <h2 className="edit-profile">עריכת פרופיל</h2>
              </button>
            </div>
          </>
          :
          <div className="profile-header">
            <h1 className="profile-title">{name}</h1>
            <button className="profile-buttons" onClick={handleEditProfile}>
              <img className="edit-image" src={`/media/icons/edit-icon-blue.png`} alt="edit profile" />
              <h2 className="edit-profile">עריכת פרופיל</h2>
            </button>
            {/* {isDesktop && <button className="profile-buttons" onClick={handleEditProfile}>
              <img className="edit-image" src={`/media/icons/edit-icon-pink.png`} alt="edit profile" />
              <h2 className="edit-profile">עריכת פרופיל</h2>
            </button>
            } */}
          </div>
        }
        {/* {(!isDesktop || isTablet) && <button className="profile-buttons" onClick={handleEditProfile}>
          <img className="edit-image" src={`/media/icons/edit-icon-blue.png`} alt="edit profile" />
          <h2 className="edit-profile">עריכת פרופיל</h2>
        </button>} */}
        {(BookStore.doneBooks.length === 0 && BookStore.isBookshelfEmpty && profileType === "student") ?
          <div style={{ height: `${isDesktop ? "60vh" : "48vh"}`, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <BooksRead name={name} booksRead={0} />
          </div> :
          <div>
            {profileType === "student" ?
              <BooksRead name={name} booksRead={booksRead} />
              : null
            }
          </div>
        }
        <div className="personalInfo">
          {/* <div className={isDesktop ? "web-stuff" : ""}>
            <div className="personalFlex">
              <div>
                <h3 className="no-pointer">החשבון שלך</h3>
                <p>{profileType === "student" ? "תלמיד" : "מורה"}</p>
              </div>
              {profileType === "student" ? <button className="blindLibrary">לפרופיל הספריה</button> : null} 
            </div>

            {isDesktop ? null :
              <button onClick={() => goToAnotherPage("/contactus")} className="contactUs">
                <h3>צור קשר</h3>
                <h3>&gt;</h3>
              </button>
            }

            <button onClick={() => goToAnotherPage("/settings")} className='settings'>
              <h3>הגדרות</h3>
              <img className="settings-icon" src="/media/icons/settings.svg" alt="אייקון של הגדרות, מעבר לעמוד הגדרות" />
            </button>

            <button className="logOut" onClick={onLogout}>
              <h3>התנתקות</h3>
              <img src="/media/icons/log-out.svg" alt="אייקון התנתקות" className="log-out-icon" />
              Credits: <div>Icons made by <a href="http://www.freepik.com/" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            </button>
          </div> */}
        </div>
      </div>
    </React.Fragment >
  ));
  //! if you uncomment the above, you need to comment two lines one that start with
  //TODO: "{profileType === "student" ? <button className="blindLibrary">לפרופיל הספריה</button> : null}"
  //TODO: "Credits: <div>Icons made by <a href="http://www.freepik.com/" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>"
}


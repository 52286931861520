import { action, observable, makeObservable } from "mobx";
import Axios from 'axios';
import ErrorFunction from "../errorFunction";
import { BooksContext } from "./Books.store"
import { createMobXContext } from "@hilma/tools";

class DashboardStore {
    students = [];
    studentsNames = [];
    books = [];
    teacherBooksEmpty = false
    pushedBooks = []
    grades = [];
    search = "";
    loading = true;
    error = "";
    studentsNotApproved = [];
    static contextType = BooksContext;

    constructor() {
        makeObservable(this, {
            students: observable,
            books: observable,
            grades: observable,
            search: observable,
            loading: observable,
            teacherBooksEmpty: observable,
            pushedBooks: observable,
            studentsNotApproved: observable,
            setStudents: action,
            setBooks: action,
            setSearch: action,
            removeNotApproved: action,
        });
    };

    /*Calls setStudents and setBooks*/
    setArrays = async (cover, setName) => {
        try {
            await this.setStudents(setName);
            await this.setBooks(cover);
            this.loading = false;
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                this.error = error.response.data.error;
            } else {
                const err = ErrorFunction(error);
                this.error = err;
            }
        }
    };

    /*Takes in a string value. If it is different from the current search, it sets the value as the new search.*/
    setSearch = (value) => {
        this.search = value;
    };

    /*This function performs a search for a book name or author.*/
    searchByBook = (value) => {
        let result = value
        if (this.search !== "") {
            result = this.books.findIndex(book =>
                book.book_name.toLowerCase().includes(this.search.toLowerCase())
                || book.author_name.toLowerCase().includes(this.search.toLowerCase())
            )
        };
        return result
    };

    /*This function performs a search for a student first or last name.*/
    searchByStudent = (value) => {
        let result = value
        if (this.search !== "") {
            result = this.students.findIndex(student =>
                student.name.includes(this.search)
            )
        }
        return result
    };

    /*Gets all of the teacher's students' info from the database and makes
      an array of students and the books in their bookshelves.*/
    setStudents = async (setName) => {
        try {
            const result = await Axios.get('/api/class/getTeacherBooks')
            setName(result.data.name);
            const grades = [];
            result.data.books?.forEach(classes => {
                let userLength = false;
                if (classes.users.length > 0) userLength = true;
                grades.push({
                    id: classes.id,
                    grade: classes.grade,
                    number: classes.number,
                    school_name: classes.school.name,
                    school_id: classes.school.id,
                    student: userLength,
                });
                for (let i = 0; i < classes.users.length; i++) {
                    const user = classes.users[i];
                    if (user.entered === false) {
                        this.studentsNotApproved.push({ name: user.name, username: user.username });
                    };
                    let books = [];
                    user.book_student?.forEach(bo => {
                        const author = bo.book.author.replace(/@@/g, ", ").trim();
                        let book = {
                            id: bo.book.id,
                            book_name: bo.book.book_name,
                            author_name: author.substring(0, author.length - 1).trimEnd(),
                            series: bo.book.series,
                            summary: bo.book.summary,
                            genre: bo.book.genre,
                            number_pages: bo.book.number_pages,
                            audio_length: bo.book.audio_length,
                            audio_type: bo.book.audio_type,
                            publication_date: bo.book.publication_date.split("T")[0],
                            publication_house: bo.book.publication_house,
                            translator: bo.book.translator,
                            chapters_number: bo.book.chapter.length,

                            feedback_title: bo.feedback_title,
                            feedback: bo.feedback,
                            general_rating: bo.general_rating,
                            quality: bo.quality,
                            story: bo.story,
                            current_time: bo.current_time,
                            chapter: bo.chapter,
                            time_chapter: bo.time_chapter,
                            book_finished: bo.book_finished,
                        }
                        books.push(book)
                    });
                    let student = {
                        name: user.name,
                        username: user.username,
                        profileImg: user.profile_index,
                        books: books,
                        class: `${classes.grade}${classes.number}` /* classes.id */,
                    };
                    this.students.push(student)
                };
            });
            this.grades = grades;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                this.error = error.response.data.error;
            } else {
                const err = ErrorFunction(error);
                this.error = err;
            }
        }
    };

    /*Gets all of books that are in the bookshelves of the students from the database and
      makes an array of books and the students that are reading them.*/
    setBooks = async (cover) => {
        this.books = [];
        this.students?.forEach(stu => {
            if (stu.books.length === 0) {
                this.teacherBooksEmpty = true;
            }
            stu.books?.forEach(book => {
                let student = {
                    name: stu.name,
                    feedback_title: book.feedback_title,
                    feedback: book.feedback,
                    general_rating: book.general_rating,
                    quality: book.quality,
                    story: book.story,
                    current_time: book.current_time,
                    chapter: book.chapter,
                    time_chapter: book.time_chapter,
                    book_finished: book.book_finished,
                };
                let find = this.books.findIndex((value) => value.id === book.id);
                if (find === -1) {
                    let result = {
                        id: book.id,
                        book_name: book.book_name,
                        author_name: book.author_name,
                        series: book.series,
                        summary: book.summary,
                        genre: book.genre,
                        number_pages: book.number_pages,
                        audio_length: book.audio_length,
                        audio_type: book.audio_type,
                        publication_date: book.publication_date,
                        publication_house: book.publication_house,
                        translator: book.translator,
                        chapters_number: book.chapters_number,

                        students: [student]
                    };
                    this.books.push(result);
                    if (cover) cover(book.book_name, book.author_name);
                } else {
                    this.books[find].students.push(student);
                };
            })
        })
        this.setPushedBooks()
    }

    /*Checks if the book that the teacher is pushing is already in the student's bookshelf. 
      It it is, it will not add the book again.*/
    setPushedBooks = async () => {
        let tempBooks = []
        let studentsNum = this.students.length
        this.books?.forEach(book => {
            if (book.students.length === studentsNum) {
                tempBooks.push(book.id)
            }
        })
        this.pushedBooks = tempBooks
    }

    /*This function adds a book to all of the teacher's students' bookshelves.*/
    addBooksToStudents = async (studentsArr, book) => {
        let bookInfo = this.books.find(bk => { return bk.id === book.id })

        if (!bookInfo) {
            this.books.push({
                id: book.id,
                book_name: book.book_name,
                author_name: book.author_name,
                audio_length: book.audio_length,
                chapters_number: book.chapters_number,
                summary: book.summary,
                students: []
            })
            bookInfo = this.books[this.books.length - 1]
        }

        studentsArr?.forEach(student => {
            let studentInfo = this.students.find(st => { return st.username === student })
            studentInfo.books.push(book)

            bookInfo.students.push({
                book_finished: false,
                current_time: 0,
                time_chapter: 0,
                chapter: 1,
                name: studentInfo.name
            })
        })
    }

    removeNotApproved = (username) => {
        const index = this.studentsNotApproved.findIndex(student => student.username === username);
        this.studentsNotApproved.splice(index, 1);
    };
}


const dashboardStore = new DashboardStore();
export const [DashboardContext, DashboardsProvider, useDashboard] = createMobXContext(dashboardStore);
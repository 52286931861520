import React, { useContext } from "react";
import { useGenAlert } from "../ctx/GenAlertContext"
import ErrorFunction from "../errorFunction";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import Axios from "axios";
import Login from "./../components/Login"
import "../style/open.scss";

/*This is the page that renders the user login. For now, it currently also renders the admin.*/
const Open = (props) => {
  const genAlertCtx = useGenAlert()
  const { isDesktop } = useContext(DetectDeviceContext);
  /*This function allows the admin to add a new teacher to the database by clicking one of the icons in the login page.
    When the application has a real admin side, this will be deleted.*/
  // const fakeAdmin = async () => {
  //   let username = prompt("username", "@gmail.com")
  //   let name = prompt("name", "סטיב")
  //   let password = prompt("password", "")
  //   if (username !== null && username !== "" && name !== null && name !== "" && password !== null && password !== "") {
  //     try {
  //       await Axios.post(`/api/user/newTeacher`, {
  //         username: username,
  //         name: name,
  //         password: password,
  //       });
  //     } catch (error) {
  //       genAlertCtx.openGenAlert({ text: ErrorFunction(error) })
  //     }
  //   } else {
  //     genAlertCtx.openGenAlert({ text: "משהו השתבש" })
  //   }
  // }

  return (
    <div className={isDesktop ? "open-web" : "open-phone"}>
      <Login {...props} />
    </div>
  );
}

export default Open;

import { useContext } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { DetectDeviceContext } from '../../ctx/DetectDeviceContext';
import "../../style/privacyAndTerms.scss";


const TermsOfUse = () => {

    const { isMobile } = useContext(DetectDeviceContext);

    const TermsOfUseTexts = [
        {
            title: "כללי",
            color: "red",
            number: 1,
            texts:
                [{
                    numb: 1.1,
                    par: `אתר ספריה בהקשבה מבית הספריה המרכזית לעיוורים ולבעלי מוגבלויות קריאה (ע"ר)  (להלן: "האתר" או "הספריה")  הינו מיזם  שמטרתו הנגשת ספרי אודיו לילדים המתקשים בקריאה.`,
                },
                {
                    numb: 1.2,
                    par: "בכל מקום בתקנון זה בו משתמשים בלשון זכר, הכוונה גם לנקבה.",
                },
                {
                    numb: 1.3,
                    par: "הוראות תקנון זה יחולו על כל שימוש ו/או גלישה שיעשו על ידך באתר ובאמצעותו, ויהוו את הבסיס המשפטי ליחסים בינך לבין הספריה, עקב כך, הנך מתבקש לקרוא בעיון תקנון זה במלואו וכן את מדיניות השמירה על פרטיות.",
                },
                {
                    numb: 1.4,
                    par: "למען בהירות הדברים והסר ספק, הגלישה באתר ו/או השימוש בחומרים שמקורם באתר הספריה, מהווה את  הסכמתך לקבל ולנהוג לפי התקנון. לכן אם אינך מסכים לתנאי מתנאיו הנך מתבקש לא לעשות כל שימוש באתר.",
                },
                {
                    numb: 1.5,
                    par: "הספריה שומרת לעצמה את הזכות לשנות תקנון זה בכל עת ומכל סיבה שהיא על פי שיקול דעתה הבלעדי.",
                }]
        },
        {
            title: "הרשמה ופעולות באתר",
            color: "yellow",
            number: 2,
            texts:
                [{
                    numb: 2.1,
                    par: "דף הבית של הספריה בהקשבה פתוח לציבור הרחב, אולם שירותי הספריה מיועדים אך ורק לשימוש בעלי מוגבלות קריאה זכאים, אשר נרשמו לשירותי הספריה בהקשבה, בהתאם לנהלי הרישום.",
                },
                {
                    numb: 2.2,
                    par: "תהליך הרישום לתלמידים בעלי מוגבלות קריאה מחייב מילוי טופס רישום, מילוי פרטי הרשמה באתר הראשי של הספריה המרכזית לעיוורים ובעלי מוגבלויות קריאה, הבאת אישור הורים (לקטינים שטרם מלאו להם 18) והבאת אישור מבעל מקצוע המאשר את זכאות התלמיד לשימוש בשירותי הספריה בהקשבה בשל מוגבלות קריאה. לתיאור נוסף ל תהליך הרישום ניתן למצוא כאן.",
                },
                {
                    numb: 2.3,
                    par: "הספריה בהקשבה לא תוכל לספק שירותים למי שלא ימלא את הפרטים כאמור, או לא יביא את האישורים כנדרש, בהתאם לדרישות הספריה.  שירותים לספריה שמורה הזכות להגדיר את תנאי הזכאות לשימוש בשירותיה, ולשנותם מעת לעת ועל פי כל דין. ",
                }]
        },
        {
            title: "קנין רוחני",
            color: "blue",
            number: 3,
            texts:
                [{
                    numb: 3.1,
                    par: ` הספריה המרכזית לעיוורים ובעלי מוגבלויות קריאה, וכך גם הספריה בהקשבה, פועלות בכפוף לחוקים המסדירים  הנגשת יצירות לבעלי מוגבלויות, ובכלל זה הוראות בסעיף 28א'  לחוק זכויות יוצרים, תש"ח-2007. הרשאת זכויות הקנין הרוחני לבעלי מוגבלויות עולה גם בקנה אחד עם  אמנת מרקש של ארגון WIPO הבינלאומי, אשר מדינת ישראל אימצה.  בשל תנאים אלה, שירותי הספריה יינתנו אך ורק עבור ובקשר עם בעלי מוגבלויות קריאה, בהתאם לתנאי ההסדרה האמורה.`,
                },
                {
                    numb: 3.2,
                    par: " ללא קבלת אישור מתאים, תקין וחוקי, אין הספריה יכולה לשרת את האדם הפונה בבקשה להיות מנוי. ",
                },
                {
                    numb: 3.3,
                    par: "במידה וחל שינוי משמעותי ביכולת הקריאה של המנוי, באחריותו לדווח לספריה על השינוי האמור במצבו, ולהמציא את האישורים הדרושים לצורך המשך רישומו לשרותיה ספריה,  במידה והוא מעוניין בשירות.",
                },
                {
                    numb: 3.4,
                    par: "השימוש בספרי האודיו ובכל שירות אחר של הספריה בהקשבה, ככל שיהיה,  מותר לשימוש למטרות פרטיות ולימודיות בלבד, ולא ייעשה בהם כל שימוש מסחרי. הספריה רשאית להפסיק את המנוי לכל משתמש אשר עובר על הוראות תקנון זה, וזאת בנוסף לכל סעד חוקי אחר.",
                },
                {
                    numb: 3.5,
                    par: "כל מידע  ו/או תצוגה המופיעים באתר, לרבות גרפיקה, עיצוב, הצגה מילולית, סימני מסחר, סימני לוגו וכן עריכתם והצגתם של אלה, הנם בבעלות בלעדית של הספריה ו/או מי מטעמה. אין להעתיק לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת את התכנים המופיעים באתר אלא אם כן הספריה נתנה את הסכמתה לכך, בכתב ומראש.",
                }]
        },
        {
            title: "אחריות",
            color: "purple",
            number: 4,
            texts:
                [{
                    numb: 4.1,
                    par: "הספריה ו/או מי מטעמה לא יהיו אחראים ולא יישאו בכל נזק ישיר או עקיף, תוצאתי או מיוחד שיגרם למשתמש או לצד שלישי, כתוצאה משימוש או רכישה באמצעות האתר - תהא עילת התביעה אשר תהא - לרבות הפסד הכנסה ו/או מניעת רווח שיגרמו מכל סיבה שהיא. למען הזהירות והסר כל ספק, הספריה אינה אחראית לכל הפרעה ו/או כשל ו/או שגיאה ו/או טעות ו/או החסרה באתר ו/או בתכניו של האתר ו/או בנתוניו ו/או ביישומיו השונים ו/או בפעילותו לרבות בגין כל נזק אשר ייגרם לרכושו של המשתמש לרבות מחשבו ו/או כל ציוד קצה אחר בשימושו של המשתמש.",
                },
                {
                    numb: 4.2,
                    par: "הספריה או מי מטעמה יספקו שירותי תמיכה בסיסיים בכל הנוגע להתחברות ושימוש באתר.",
                },
                {
                    numb: 4.3,
                    par: "הספריה רשאית להפסיק או לשנות שירות זה בכל עת.",
                }]
        }
        ,
        {
            title: "שונות",
            color: "orange",
            number: 5,
            texts:
                [{
                    numb: 5.1,
                    par: "פרשנותו ואכיפתו של תקנון זה ו/או כל פעולה או סכסוך הנובע ממנו, יעשו בהתאם לחוקי מדינת ישראל"
                }]
        }
    ]

    return (
        <>
            <Header title="תנאי שימוש באתר" />

            <div className="privacy-terms-page">
                {isMobile ? "" : <div className="privacy-terms-title">תנאי שימוש באתר</div>}
                <div className="privacy-terms-texts">
                    {TermsOfUseTexts.map((item) => {
                        return (
                            <div className="privacy-terms-part">
                                <div className="privacy-terms-text-title">
                                    <span>{item.number}. {item.title}</span>
                                    <div className={`privacy-terms-${item.color}`}></div>
                                </div>

                                <div className="privacy-terms-inside">
                                    {item.texts.map((t) => {
                                        return (
                                            <span className="part"><span style={{ fontWeight: "bold" }}>{t.numb}</span>{" "}{t.par}</span>
                                        )
                                    })}
                                </div>

                            </div>
                        )
                    })}

                    <p style={{ marginBottom: "10vh", alignSelf: "flex-end", fontWeight: "bold", fontSize: "3vh" }}>קריאה מהנה, צוות הספריה</p>
                </div>
            </div>
            {isMobile ? "" : <Footer />}

        </>
    )
}

export default TermsOfUse;
import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "axios";
import ErrorFunction from "../errorFunction";
import { useGenAlert } from "./GenAlertContext";

export const PlayerContext = React.createContext();
export const PlayerProvider = ({ children }) => {
  const [speed, setSpeed] = useState("1.0");
  const [littlePlayer, setLittlePlayer] = useState(false);
  const [ifPlayer, setIfPlayer] = useState(false);
  const [showSpeeds, setShowSpeeds] = useState(false);
  const [showChapters, setShowChapters] = useState(false);
  const [chapter, setChapter] = useState(1);
  const [littlePlayerAnimtion, setLittlePlayerAnimtion] = useState(false);
  const [bookId, setBookId] = useState()
  const [isFinished, setIsFinished] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [numChapters, setNumChapters] = useState();
  const [bookStudentId, setBookStudentId] = useState();
  const [studentBooks, setStudentBooks] = useState();
  const [nameBook, setNameBook] = useState("");
  const [bookCode, setBookCode] = useState("");
  const [nameAuthor, setNameAuthor] = useState("")
  const [startPlay, setStartPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const genAlertCtx = useGenAlert();
  const lastFocusedElement = useRef(null)

  /*Gets the number of chapters.*/
  useEffect(() => {
    (async () => {
      try {
        if (bookId) {
          let result = await Axios.get(`/api/books/getNumChaptersInBook/${bookId}`);
          const chapters1 = [];
          for (let i = 1; i <= result.data; i++) {
            chapters1.push(i);
          }
          for (let i = 0; chapters1.length < 14; i++) {
            chapters1.push("");
          }
          setChapters(chapters1);
          setNumChapters(result.data);
        }
      } catch (error) {
        genAlertCtx.openGenAlert({ text: ErrorFunction(error) });
      }
    })();
  }, [bookId]);

  const saveLastElement = (element) => {
    lastFocusedElement.current = element
  }

  const ctxValue = {
    speed,
    setSpeed,
    littlePlayer,
    setLittlePlayer,
    ifPlayer,
    setIfPlayer,
    showSpeeds,
    setShowSpeeds,
    showChapters,
    setShowChapters,
    chapter,
    setChapter,
    chapters,
    setChapters,
    littlePlayerAnimtion,
    setLittlePlayerAnimtion,
    bookId,
    setBookId,
    numChapters,
    bookStudentId,
    setBookStudentId,
    studentBooks,
    setStudentBooks,
    isFinished,
    setIsFinished,
    nameBook,
    setNameBook,
    nameAuthor,
    setNameAuthor,
    startPlay,
    setStartPlay,
    playing,
    setPlaying,
    bookCode,
    setBookCode,
    lastFocusedElement,
    saveLastElement
  };

  return (
    <PlayerContext.Provider value={ctxValue}>{children}</PlayerContext.Provider>
  );
};


const usePlayer = () => useContext(PlayerContext)
export default usePlayer;

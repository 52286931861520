import React, { useContext } from 'react';
import { useRef } from 'react';
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import '../style/popup.scss';
import { FocusTrap } from './FocusTrap';

/**
 * close- The function that will allow the popup to close (for example by changing the state)
 * delete- The path to whichever delete icon you chose
 * imgSrc- A path to any image you want in the middle of the popup
 * text- Any text you want in the popup
 * text1- Any text you want in button 1
 * function1- Any functionality you want to add to button 1
 * text2- Any text you want in button 2
 * function2- Any functionality you want to add to button 2
 */
function Popup(props) {
    const { isDesktop } = useContext(DetectDeviceContext);
    const popup = useRef(null)

    const closePopUp = (e) => {
        e.preventDefault();
        props.close()
    }

    return (
        <FocusTrap trapRef={popup} >
            <div className={`popup-background generic ${isDesktop ? "web-version" : ""}`}
                style={{ height: `${100 * props.newVh} !important` }} 
                aria-label={props.text} >
                <div className="bounce-7">
                    <div ref={popup} className="body saved-popup">
                        <button aria-label={"סגירת חלון " + (props.text || "")} className="close-popup" onClick={closePopUp}>
                            <img src={props.delete} alt="כפתור סגירה" className="popup-icon" />
                        </button>
                        {!props.bookPage ?
                            <div className="popup-text" aria-live="assertive" >
                                {!props.imgsrc ? null : <img src={props.imgsrc} alt="" className="popup-img" />}
                                {props.text}
                                <div className="popup-buttons">
                                    {props.text1 ? <button aria-label={props.text1} onClick={props.function1 || (() => { })}
                                        className="popup-button">{props.text1}</button> : null}
                                    {props.text2 ? <button aria-label={props.text1} onClick={props.function2 || (() => { })}
                                        className="popup-button">{props.text2}</button> : null}
                                </div>
                            </div> :
                            <div className="popup-text bp-text" aria-live="assertive" >
                                <span className="bp-thanks">תודה!</span>
                                <span>{props.text}</span>
                            </div>}
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
}

export default Popup;
import React, { useContext } from 'react'
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import '../style/generalAlerts.scss'

/*This component generates alerts for errors that happen in the app.*/
export const GeneralAlert = ({ text, warning, center, noTimeout = false }) => {
    const { isDesktop } = useContext(DetectDeviceContext);

    return (
        <div style={{ height: "0", position: "sticky", bottom: isDesktop ? "0" : "10vh", left: "0" }}>
            <div id="general-alert-container" style={{ whiteSpace: "nowrap" }} className={`${warning ? "warning-color" : "default-color"} ${center ? "center" : ""} ${noTimeout ? "" : "timeout-animation"}`} >
                {text}
            </div>
        </div>
    );
}
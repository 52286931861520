import React, { useRef, useState, useEffect, useContext } from "react";
import { isIOS, isMacOs } from "react-device-detect";
import usePlayer from "../../ctx/PlayerContext"; // context
import { InputContext } from "../../ctx/InputContext";
import { useGenAlert } from "../../ctx/GenAlertContext";
import CircleLoading from "../../components/Loader.jsx";
import ErrorFunction from "../../errorFunction";
import LittlePlayer from "./LittlePlayer";
import ReactPlayer from "react-player"; // player
import BigPlayer from "./BigPlayer";
import Axios from "axios";
import "react-player-circle-controls/dist/styles.css"; // style circle player
import "../style/player.scss"; // style big player
import "../style/player-little.scss"; // style little player
import { DetectDeviceContext } from "../../ctx/DetectDeviceContext";
import { useBooks } from "../../stores/Books.store";
import { useAsyncEffect } from "@hilma/tools";
import { ClosePlayer } from "../components/ClosePlayer";


/* render all that conect to the player */
export default function Player(props) {

  const player = useRef(null); // the data from the player

  const BookStore = useBooks();
  // context

  const { setPopupOpen } = useContext(InputContext);
  const { littlePlayer, setLittlePlayer, speed, chapter, numChapters, setIsFinished, setIfPlayer, setChapter, setStartPlay, showChapters, showSpeeds, setShowSpeeds, setShowChapters, littlePlayerAnimtion, startPlay, setLittlePlayerAnimtion, bookId, nameBook, nameAuthor, playing, setPlaying, bookCode, lastFocusedElement } = usePlayer();

  const genAlertCtx = useGenAlert();
  const { isDesktop } = useContext(DetectDeviceContext);

  // use state
  const [timeAll, setTimeAll] = useState("00:00");//time of chapter
  const [urlChapter, setUrlChapter] = useState();
  const [firstTime, setFirstTime] = useState(true);
  const [loading, setLoading] = useState(false);
  const [bookIdCopy, setBookIdCopy] = useState(bookId);
  const [chapterCopy, setChapterCopy] = useState(chapter);
  const [time, setTime] = useState();// current time
  const [error, setError] = useState();
  const [urlChapters, setUrlChapters] = useState();
  const [miniLoading, setMiniLoading] = useState(false);
  const [playerState, setPlayerState] = useState({
    played: 0,
    loaded: 0,
  }); // belong to circle player
  const [bookInfo, setBookInfo] = useState({})
  // let bookInfo;


  // when I leave the page - if there's an error, it's deleting it
  useEffect(() => {
    return () => {
      if (BookStore.ifError) {
        BookStore.error = "";
        BookStore.ifError = false;
      }
    };
  }, []);




  // get chapter's urls 
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (chapter) {
  //         if (urlChapters) {
  //           const index = urlChapters.findIndex(element => chapter === element.number);
  //           console.log(index, 'index in case there is already chapterUrl')
  //           if (index !== -1) {
  //             setUrlChapter(urlChapters[index].url);
  //           } else {
  //             let result = await Axios.get(
  //               `/api/user/getUrlsChapters/${bookCode}`
  //             );
  //             console.log(result, 'result1')
  //             if (!result.data.HasError) {
  //               const chapters = [];
  //               for (let i = 0; i < result.data.Files.length; i++) {
  //                 const chapter = result.data.Files[i];
  //                 chapters.push({ url: chapter.Url, number: i + 1 })
  //               };
  //               setUrlChapters(chapters)
  //               const indexNew = chapters.findIndex(element => chapter === element.number);
  //               if (indexNew !== -1) {
  //                 setUrlChapter(chapters[indexNew].url);
  //               } else {
  //                 setError(ErrorFunction(error))
  //               }
  //             } else {
  //               console.log('error 1')
  //               setError(result.data.ErrorDescription);
  //             }
  //           }
  //         } else {
  //           let result = await Axios.get(
  //             `/api/user/getUrlsChapters/${bookCode/* '000041020' */}`
  //           );
  //           console.log(result, 'result2')
  //           if (!result.data.HasError) {
  //             const chapters = [];
  //             if (!result.data.files.length) {
  //             }
  //             for (let i = 0; i < result.data.Files.length; i++) {
  //               const chapter = result.data.Files[i];
  //               chapters.push({ url: chapter.Url, number: i + 1 })
  //             };
  //             setUrlChapters(chapters)
  //             const indexNew = chapters.findIndex(element => chapter === element.number);
  //             if (indexNew !== -1) {
  //               setUrlChapter(chapters[indexNew].url);
  //             } else {
  //               console.log('error 2')
  //               setError(ErrorFunction(error))
  //             }
  //           } else {
  //             console.log('error 3')
  //             setError(result.data.ErrorDescription);
  //           }
  //         }
  //       } else {
  //         console.log('error 4')
  //         setError(ErrorFunction(error))
  //       }
  //     } catch (error) {
  //       console.log('error 5')
  //       setError(ErrorFunction(error))
  //     }
  //   })();
  // }, [chapter, bookId, bookCode]);


  useEffect(() => {
    (async () => {
      try {
        if (chapter && bookId && bookCode) {

          if (urlChapters) {
            const index = urlChapters.findIndex(element => chapter === element.number);
            setUrlChapter(urlChapters[index].url);

          } else {
            const result = await Axios.get(`/api/user/getUrlsChapters/${bookCode}`);

            const urlArray = result.data.Files;

            if (urlArray.length === 0) {
              setError('טרם הוקלטו קבצי שמע לספר זה,\nנסה שנית מאוחר יותר');
            } else {
              const chapters = [];
              if (urlArray[0]?.Name.includes(".mp3")) {
                for (let i = 0; i < urlArray.length; i++) {
                  const chapter = urlArray[i];
                  chapters.push({ url: chapter.Url, number: i + 1 });
                };
              } else {
                for (let i = 1; i < urlArray.length; i++) {
                  const chapter = urlArray[i];
                  chapters.push({ url: chapter.Url, number: i });
                };
              }
              setUrlChapters(chapters);
              const indexNew = chapters.findIndex(element => chapter === element.number);
              setUrlChapter(chapters[indexNew].url);
            }
          }
        } else {
          setError(ErrorFunction(error));
        }
      } catch (error) {
        setError(ErrorFunction(error));
      }
    })();
  }, [chapter, bookId, bookCode]);

  useEffect(() => {
    TimeChapter();
    bookChange();
  }, [bookId]);

  // send current time when changing book
  // update date for book for last heard books
  const bookChange = async () => {
    setUrlChapters();
    try {
      const book = await BookStore.myBooks.find(myB => bookId === myB.id);
      if (book) {
        BookStore.updateLastBook(book, "update");
      }

      await Axios.post('/api/user/updateFirst', { book: bookId });
      const timeFinished = player.current.getCurrentTime();
      await Axios.post(`/api/user/bookChange`, {
        bookStudent: { book: bookIdCopy, chapter: chapterCopy, time_chapter: timeFinished - 1, },
        book: bookId,
        firstTime: firstTime
      });
      if (!firstTime) {
        if (chapterCopy && bookIdCopy) {
          setBookIdCopy(bookId);
          setChapterCopy(chapter);
          setPlaying(false);
          setFirstTime(true);
        }
      }
    } catch (error) {
      genAlertCtx.openGenAlert({ text: ErrorFunction(error) });
    }
  };

  // get current time
  const TimeChapter = async () => {
    try {
      setFirstTime(true);
      bigScreen();
      let result = await Axios.get(`/api/user/getTimeChapter/${bookId}`);
      setChapter(result.data.chapter);
      if (result.data.time_chapter === -1) {
        setTime(0);
      } else {
        setTime(result.data.time_chapter);
      }
    } catch (error) {
      setError(ErrorFunction(error));
    }
  };


  // loading the new url when changing chapter
  useEffect(() => {
    (async () => {
      if (!firstTime) {
        setTimeAll(0);
        if (littlePlayer) {
          // bigScreen();
          setPlaying(true);
          // setFirstTime(true);
          // setTimeout(() => {
          //   setFirstTime(false);
          // }, 3000);
        }
        setLoading(true);
        try {
          if (bookId) {
            await Axios.post(`/api/user/timeAndChapter`, {
              book: bookId,
              chapter: chapter,
              time_chapter: 0,
            });
            if (!startPlay) {
              setStartPlay(true);
            }
          } else {
            genAlertCtx.openGenAlert({ text: "משהו השתבש" });
          }
        } catch (error) {
          genAlertCtx.openGenAlert({ text: ErrorFunction(error) });
        }
        let count = 0;
        let interval = setInterval(() => {
          if (player.current) {
            let test = player.current.getDuration();
            setTimeAll(test);
            if (player.current && String(test) !== "NaN") {
              setTimeAll(test);
              setFirstTime(false);
              clearInterval(interval);
            }
            if (test) {
              // setPlaying(false);
              setTimeout(() => {
                setLoading(false);
              }, 500);
              count = 0;
            }
            count++;
            if (count >= 50) {
              clearInterval(interval);
              count = 0;
              setError("לא הצלחנו לטעון את קובץ השמע,\nנסה שנית מאוחר יותר");
            }
          }
        }, 100);
      }
    })();
  }, [chapter]);

  // post current time and chapter before refresh
  window.onbeforeunload = () => {
    (async () => {
      setUrlChapters();
      setBookIdCopy();
      setChapterCopy();
      try {
        if (bookId && !firstTime) {
          await Axios.post(`/api/user/timeAndChapter`, {
            book: bookId,
            chapter,
            time_chapter: player.current.getCurrentTime() - 1,
          });
        } else {
          genAlertCtx.openGenAlert({ text: "משהו השתבש" });
        }
      } catch (error) {
        genAlertCtx.openGenAlert({ text: ErrorFunction(error) });
      } return;
    })();
  };

  //Checking if you started the book
  const onStart = () => {
    if (!startPlay) {
      setStartPlay(true);
    }
  };


  // loading the url when you ready book
  const onReady = () => {
    (async () => {
      if (firstTime) {
        let count = 0;
        let interval = setInterval(async () => {
          if (time && player.current) {
            await player.current.seekTo(time, "seconds");
          }
          if (playing) {
            setPlaying(false);
          }
          if (player.current) {
            let test = await player.current.getDuration();
            setTimeAll(test);
            if (player.current && String(test) !== "NaN") {
              if (player.current.getDuration()) {
                setTimeAll(player.current.getDuration());
                setTimeout(() => {
                  // setPlaying(false);
                  setFirstTime(false);
                }, 500);
                clearInterval(interval);
                count = 0;
              }
            }
          }
          count++;
          if (count >= 50) {
            clearInterval(interval);
            count = 0;
            setError("לא הצלחנו לטעון את קובץ השמע,\nנסה שנית מאוחר יותר");
          }
        }, 100);
      }
      else {
        setPlaying(true);
      }
    })();
  };

  // change to little player
  const littleScreen = () => {
    setLittlePlayerAnimtion(true);
    setTimeout(() => {
      setLittlePlayer(true);
    }, 399);
  };

  // change to big player
  const bigScreen = () => {
    setLittlePlayer(false);
    setLittlePlayerAnimtion(false);
  };

  // belong to circle player -- control the progress bar
  const onSeek = (amount) => {
    if (player.current) {
      player.current.seekTo(amount, "fraction");
      getLoaded();
    }
  };

  const getLoaded = () => {
    let interval = setInterval(() => {
      if (player.current && !firstTime) {
        if (player.current.getSecondsLoaded() < player.current.getCurrentTime()) {
          bigScreen();
          setMiniLoading(true);
          setPlaying(false);
        } else if (player.current.getSecondsLoaded() >= player.current.getCurrentTime()) {
          setMiniLoading(false);
          clearInterval(interval);
        }
      }
    }, 1);
  };

  // show duration time -- זמן כללי
  // show current time -- זמן נוכחי
  const getTime = (props) => {
    if (player.current) {
      let date = 0;
      if (props === "getDuration") {
        date = timeAll;
      } else if (props === "getCurrentTime") {
        date = player.current.getCurrentTime();
      } else {
        return "00:00";
      }
      if (date) {
        let time = Math.round(date);
        let timeM = Math.floor(time / 60);
        let timeS = time % 60;
        if (timeM < 10) {
          timeM = `0${Math.floor(time / 60)}`;
        }
        if (timeS < 10) {
          timeS = `0${time % 60}`;
        }

        return `${timeM}:${timeS}`;
      } else {
        return "00:00";
      }
    }
  };

  // show left time -- זמן נותר

  useAsyncEffect(async () => {
    let updateBookInfo = await BookStore.getBookByID(bookId)
    setBookInfo(
      updateBookInfo
    );
  }, [])

  //show left time for book
  const leftTimeBook = () => {
    if (player.current) {
      const audioLength = bookInfo?.bookInfo?.audio_length;


      if (Math.floor(audioLength / 3600) > 0) {
        if (Math.floor(audioLength % 3600 !== 0)) {
          return `נותרו כ ${Math.floor(audioLength / 3600)} שעות ${Math.floor(audioLength / 3600) > 0 && " ו"}  ${Math.floor((audioLength / 60) % 60)} דקות`
        }
        return `נותרו כ${Math.floor(audioLength / 3600)} שעות`
      }
      if (Math.floor(audioLength % 3600 !== 0)) {
        if (Math.floor(audioLength / 3600) === 0) {
          return `${Math.floor((audioLength / 60) % 60)} דקות`;
        }
        return ` ${Math.floor(audioLength / 3600) > 0} ו ${Math.floor((audioLength / 60) % 60)} דקות`
      }

    }

  };

  // open the popup rating and marker the book as finished
  const endRating = async () => {
    try {
      if (bookId) {
        await Axios.post(`/api/user/timeAndChapter`, {
          book: bookId,
          chapter,
          time_chapter: 0,
        });
      } else {
        genAlertCtx.openGenAlert({ text: "משהו השתבש" });
      }
    } catch (error) {
      genAlertCtx.openGenAlert({ text: ErrorFunction(error) });
    }
    if (chapter === numChapters) {

      setIfPlayer(false);
      lastFocusedElement.current && lastFocusedElement.current.focus();
      props.setRatingPopup(true);
      setPopupOpen(true);
      try {
        setIsFinished(true);
        await Axios.post(`/api/user/bookFinished/${bookId}`);
      } catch (error) {
        genAlertCtx.openGenAlert({ text: ErrorFunction(error) });
      }

    }
  };

  return (
    <div className={`${isIOS || isMacOs ? "ios" : ""} ${isDesktop ? "browser" : ""}`}>
      {!littlePlayer ? (
        <div className="loadingbig"> {/* loading and error */}
          <div className={`firstTime ${firstTime ? "scale-in-ver-bottom" : ""}`} style={{ display: (firstTime) && (!!error?.length) ? "inline" : "none", }}>
            <ClosePlayer
              setUrlChapters={setUrlChapters}
              firstTime={firstTime}
              // loading={!littlePlayer || miniLoading}
              player={player}
              setBookIdCopy={setBookIdCopy}
              setChapterCopy={setChapterCopy}
              skipValidation
            />

            <div className="loading">
              {BookStore.ifError || error ? (
                <div>
                  <p style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>

                    {error ?? BookStore.error}
                  </p>

                </div>
              ) : (
                <CircleLoading color="pink" />
              )}
            </div>
          </div>
        </div>
      ) : null}
      {!littlePlayer ? (
        <div className="loadingbig"> {/* loading if Jumps in time*/}
          <div className={`miniLoading`} style={{ display: miniLoading ? "flex" : "none", }} >
            <CircleLoading color="white" />
          </div>
        </div>
      ) : null}
      <div>
        {/* react player - what play the voice */}
        <ReactPlayer
          ref={player}
          url={urlChapter}
          playing={playing}
          height="0"
          width="0"
          onError={() => { setError("לא הצלחנו לטעון את קובץ השמע,\nנסה שנית מאוחר יותר"); }}
          onProgress={setPlayerState}
          onEnded={() => { setPlaying(false); setChapter(chapter + 1); endRating(); }}
          playbackRate={Number(speed)} onReady={() => onReady()}
          onStart={() => onStart()} />
        {/* little player */}
        {littlePlayer ? (
          <div className={`PlayerL ${littlePlayerAnimtion ? "scale-in-ver-bottom" : ""} `}>
            <LittlePlayer
              player={player}
              setPlaying={setPlaying}
              setRatingPopup={props.setRatingPopup}
              miniLoading={miniLoading}
              playing={playing}
              bigScreen={bigScreen}
              nameBook={nameBook}
              chapter={chapter}
              leftTime={leftTimeBook} />
          </div>
        ) : (
          /* big player */
          <div className={`bigPage`}>
            <BigPlayer
              littlePlayerAnimtion={littlePlayerAnimtion}
              trapActivationTrigger={!showChapters && !showSpeeds}
              setUrlChapters={setUrlChapters}
              setBookIdCopy={setBookIdCopy}
              setChapterCopy={setChapterCopy}
              firstTime={firstTime}
              player={player}
              miniLoading={miniLoading}
              littleScreen={littleScreen}
              nameBook={nameBook}
              nameAuthor={nameAuthor}
              leftTime={leftTimeBook}
              setShowChapters={setShowChapters}
              setShowSpeeds={setShowSpeeds}
              speed={speed}
              setPlaying={setPlaying}
              setRatingPopup={props.setRatingPopup}
              setPopupOpen={setPopupOpen}
              getTime={getTime}
              playing={playing}
              chapter={chapter}
              onSeek={onSeek}
              played={playerState.played}
              loaded={playerState.loaded} />
          </div>
        )}
      </div>
    </div>
  );
}

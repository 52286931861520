import React from 'react'
import Rating from 'react-rating'
import { STARS_STEPS } from '../../consts/rating.const'
import { inRange } from '../../fucntions/rating'

export const StarRatingField = ({ rating, error, updateRating, label }) => {

    const changeRateWithArrow = (e) => {
        if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;
        updateRating(prev => {
            return inRange(e.key === 'ArrowRight' ? prev + STARS_STEPS : prev - STARS_STEPS)
        })
    }

    return (
        <div className="popup-input">
            <div className="errors-flex">
                <div className="star-flex">
                    <label>{label}</label>
                    <div style={{ display: 'contents' }} className="rating-container" onKeyDown={changeRateWithArrow}>
                        <Rating
                            tabIndex={0}
                            aria-label={`${label} ${rating}`}
                            className={`stars-fractional-rating ${!error ? "no-" : ""}error-star`}
                            initialRating={rating}
                            emptySymbol="fa fa-star-o fa"
                            fullSymbol="fa fa-star fa"
                            fractions={1 / STARS_STEPS}
                            onChange={(rate) => updateRating(rate)} />
                    </div>
                </div>
                <div className="error">{error}</div>
            </div>
        </div>
    )
}

import React, { useContext, useState } from 'react'
import Select from 'react-select';
import axios from 'axios';
import ErrorFunction from "../../errorFunction";
import { DetectDeviceContext } from '../../ctx/DetectDeviceContext';

/*When the teacher wants to update the classes they teach, they open this popup that has an    
  option to select a new grade and the corresponding classes they will teach in that grade*/
export default function ChangeTeacherPopup(props) {

    const classes = props.class;
    const [teacher, setTeacher] = useState("");
    const [error, setError] = useState("")
    const [errorServer, setErrorServer] = useState();
      const { isDesktop } = useContext(DetectDeviceContext);

    /*This function updates the class' teacher*/
    const update = async () => {
        if (!teacher.value) {
            setError("לא בחרת מורה");
            return;
        }
        setError("");
        try {
            const result = await axios.post('/api/class/ChangeClassroomTeacher', {
                class: classes.id,
                username: teacher.value
            });
            result.data ? props.close() : console.log("%cproblem", "color:red");
        } catch (error) {
            setErrorServer(ErrorFunction(error))
        }
    };

    /*This is the styling for the React Select component*/
    const colourStyles = {
        option: (styles, { isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "var(--custom-medium-blue)" : null,
                color: "#333333",
                cursor: "pointer",
            };
        },
    };

    return (
        <div className={`popup-background generic ${isDesktop ? "web-version" : ""}`}>
            <div className="bounce-7">
                <div className="body saved-popup">
                    <div className="close-popup" onClick={props.close}>
                        <img src="/media/icons/delete2.svg" alt="כפתור סגירה" className="popup-icon" />
                    </div>
                    <div className="errorServer">{errorServer}</div>
                    <div>
                        <div>{classes.school}</div>
                        <div>בחר מורה חדש לכיתה {classes.grade}{classes.number}:</div>
                        <Select
                            placeholder="בחר"
                            value={teacher}
                            onChange={(e) => { setTeacher(e) }}
                            options={classes.teachers.map((value) => ({
                                value: value.username,
                                label: value.name,
                            }))}
                            className="react-select-1"
                            styles={colourStyles}
                        />
                        <div className="error">{error}</div>
                        <div onClick={update}>עדכן מורה</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

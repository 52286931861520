//function takes blob type file and downloads it

import axios, { AxiosRequestConfig, Method } from 'axios';
import downloadBlob from './download-blob';

const downloadServerFile = async (path: string, fileName: string, config: AxiosRequestConfig = {}, download: boolean = false, method?: Method) => {

    const response = await axios({
        url: path,
        method: method || 'GET',
        ...(process.env.NODE_ENV === "production" || download ? {
            responseType: "blob",
            headers: {
                'Accept': 'application/pdf'
            }
        } : {}),
        ...config,//keeping this config in the bottom that way it can override things on top
    });

    return downloadBlob(response.data, fileName);
}

export default downloadServerFile;
import { ValidateFields } from '@hilma/tools';
import { TextareaAutosize } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from '../components/Footer';
import { GenericButton } from '../components/GenericButton';
import Header from '../components/Header';
import Popup from '../components/Popup';
import { GLOBAL_VARIABLES } from '../consts/global-variables.const';
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { UserContext } from '../ctx/UserContext';
import '../style/contact-us.scss';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [popup, setPopup] = useState(false);

  const { isMobile } = useContext(DetectDeviceContext);
  const history = useHistory();

  const inputsArray = [{
    name: 'name',
    type: 'text',
    placeholder: 'שם מלא',
    required: true,
    value: name,
    error: nameError,
    onChange: (e) => {
      if (e.target.value.length < 30) {
        setName(e.target.value);
      } else {
        setNameError('שם המלא חייב להכיל עד 30 תווים')
      }
    },
    onBlur: () => {
      const NameError = ValidateFields.validateFullNameInput(name, true);
      setNameError(NameError);
      return NameError;
    },

  }, {
    name: 'email',
    type: 'email',
    placeholder: 'אימייל',
    required: true,
    value: email,
    error: emailError,
    onChange: (e) => setEmail(e.target.value),
    onBlur: () => {
      const EmailError = ValidateFields.validateEmailInput(email, true);
      setEmailError(EmailError);
      return emailError;
    },
  }, {
    name: 'message',
    type: 'text-area',
    placeholder: 'הפנייה שלך',
    required: true,
    value: message,
    error: messageError,
    onChange: (e) => {
      if (e.target.value.length <= 1024) setMessage(e.target.value);
      else setMessageError('תוכן הפנייה לא יכול להיות יותר מ1024 תווים');
    },
    onBlur: () => {
      const MessageError = ValidateFields.validateStringInput(message, true, 'את תוכן הפנייה', 1024);//validate all inputs and set errors if needed
      setMessageError(MessageError);
      return MessageError;
    },
  }];


  const handleSubmit = async (e) => {
    e.preventDefault();
    // //validate all inputs and set errors if needed
    // inputsArray.forEach((input) => { input.onBlur(); }); // call onBlur on all inputs, to validate them
    // if all inputs are valid, send the message
    let isValid = true;
    inputsArray?.forEach((input) => {
      let result = input.onBlur();
      if (result !== '') {
        isValid = false;
      }
    });
    if (isValid) {
      try {
        
        const res = await axios.post('/api/contact-us', {
          name,
          email,
          message
        });
        if (res) {
          setPopup(true);
        } 
      } catch (error) {
        setMessageError(GLOBAL_VARIABLES.NO_SERVER_CONNECTION)
        console.error({error})
      }
    };
  };

  return (
    <>
      <Header title="צור קשר" />

      <div className='contact-us'>
        <div className="pre-form">
          <header className="title">יצירת קשר</header>
          <span className="description">שלחו לנו הודעה באמצעות טופס זה</span>
        </div>

        <div className="form">
          {
            inputsArray.map((input, index) => {
              return (
                <>
                  <label className='input-container'>
                    <span className="input-title">
                      {input.placeholder} {input.required && <span className='required'>*</span>}
                    </span>

                    {
                      input.type === 'text-area' ?
                    
                        <TextareaAutosize
                          className='input text-area'
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          maxRows={6}
                        />


                        :
                        <input
                          className="input"
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                        />}
                    <span className='input-error'>{input.error}</span>
                  </label>
                </>
              );
            }
            )
          }

        </div>
        <div className="button-container">
          <GenericButton
            ariaLabel={'שליחה'}
            text={'שליחה'}
            onClick={handleSubmit}
            className='submit-button'
          />
        </div>

      </div>
      {isMobile ? "" : <Footer contactUs={true}/>}

      {popup && <Popup
        close={() => history.push("/")}
        text="האימייל נשלח בהצלחה!"
        delete="/media/icons/delete2.svg"
        imgsrc="/media/images/thumbsUp.svg" 

      />}

    </>
  );
};

export default ContactUs;

import React, { useContext, useState } from 'react';
import { GeneralAlert } from '../components/GeneralAlert.jsx';

const GenAlertContext = React.createContext()

export const useGenAlert = () => useContext(GenAlertContext)

let alertTO = null;
export const GenAlertProvider = ({ children }) => {
    
    const [showAlert, setShowAlert] = useState(false)
    const closeAlert = () => { setShowAlert(false) }
  
    const openGenAlert = (obj, popupCb = () => { }) => {
        if (typeof obj !== "object" || Array.isArray(obj)) return; 
        clearTimeout(alertTO);
        closeAlert();
        const alertObj = { text: obj.text, warning: obj.warning || false, center: obj.center || false, noTimeout: obj.noTimeout || false }
        if (obj.isPopup) alertObj.isPopup = { ...obj.isPopup, popupCb, closeSelf: () => { setShowAlert(false) } }
        setShowAlert(alertObj)
        if (!obj.isPopup && !obj.noTimeout) alertTO = setTimeout(closeAlert, 5000)
    }

    const ctxValue = {
        openGenAlert, closeAlert, 
    }

    return <GenAlertContext.Provider value={ctxValue} >
        {children}
        {showAlert && showAlert.text ? <GeneralAlert text={showAlert.text} warning={showAlert.warning} center={showAlert.center} isPopup={showAlert.isPopup} noTimeout={showAlert.noTimeout} /> : null}
    </GenAlertContext.Provider>
}
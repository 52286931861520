import React, { useContext } from "react";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { useHistory } from 'react-router-dom';
import "../style/no-page.scss"

/*This is the page that is displayed when the url of the page that the user is looking for does not exist.
It renders a Error 404: Page Not Found message.*/
export default function NoPage(props) {
    const history = useHistory();
    const { isDesktop } = useContext(DetectDeviceContext);
    
    const backToCatalog = (e) => {
        e.preventDefault();
        history.push('/');
    }

    return (
        <div className="page404">
            {!isDesktop ? (
                <div className="noPageTitle">
                    <div className="titlet">ספריה בהקשבה</div>
                    <img src="whiteLogo.svg" alt="" />
                </div>
            ) : null}
            <img src={"/media/images/colorsUp.svg"} alt="" className="imgtop" />
            <div className="error404">
                <img src="/media/images/404.png" className="errorImg" alt="הדף לא נמצא, שגיאת 404" />
                <div className="noPage">הדף המבוקש לא נמצא</div>
                <button className="back-button" onClick={backToCatalog}>
                    <img className="back-button--arrow"
                        src="/media/icons/whiteBackArrow.svg"
                        alt="אייקון של חץ אחורה, חזרה לקטלוג הספרים" />
                    <span className="text">בחזרה לקטלוג ספרים</span>
                </button>
            </div>
            <img src={"/media/images/colorsDown.svg"} alt="" className="imgbottom" />
        </div>
    );
};

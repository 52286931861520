//this component returns a generic loading of 3 dots that is used on a button 

import "../style/genericLoading.scss"

const GenericLoading = (props) => {

    return (
        <div className={`loading-circles-container ${props.big? "big-player-dots" : ""}`}>
            <span className="circle-1"></span>
            <span className="circle-2"></span>
            <span className="circle-3"></span>
        </div>
    )
}

export default GenericLoading;
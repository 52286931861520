import React from 'react';
import { GenericButton } from '../../components/GenericButton'
import '../style/player-speed.scss';

const speeds = ['0.5', '0.75', '1.0', '1.25', '1.5', '1.75', '2.0', '', '', '', '', '', '', ''] // list of speeds value

/*This component displays the speeds menu in the player.*/
export default function PlayerSpeed(props) {
    return (
        <div className="bigPage" role="dialog" ref={props.dialogRef}>
            <div className='speeds'>
                <div className='lt'>
                    <span></span>
                    <p className='speedTitle'>מהירות קריינות</p>
                    <GenericButton
                        style={{ backgroundColor: 'inherit', height: 'fit-content' }}
                        onClick={() => props.setShowSpeeds(false)}
                        ariaLabel="סגירת חלון בחירת המהירות" >
                        <img src="/media/icons/xButton.svg" alt="אייקון סגירה" className='x' />
                    </GenericButton>
                </div>
                <div className='list'>
                    {speeds.map((s, i) => {
                        let p = <div className='num'>{s}x</div>
                        if (s === props.speed) {
                            p = <div className='current'><div className='num bold'>{s}x</div> <img className='check' src='/media/icons/check.svg' alt="סימון וי" /></div>;
                        }
                        return (
                            s ?
                                <GenericButton
                                    key={s}
                                    className="speed-button"
                                    ariaLabel={`מהירות ${s} ${s === props.speed ? "כרגע מופעל" : ""}`}
                                    onClick={() => { props.setSpeed(s); props.setShowSpeeds(false) }}>
                                    {p}
                                    < hr />
                                </GenericButton> :
                                <div key={i}>
                                    <div className='num none'>
                                        {s}
                                    </div>
                                    <hr />
                                </div>
                        )
                    })}
                </div>
            </div >
        </div>
    )
}


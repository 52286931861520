import { Link } from "react-router-dom";

export const COLORS = ["yellow", "lightblue", "red", "blue"];

export const NO_BOOK = (
    <>
        <span className="nb">
            עדיין אין לך ספרים על המדף. <br />
        </span>
        <span>
            <Link to={"/"} aria-label="בקר בספריה" className="lib nb">
                בקר בספריה
            </Link>
        </span>
        <span className="nb"> ובחר לך את ספר השמע הראשון שלך</span>
    </>
);
export const CHANGE_TIME_IMG = {
    little: {
        forward: {
            src: '/media/icons/forwardLittle.svg',
            alt: 'הרצה קדימה 30 שניות',
        },
        rewind: {
            src: '/media/icons/rewindLittle.svg',
            alt: 'חזרה 30 שניות אחורה',
        },
        className: 'seekL'
    },
    big: {
        forward: {
            src: '/media/icons/forward.svg',
            alt: 'הרצה קדימה 30 שניות',
        },
        rewind: {
            src: '/media/icons/rewind.svg',
            alt: 'חזרה 30 שניות אחורה',
        },
        className: 'seek'
    }
};
import React, { useEffect, useRef, useState } from "react";
import ErrorFunction from "../../errorFunction";

const Move = ({ timeToStart, text, width, speed, rounds, containerClassName, textClassName }) => {
  const [moving, setMoving] = useState("");
  const [moveTimeOut, setMoveTimeOut] = useState(null);
  const [interval, setInterval] = useState(null)
  const textRef = useRef(null);
  const ContainerRef = useRef(null);

  useEffect(() => {
    let maxWidth = ContainerRef.current.offsetWidth;
    let nameWidth = textRef.current.offsetWidth;
    if (nameWidth <= maxWidth) setMoving("moveTitle");
    const newTimeOut = setTimeout(move, timeToStart);
    setMoveTimeOut(newTimeOut)
    return () => {
      clearInterval(interval);
      clearTimeout(moveTimeOut);
    };
  }, []);

  const move = () => {
    try {
      let maxWidth = ContainerRef.current.offsetWidth;
      let nameWidth = textRef.current.offsetWidth;
      if (nameWidth > maxWidth) {
        let counter = 0;
        const newInterval = setInterval(() => {
          let margin = Number(
            textRef.current.style.marginRight.replace("px", "")
          );
          if (margin * -1 > nameWidth) {
            if (counter !== 0 && counter === Number(rounds) - 1) {
              textRef.current.style.marginRight = 0;
              clearInterval(interval);
            } else {
              textRef.current.style.marginRight = maxWidth + "px";
              counter++;
            }
            return;
          }
          textRef.current.style.marginRight = margin - 1 + "px";
        }, Number(speed) * 10);
        setInterval(newInterval)
      }
    } catch (error) {
      clearTimeout(moveTimeOut);
      clearInterval(interval);
      ErrorFunction(error);
    }
  };

  return (
    <div className={`${containerClassName} ${moving}`} style={{ direction: "rtl", width, overflow: "hidden" }} ref={ContainerRef}>
      <div className={textClassName} style={{ whiteSpace: "nowrap", width: "fit-content" }} ref={textRef}>
        {text}
      </div>
    </div>
  );
};

export default Move;

import { useContext, useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { InputContext } from "./../../ctx/InputContext";
import usePlayer from "./../../ctx/PlayerContext";
import { UserContext } from "./../../ctx/UserContext";
import PlayerMenus from "./PlayerMenus";
import PopupRating from "./PopupRating";
import Player from "../pages/Player"; // to do 
import Profile from "../../pages/Profile";
import Library from "../../pages/Library";
import BookPage from "../../pages/BookPage";
import ContactUs from "../../pages/ContactUs";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import RotateDevice from "../../components/RotateDevice";
import NoPage from "../../pages/NoPage";
import Setting from "./../../pages/Setting";
import { useIsAuthenticated } from "@hilma/auth";
import { DetectDeviceContext } from "../../ctx/DetectDeviceContext";
import BooksByAge from "../pages/BooksByAge";
import { useBooks } from "../../stores/Books.store";
import Bookshelf from "../../pages/Bookshelf";
import { Redirect } from "react-router";
import PrivacyPage from "../pages/PrivacyPage";
import TermsOfUse from "../pages/TermsOfUse";


/*This page gives routes to all of the pages in the student's side of the application.*/
export default function StudentRoute() {
  const [rotation, setRotation] = useState(!window.matchMedia("(orientation: portrait)").matches);
  const [ratingPopup, setRatingPopup] = useState(false);

  const playerctx = usePlayer();
  const userctx = useContext(UserContext);
  const { isDesktop, isTablet } = useContext(DetectDeviceContext);
  const { keyboardOpen, setKeyboardOpen, popupOpen, newVh, setNewVh } = useContext(InputContext);
  const BookStore = useBooks();

  const updateWindowSize = function () {
    window.lastInnerWidth = window.innerWidth;
    window.lastInnerHeight = window.innerHeight;
    // Stays the same for iOS, so that's our ticket to detect iOS keyboard
    window.lastBodyHeight = document.body.clientHeight;
  };

  window.addEventListener('resize', (event) => {
    setNewVh(window.innerHeight / 100);
  });

  useEffect(() => {
    // BookStore.setBooks();
    BookStore.fetchMyBooks(playerctx.setStudentBooks, userctx.setClassId);
    BookStore.fetchRecommendedBooks();
    BookStore.fetchNewBooks();
    BookStore.fetchLastBooks();
    userctx.setProfileType("student");
  }, []);



  useEffect(() => {
    updateWindowSize();
    window.topBarHeight = window.screen.height - window.innerHeight;
    window.addEventListener("resize", resizeThrottler, false);

    var resizeTimeout;
    function resizeThrottler() {
      // ignore resize events as long as an actualResizeHandler execution is in the queue
      if (!resizeTimeout) {
        actualResizeHandler();
      }
    }

    function actualResizeHandler() {
      if (isIOS) {
        //   detectIPhoneKeyboard();
      }
      else {
        let keyboardHeight = detectKeyboard();
        if (keyboardHeight > 0) {
          keyboardShift(keyboardHeight);
        } else if (parseInt(keyboardHeight) === -1) {
          removeKeyboardShift();
        }
      }
    }
  }, []);

  
  


  function detectKeyboard() {
    // No orientation change, keyboard opening
    if (
      window.lastInnerHeight - window.innerHeight > 150 &&
      parseInt(window.innerWidth) === parseInt(window.lastInnerWidth)
    ) {
      let keyboardHeight = window.lastInnerHeight - window.innerHeight;
      updateWindowSize();
      return keyboardHeight;
    }

    // No orientation change, keyboard closing
    if (
      window.innerHeight - window.lastInnerHeight > 150 &&
      parseInt(window.innerWidth) === parseInt(window.lastInnerWidth)
    ) {
      let keyboardHeight = -1;
      updateWindowSize();
      return keyboardHeight;
    }

    // Orientation change or regular resize, no keyboard action
    let keyboardHeight = 0;
    updateWindowSize();
    return keyboardHeight;
  }

  function keyboardShift(keyboardHeight) {
    //the keyboard is open
    setKeyboardOpen(true);
  }

  function removeKeyboardShift() {
    //the keyboard is closed
    setKeyboardOpen(false);
  }

  window.onorientationchange = (e) => {
    setRotation(e.target.matchMedia("(orientation: portrait)").matches);
  };

  const isAuthenticated = useIsAuthenticated();

  // const display = () => {
  //   console.log("im In!!!!!!!!!1");
  //     const now = new Date();
  //     const time = now.getTime();
  //     const expireTime = time + 1 * 60 * 1000;
  //     now.setTime(expireTime);
  //     document.cookie = 'cookie=ok;expires='+now.toUTCString()+';path=/';
  //     console.log(document.cookie);
  // }

  // display();

  if (!isAuthenticated) return <Redirect to='/login' />


  return (
    <>

      <div className="App" style={(playerctx.ifPlayer && !playerctx.littlePlayer) || popupOpen
        ? { position: "absolute", top: "0", right: "0", height: 100 * newVh, width: "100vw", overflow: "hidden" } : null}>

        <div className={!isDesktop ? playerctx.littlePlayer ? "player-margin" : "footer-margin" : ""}>

          {isDesktop ? <Header unauth={isAuthenticated} /> : ""}
          <Switch>
            <Route exact path="/catalog" component={Library} />
            <Route exact path="/catalog/:id" component={BookPage} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/settings" component={Setting} />
            {/* <Route exact path="/privacy" component={PrivacyPage} />
            <Route exact path="/termsofuse" component={TermsOfUse} /> */}
            <Route exact path="/mybooks" component={Bookshelf} />
            <Route path="/elementary-school" component={BooksByAge} />
            <Route path="/middle-school" component={BooksByAge} />
            <Route path="/high-school" component={BooksByAge} />
            <Route exact path="/" component={Library} />
            <Route path="/" component={NoPage} />
            {/* {!isDesktop ? <Route exact path="/contactus" component={ContactUs} /> : null} */}
          </Switch>
        </div>

        {(playerctx.showSpeeds || playerctx.showChapters) ? <PlayerMenus /> : null}
        {/* {ratingPopup ? <PopupRating setRatingPopup={setRatingPopup} /> : null} */}
        {isDesktop ? <Footer /> : ""}


      </div>



      {/* <div style={keyboardOpen || rotation && !isDesktop ? { visibility: "hidden", height: "0vh" } : {}} >
        {playerctx.ifPlayer ? <Player setRatingPopup={setRatingPopup} /> : null}
      </div> */}
    </>
  );
}

import React, { Component } from "react";
import Slider from "react-slick";
import "../style/slider.scss";

/*This is the component for the slider on the teacher's dashboard. It is a React component*/
export default class CenterMode extends Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate(prevProps) {
    if (this.props.result !== prevProps.result) {
      this.slider.slickGoTo(this.props.result);
    };
  };

  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "40px",
      slidesToShow: 5,
      speed: 500,
      draggable: false,
      afterChange: (index) => {
        this.props.setResult(index);
      },
      touchMove: false,
    };

    return (
      <div className="container3211">
        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          {this.props.array.map((value, i) => (
            <div key={i}>
              <div className="sliderElement">{value}</div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
import { useAsyncEffect } from "@hilma/tools";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { PropsGenericRedPopup } from "../clientStudent/components/GenericRedPopup";


export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {

  const [name, setName] = useState("");
  const [booksRead, setBooksRead] = useState(0);
  const [profileType, setProfileType] = useState("");
  const [profileIndex, setProfileIndex] = useState(0);
  const [login, setLogin] = useState(false);
  const [classId, setClassId] = useState(null);
  const [popupState, setPopupState] = useState(false);

  const [textPopup, setTextPopup] = useState("ברוכים הבאים!");
  const [colorPopup, setColorPopup] = useState("red-rpopup");

  const [isSuperadmin, setIsSuperadmin] = useState(null);


  // const loginFunction = (boolean) => { // login function that callback to setLogin and open popup
  //   setLogin(boolean);
  //   console.log('open this fucking popup')
  //   if (boolean) {
  //     console.log('need to be open')
  //     openPopup();
  //   }
  // };
  let timeout;

  const openPopup = () => {//open popup for 8seconds and then close it
    setTextPopup("ברוכים הבאים!")
    setColorPopup("red-rpopup")
    setPopupState(true);
    timeout = setTimeout(() => {
      // setPopupState(false);
    }, 8000);
  };



  useEffect(() => { // clear timeout when unmounting for performance
    return () => { clearTimeout(timeout); };
  }, [timeout]);

  // get current user info from coockies and set it to the context
  useAsyncEffect(async () => {
    if (name || isSuperadmin !== false) return
    const user = (await axios.get("/api/user/getCurrentUserName")).data
    if (user) {
      setName(user.name);
      // setProfileIndex(user.profile_index)
      user.profile_index && user.profile_index !== 0 && setProfileIndex(user.profile_index);
    }
  }, [isSuperadmin])




  const ctxuValue = {
    name,
    setName,
    isSuperadmin,
    setIsSuperadmin,
    booksRead,
    setBooksRead,
    profileType,
    setProfileType,
    profileIndex,
    setProfileIndex,
    login,
    setLogin,
    openPopup,
    // loginFunction,
    classId,
    setClassId,
  };



  return (
    <UserContext.Provider value={ctxuValue}>
      {popupState && <PropsGenericRedPopup text={textPopup} color={colorPopup} />}
      {children}
    </UserContext.Provider>
  );
};

import React, { useEffect, useContext } from "react";
import { useObserver } from "mobx-react-lite";
import Bookshelf from '../../pages/Bookshelf'
import { useDashboard } from "../../stores/Dashboard.store";


/*This component displays all of the books in the teacher's bookshelf (i.e. all of the books
  that the teacher's students are reading).*/
export default function TeacherBookshelf() {
    const DashboardStore = useDashboard()

    useEffect(() => {
        if (DashboardStore.teacherBooksEmpty && DashboardStore.books.length) {
            DashboardStore.teacherBooksEmpty = false
        }
    }, [DashboardStore.books]);

    return useObserver(() => (
        <React.Fragment>
            <Bookshelf myBooks={DashboardStore.books} isEmpty={DashboardStore.teacherBooksEmpty} />
        </React.Fragment>
    ));
}


import React, { useContext, useEffect } from "react";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { UserContext } from "../ctx/UserContext";
import "../style/book.scss";

/*This component calculates the length of each book and renders it in into a string 
representing the hours, minutes, or both in a different syntax */
export default function BookLength(props) {
    const userctx = useContext(UserContext);

    const { isDesktop } = useContext(DetectDeviceContext);
    /*The variables below determines what to render in each page, according to the path name*/
    const location = window.location.pathname;
    const inBookshelf = location === "/mybooks";
    const inDashboard = location === "/" && userctx.profileType === "teacher";
    const inBookPage = location.includes(`/catalog/`);


    return (
        <React.Fragment>
            {/* {console.log("hii", props)} */}
            {/* There are different syntaxes for the book's length, according to the 
                progress bar in Bookshelf page (for "done" books and "in progress" books)*/}
            {(inBookshelf || inDashboard) ?
                !props.progress ?
                    (<h6 className="bs-length">
                        <span>סיימת {props.totalChapters} פרקים</span>
                    </h6>) :
                    (<h6 className="bs-length">
                        {props.currentChapter === 1 ?
                            <span>נותרו כ{props.totalChapters} פרקים מתוך {props.totalChapters} פרקים </span>
                            : <span>נותרו כ{props.totalChapters - props.currentChapter + 1} פרקים מתוך {props.totalChapters} פרקים </span>}
                    </h6>)
                :
                <div className={props.isTime ? "time-of-book" : ""}>
                    {props.isTime ?
                        <img src="/media/icons/clock.svg" alt="clock image" className="clock-img" /> : <></>
                    }
                    <h6 className={inBookPage ? "book-length no-padding" : isDesktop ? "s-length" : "length"}>

                        {Math.floor(props.book.audio_length / 3600) > 0 ? (
                            <span>
                                כ{Math.floor(props.book.audio_length / 3600)} שעות
                            </span>
                        ) : null}
                        {Math.floor(props.book.audio_length % 3600) !== 0 ? (
                            <span>
                                {Math.floor(props.book.audio_length / 3600) > 0 && " ו"}
                                {Math.floor((props.book.audio_length / 60) % 60)} דקות
                            </span>
                        ) : null}
                    </h6>
                </div>
            }

        </React.Fragment>
    )
}
import * as React from 'react';
import { Modal, IconButton, Dialog } from '@mui/material';
import "./../style/genericPopup.scss";

const GenericPopup = (props) => {

    return (
        <Dialog
            open={props.open}
            // onClose={props.handleClose}
            disableEscapeKeyDown
        >
            <div className='the-popup'>
                {props.children}
            </div>
        </Dialog>
    )
}


export default GenericPopup;
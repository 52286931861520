import React, { useContext } from "react";
import { GenericButton } from "../../components/GenericButton";
import { CHANGE_TIME_IMG } from "../../consts/player.const";
import usePlayer from "../../ctx/PlayerContext";

/*This component allows the user to skip or rewind by 30 seconds.*/
export default function ChangeTime(props) {
    const { chapter, setChapter, numChapters, littlePlayer } = usePlayer()

    /*Skips forward 30 seconds*/
    const forward30 = async () => {
        if (props.player.current) {
            if (props.player.current.getDuration() - props.player.current.getCurrentTime() < 30) {
                props.setPlaying(false);
                if (chapter === numChapters) {
                    props.player.current.seekTo(props.player.current.getDuration(), "seconds");
                    // setIfPlayer(false);
                    // props.setRatingPopup(true);
                    // setPopupOpen(true);
                    // try {
                    //     await Axios.post(`/api/user/bookFinished/${bookId}`);ד
                    //     props.playerctx.setIsFinished(true);
                    // } catch (error) {
                    //     genAlertCtx.openGenAlert({ text: ErrorFunction(error) })//todo : להבין למה יש את הערה
                    // }
                } else {
                    setChapter(chapter + 1)
                    props.player.current.seekTo(0, "seconds");
                }
            } else {
                props.player.current.seekTo(props.player.current.getCurrentTime() + 30, "seconds");
            }
        }
    };

    /*Skips forward 30 seconds*/
    const rewind30 = () => {
        if (props.player.current) {
            if (props.player.current.getCurrentTime() < 30) {
                props.player.current.seekTo(0, "seconds");
            } else {
                props.player.current.seekTo(props.player.current.getCurrentTime() - 30, "seconds");
            }
        }
    };

    return (
        <>
            <GenericButton
                ariaLabel={CHANGE_TIME_IMG[littlePlayer ? 'little' : 'big'][props.move].alt || "כפתור הרצה"}
                onClick={props.move === "forward" ? forward30 : props.move === "rewind" ? rewind30 : undefined}
                style={{ backgroundColor: 'inherit', borderRadius: 0 }}
            >
                <img
                    className={CHANGE_TIME_IMG[littlePlayer ? 'little' : 'big'].className}
                    {...(CHANGE_TIME_IMG[littlePlayer ? 'little' : 'big'][props.move] || {})}
                />
            </GenericButton>
        </>
    )
}
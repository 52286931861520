import React from 'react'
import Rating from "react-rating";
import { StarRatingField } from './StarRatingField';

/*This component allows the student to pick values from 0.5-5 that 
  represent 3 different ratings (story, quality, and general).*/
export default function Stars(props) {

    return (
        <>
            <StarRatingField rating={props.genRating} error={props.gen} updateRating={props.setGenRating} label='דירוג כללי' />
            <StarRatingField rating={props.qualityRating} error={props.quality} updateRating={props.setQualityRating} label='ביצוע' />
            <StarRatingField rating={props.storyRating} error={props.story} updateRating={props.setStoryRating} label='סיפור' />
        </>
    )
}

import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useLogout } from "@hilma/auth";
import Dashboard from "../pages/DashBoard";
import TeacherBookshelf from "../pages/TeacherBookshelf";
import Header from "../../components/Header";
import WebContactUs from "../../components/WebContactUs";
import Profile from "../../pages/Profile";
import Library from "../../pages/Library";
import BookPage from "../../pages/BookPage";
import ContactUs from "../../pages/ContactUs";
import NoPage from "../../pages/NoPage";
import Setting from "./../../pages/Setting";
import { UserContext } from "../../ctx/UserContext";
import { InputContext } from "./../../ctx/InputContext";
import { DetectDeviceContext } from "../../ctx/DetectDeviceContext";
import { useBooks } from "../../stores/Books.store";
import { useDashboard } from "../../stores/Dashboard.store";


/*This page gives routes to all of the pages in the teacher's side of the application.*/
export default function App(props) {
  const BookStore = useBooks()
  const DashboardStore = useDashboard();
  const userctx = useContext(UserContext);
  const { popupOpen } = useContext(InputContext);

  const logout = useLogout();
  const { isDesktop } = useContext(DetectDeviceContext);

  useEffect(() => {
    BookStore.setBooks();
    BookStore.fetchRecommendedBooks();
    BookStore.fetchNewBooks();
    DashboardStore.setArrays(BookStore.setBooksCovers, userctx.setName);
    userctx.setProfileType("teacher");
    return () => window.location.reload();
  }, []);

  /*The application is unavailable for teachers on mobile, so if a  
    teacher attempts to open it on a phone, there is an error page*/
  const noPhoneTeacher = (
    <>
      <div className="homeTitle">
        <div className="titlet">ספריה בהקשבה</div>
        <img src="whiteLogo.svg" alt="" />
      </div>
      <div className="noPhoneTeacher">מצטערים, <br />האתר למורים לא נתמך<br />במכשירים סלולריים</div>
      <div className="exit" onClick={() => logout()}>התנתק</div>
    </>
  );

  return (
    <div className="App" style={popupOpen ? { position: "absolute", top: "0", right: "0", height: "100vh", width: "100vw", overflow: "hidden" } : null}>
      {!isDesktop ?
        noPhoneTeacher :
        <>
          <Header />
          <Switch>
            <Route exact path="/catalog" component={Library} />
            <Route exact path="/" component={Library} />
            <Route exact path="/catalog/:id" component={BookPage} />
            <Route exact path="/profile" component={Profile} />
            {!isDesktop && <Route exact path="/contactus" component={ContactUs} />}
            <Route exact path="/mybooks" component={TeacherBookshelf} />
            <Route exact path="/settings" component={Setting} />
            <Route exact path="/" component={Dashboard} />
            <Route path="/" component={NoPage} />
          </Switch>
          <WebContactUs />
        </>}
    </div>
  );
}

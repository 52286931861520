import React, { useContext } from "react";
import { useGenAlert } from "../../ctx/GenAlertContext"
import ErrorFunction from "../../errorFunction";
import Axios from "axios";
import usePlayer from "../../ctx/PlayerContext";

/*This component closes the player and saves the data (i.e. saves where in the book the user stopped).*/
export function ClosePlayer(props) {
    const { chapter, setIfPlayer, bookId, lastFocusedElement } = usePlayer()
    const genAlertCtx = useGenAlert()

    const closePlayer = async () => {


        try {
            if (props.skipValidation) return;


            props.setUrlChapters()
            props.setBookIdCopy();
            props.setChapterCopy();
            if (bookId && !props.firstTime) {
                await Axios.post(`/api/user/timeAndChapter`, {
                    book: bookId,
                    chapter,
                    time_chapter: props.player.current.getCurrentTime() - 1,
                })
            } else {
                genAlertCtx.openGenAlert({ text: "משהו השתבש" })
            }
        } catch (error) {
            genAlertCtx.openGenAlert({ text: ErrorFunction(error) })
        } finally {
            setIfPlayer(false)
            lastFocusedElement.current && lastFocusedElement.current.focus();
        }
    }

    return (
        <button aria-label="סגירת נגן" onClick={closePlayer} className="close-player" style={{ opacity: props.loading ? "0.9" : "1" }} >
            <img src="/media/icons/delete1.svg" alt="סגירה" className="close-player--img" />
        </button>
    )
}
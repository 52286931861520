import React, { useEffect } from 'react'
import { useRef } from 'react';

export const FocusTrap = ({ children, trapRef, activationTrigger, reFocus }) => {
    const firstMount = useRef(true);

    useEffect(() => {
        if (!trapRef || (!activationTrigger && activationTrigger !== undefined)) return;
        const focusableEls = trapRef.current.querySelectorAll('all a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');

        const firstFocusableEl = focusableEls[0];
        if (firstMount?.current || (!firstMount?.current && reFocus)) firstFocusableEl?.focus();
        firstMount.current = false;
        const lastFocusableEl = focusableEls[focusableEls.length - 1];

        const listener = (e) => eventListener(e, firstFocusableEl, lastFocusableEl)

        trapRef.current.addEventListener('keydown', listener)

        return () => {
            trapRef?.current?.removeEventListener('keydown', listener)
        }
    }, [activationTrigger])

    const eventListener = (e, firstFocusableEl, lastFocusableEl) => {
        if (e.key === 'Tab' || e.keyCode === 9) {
            if (e.shiftKey) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        }
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidateFields } from "@hilma/tools";
import { UserContext } from "../ctx/UserContext";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { useLogin } from "@hilma/auth";
// import Axios from "axios";
// import Popup from "../components/Popup";
import { GenericButton } from "./GenericButton";
import { useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "../style/login.scss";
import { GLOBAL_VARIABLES } from "../consts/global-variables.const";


// const TYPE = ["password", "text"]
/*This component allows the user to log in. In addition, if the user already exists and forgot 
their password, it sends an email to the user that allows them to change their password.*/
const Login = () => {

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");

    const [isShowPassword, setIsShowPassword] = useState(false);
    // const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [submitButton, setSubmitButton] = useState(false);
    // const [forgotPass, setForgotPass] = useState(false);
    // const [getNewPass, setGetNewPass] = useState(false)

    const { isDesktop, isMobile } = useContext(DetectDeviceContext);
    const Userctx = useContext(UserContext);
    const login = useLogin();
    const history = useHistory();
    const location = useLocation();

    /*This function allows the user to toggle whether the password is visible or hidden*/
    const showPassword = () => {
        setIsShowPassword(prev => !prev);
    };

    /*First, this function validates that the user's email is properly formatted. Then,
      it checks that the username and password both exist and belong to the same user.*/
    const validate = async () => {
        // let emailE = ValidateFields.validateEmailInput(email, true);
        setErrors({
            username: "",
            password: "",
            all: "",
        });
        let usernameE = ValidateFields.validateUsernameInput(username, true);
        let passwordE = "";
        if (!password.length) {
            passwordE = "אנא הכנס סיסמא";
        }
        let allE = "";

        if (!passwordE && !usernameE) {
            try {
                const isSuperAdmin = location.pathname === "/login-superadmin"
                const loginFunc = () => isSuperAdmin
                    ? login('api/user/superadminLogin', { username, password })
                    : login('api/user/studentLogin', { IDN: username, UserId: password, })

                const result = await loginFunc();

                
                if (!result.success) {
                    allE = GLOBAL_VARIABLES.NO_SERVER_CONNECTION;
                    console.error(allE)
                    
                } else if (!result.user) {
                    allE = GLOBAL_VARIABLES.NO_ACCOUNT_FOUND;
                    console.error(allE)
                } else {
                    Userctx.setName(result.user.name); // set the name for the user
                    Userctx.setIsSuperadmin(isSuperAdmin);
                    history.push("/");
                }
            } catch (error) {
                console.error(error);
            }
        }

        setErrors({
            username: usernameE,
            password: passwordE,
            all: allE,
        });
    };

    /*This function calls the validation function and sets the submit buttons to true (i.e. that the submit button 
      was clicked). If there was an error in the validation, it will be displayed when this function is called.*/
    const enter = () => {
        validate();
        setSubmitButton(true);
    };

    // /*This function checks that the email is a real email. If it is, it sends an email to 
    // the user with a link that sends them to a page where they can change their password,*/
    // const forgotPassword = async () => {
    //     try {
    //         let emailE = ValidateFields.validateEmailInput(email, true);
    //         setErrors({
    //             email: emailE,
    //         });
    //         if (!emailE) {
    //             const result = await Axios.get(`/api/user/forgotPassword/${email}`)
    //             if (result.data.id.length > 0) {
    //                 setGetNewPass(true)
    //             }
    //         }
    //     }
    //     catch (error) {
    //         setErrors({
    //             email: "האימייל לא קיים"
    //         })
    //     }
    // };

    const inLogin = () => Userctx.setLogin(true);

    // const back = () => { setForgotPass(false); inLogin(); setErrors({}); setEmail("") }

    // const moveToForgotPassword = () => { setForgotPass(true); setErrors({}); setEmail(""); setPassword("") }

    const goToSignUp = () => history.push('/signup');



    return (
        <div className="login-page">
            <Header />

            <div className="login-component">

                <div className="login-title--container">
                    {isMobile ?
                        <>
                            <div className="login-title">ברוכים הבאים</div>
                            <div className="login-title second">לספריה בהקשבה!</div>
                            <p className="login-subtitle">
                                מאגר ספרי השמע (אודיובוקס)
                                <br />
                                הפתוח ללא עלות לבעלי מוגבלות קריאה
                            </p>
                        </> :
                        <>
                            <div className="login-title">ברוכים הבאים לספריה בהקשבה!</div>
                            <p className="login-subtitle">מאגר ספרי השמע (אודיובוקס) הפתוח ללא עלות לבעלי מוגבלות קריאה</p>
                        </>
                    }
                </div>

                <div className="login-inputs-container">
                    <div className="login-inputs">
                        <input placeholder="שם משתמש" value={username} type="text" className="login-input" onChange={(e) => setUsername(e.target.value)} name="username" id="username" data-lpignore="true" />
                        <div className="login-error" aria-live="assertive" >{submitButton ? errors.username : ""}</div>

                        <div className="login-pass login-input">
                            <input placeholder="סיסמא" value={password} type={isShowPassword ? 'text' : 'password'} className="login-pass-input" onChange={(e) => setPassword(e.target.value)} id="password" name="password" autoComplete="off" data-lpignore="true" maxLength="20" />
                            <FontAwesomeIcon onClick={showPassword} icon={["fas", "eye"]} color={!isShowPassword ? '#787a81' : '#0c3e89'} style={{ marginTop: "8px" }} />
                        </div>
                        <div className="login-error" aria-live={submitButton && errors.password ? "assertive" : "off"} >{submitButton && errors.password ? errors.password : ""}</div>
                    </div>
                    <div className="login-error">
                        {submitButton && errors.all ? errors.all : ""}
                    </div>
                </div>
                <div className="login-btns">
                    <GenericButton text="כניסה" onClick={enter} className="login-btn-enter login-btn" />
                    <GenericButton text="חדש? הרשם כאן" onClick={goToSignUp} className="login-btn-new login-btn" />
                </div>
            </div>
            {isMobile ? "" : <Footer />}
        </div>
    );
};

export default Login;
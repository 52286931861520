import React, { createRef, useEffect, useState } from "react";
import ProgressBar from 'progressbar.js'
import "../style/student-table.scss"

/*This is the table of which students are listening to a certain book.*/
export default function StudentTable(props) {

    const [containerRef, setContainerRef] = useState([]);

    useEffect(() => {
        const cleanRef = [...containerRef]
        for (let i in cleanRef) {
            if (cleanRef[i] && cleanRef[i].innerHTML) cleanRef[i].innerHTML = "";
        }
        setContainerRef(cleanRef)
        const ref = [];
        for (let i in sdtime) {
            ref.push(createRef(null))
        }
        setContainerRef(ref)
    }, [props.book_name])


    useEffect(() => {
        let space = containerRef;
        for (const i in space) {
            if (!space[i].innerHTML) {
                if (space[i].current !== null) {
                    let bar = new ProgressBar.Circle(space[i], {
                        strokeWidth: 8,
                        trailWidth: 8,
                        color: '#EB6868',
                        trailColor: '#23CDFD',
                        duration: 0,
                        svgStyle: null,
                    });
                    let prec = (props.students[i].chapter / props.chapters_number) * 100;
                    let allTime = getTime(props.students[i].chapter);
                    bar.animate(prec / 100);
                    bar.setText(allTime)
                }
            }
        };
    }, [containerRef]);

    /*Takes in time in seconds and returns it in minutes and hours.*/
    const getDuring = () => {
        let h = Math.floor(props.audio_length / 3600);
        let m = Math.floor(props.audio_length % 3600 / 60);
        let hm = "שעות"
        let mm = "דקות"
        if (h === 0) {
            return `${m} ${mm}`;
        } else if (m === 0) {
            return `${h} ${hm}`;
        } else {
            return `${h} ${hm} ו${m} ${mm}`;
        }
    };

    /*Gets the student's current chapter and returns the percentage of chapters they have read.*/
    const getTime = (chapter) => {
        return `${chapter}/${props.chapters_number}`
    };

    let sdtime = [];
    if (props.students) {
        for (let g = 0; g < props.students.length; g++) {
            if (props.students[g].current_time !== 0) {
                let i = (props.students[g].current_time);
                sdtime.push({ prec: i })
            };
        };
    };

    return (
        <div className="allStudentTable">
            <div className="Significant">
                <div className="titleS">{props.book_name}</div>
                <div className="authorS">{props.author_name}</div>
                <div className="durationS">{getDuring()}</div>
                <div className="durationS">{props.chapters_number} פרקים</div>
            </div>
            <div className="listenMode">תלמידים- מצב האזנה</div>
            <div className="table">
                <div className="th">
                    <div className="tt">שם התלמיד</div>
                    <div className="tt">מיקום</div>
                    <div className="tt">התקדמות</div>
                </div>
                {props.students && props.students.map((s, i) => {
                    let color = "dark-red";
                    if (((s.current_time / props.audio_length) * 100) > 75) color = "green"
                    else if (((s.current_time / props.audio_length) * 100) > 25) color = "orange"
                    let time = (s.chapter > 1 || s.time_chapter > 0 ?
                        <div className="container1" ref={(ref) => { containerRef[i] = ref; return ref }}></div>
                        : "-");
                    return (
                        <div key={s + i} className="td">
                            <div className="name ttd">{s.name}</div>
                            <div className="currentTime ttd">{time} </div>
                            <div className="circle ttd">
                                <div 
                                title={`התלמיד קרא ${((s.current_time / props.audio_length) * 100)}%`} 
                                className={`progress ${color}`}>
                                     
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export const NAV_BAR_LINKS = [
    {
        to: "/",
        currentPage: "library",
        content: "הספריה",
        src: "magnifyingGlass.svg",
        alt: 'לוגו של ספריה'
    }, {
        to: "/mybooks",
        currentPage: "myBooks",
        content: "הספרים שלי",
        src: "books.svg",
        alt: 'לוגו של תפריט הספרים שלי'
    }, {
        to: "/contact-us",
        currentPage: "contactUs",
        content: "צור קשר",
        src: "phone-call.svg",
        alt: 'לוגו של צור קשר',
    }, {
        to: "/profile",
        currentPage: "profile",
        content: "פרופיל",
        src: "goodEmployee.svg",
        alt: 'לוגו של פורפיל'
    }
];

export const HEADER_LINKS = [

    {
        to: "/login",
        currentPage: "login",
        content: "כניסה",

    }, {
        to: "/q-and-a",
        currentPage: "qAndA",
        content: "שאלות ותשובות",

    }, {
        to: "/contact-us",
        currentPage: "contactUs",
        content: "צור קשר",

    }, {
        to: "/about",
        currentPage: "about",
        content: "אודות",

    }
];

export const HEADER_LINKS_UNAUTH = [
    {
        to: "/",
        currentPage: "library",
        content: "הספריה",

    }, {
        to: "/mybooks",
        currentPage: "myBooks",
        content: "הספרים שלי",

    }, {
        to: "/q-and-a",
        currentPage: "qAndA",
        content: "שאלות ותשובות",

    }, {
        to: "/contact-us",
        currentPage: "contactUs",
        content: "צור קשר",

    }, {
        to: "/about",
        currentPage: "about",
        content: "אודות",

    }
];

export const MENU_LINKS = [

    {
        to: "/login",
        currentPage: "/login",
        content: "כניסה",

    }, {
        to: "/q-and-a",
        currentPage: "/q-and-a",
        content: "שאלות ותשובות",

    }, {
        to: "/contact-us",
        currentPage: "/contact-us",
        content: "צור קשר",

    }, {
        to: "/about",
        currentPage: "/about",
        content: "אודות",

    }, {
        to: "/privacy",
        currentPage: "/privacy",
        content: "פרטיות",
    }, {
        to: "/termsofuse",
        currentPage: "/termsofuse",
        content: "תנאי שימוש",
    }
];

export const MENU_LINKS_UNAUTH = [
    {
        to: "/",
        currentPage: "/",
        content: "הספריה",

    }, {
        to: "/mybooks",
        currentPage: "/mybooks",
        content: "הספרים שלי",

    }, {
        to: "/q-and-a",
        currentPage: "/q-and-a",
        content: "שאלות ותשובות",

    }, {
        to: "/contact-us",
        currentPage: "/contact-us",
        content: "צור קשר",

    }, {
        to: "/about",
        currentPage: "/about",
        content: "אודות",

    }, {
        to: "/privacy",
        currentPage: "/privacy",
        content: "פרטיות",
    }, {
        to: "/termsofuse",
        currentPage: "/termsofuse",
        content: "תנאי שימוש",
    }
];


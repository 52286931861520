import { useState, useEffect, useContext } from "react";
import { ContactContext } from "../ctx/ContactContext";
import { useGenAlert } from "../ctx/GenAlertContext"
import { UserContext } from "../ctx/UserContext";
import ErrorFunction from "../errorFunction";
import CircleLoading from "../components/Loader.jsx";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import BookSquare from "../components/BookSquare.jsx";
import BookLength from "../components/BookLength";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Buttons from '../components/Buttons'
import Rating from "../components/Rating.jsx";
// import Ratings from "../components/StudentRating.jsx";
import NoPage from "./NoPage"
// import axios from "axios";
import "../style/book-page.scss";
import { ErrorPage } from "../components/ErrorPage";
import { useRef } from "react";
import { useBooks } from "../stores/Books.store";
import { useDashboard } from "../stores/Dashboard.store";
import usePlayer from "../ctx/PlayerContext";

/*This is the page of a single book, including but not limited to student ratings, summary, and publishing information.*/
export default function BookPage(props) {
  const [book, setBook] = useState();
  const [author, setAuthor] = useState();
  const [audio, setAudio] = useState();
  const [audioUrl, setAudioUrl] = useState();
  const { isMobile } = useContext(DetectDeviceContext);

  const BookStore = useBooks()
  const DashboardStore = useDashboard()

  const [notFound, setNotFound] = useState(false);
  const [disableButton, setDisableButton] = useState();

  const playerctx = usePlayer()
  const userctx = useContext(UserContext);
  const bpctx = useContext(ContactContext);
  const genAlertCtx = useGenAlert();

  const reviewRef = useRef()

  useEffect(() => {
    (async () => {
      try {
        const authorBookInfo = await BookStore.getBookByID(
          Number(props.match.params.id),
          true
        );

        if (!authorBookInfo) {
          setNotFound(true)
          return
        }

        const bookInfo = await authorBookInfo.bookInfo;
        if (bookInfo.book_name[bookInfo.book_name.length - 1] === ".") {
          bookInfo.book_name = bookInfo.book_name.substring(0, bookInfo.book_name.length - 1)
        }
        setBook(bookInfo);

        const authorInfo = await authorBookInfo.authorInfo;
        setAuthor(authorInfo);

        if (userctx.profileType === 'student') {
          setDisableButton(bookInfo.is_in)
        }
        else {
          setDisableButton(DashboardStore.pushedBooks.includes(bookInfo.id))
        }

      } catch (error) {
        genAlertCtx.openGenAlert({ text: ErrorFunction(error) })
      }
    })();
  }, [userctx.profileType]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (book && book.id && userctx.profileType === "student") {
  //         let result = await axios.get(`/api/user/getUrlsChapters/${book.code}`);
  //         setAudioUrl(result.data.Files[1].Url);
  //       }
  //     } catch (error) {
  //       genAlertCtx.openGenAlert({ text: ErrorFunction(error) })
  //     }
  //   })()
  // }, [book])

  /*This function sends the user back to the previous page that the user was on. It pauses the audio if the user is playing a
    sample of the audio. It also preserves any search input that the user might have (i.e. if the user was in the Catalog page,
    the button would send him back to his search results instead of deleting them.) */
  const back = () => {
    // e.preventDefault()
    props.history.goBack();
    audio && audio.pause();
    if (sessionStorage.getItem("searchInput")) {
      BookStore.setSearch(sessionStorage.getItem("searchInput"));
      BookStore.setBooks();
    }

    book.is_in = disableButton

    let temp = {
      bookInfo: book,
      authorInfo: author,
      audio_url: book.audio_url,
      is_in: disableButton
    }
    BookStore.booksInfo.push(temp)

    let a = BookStore.buttonsInfo.find(b => b.id === book.id)
    if (a && a.id) {
      a.is_in = disableButton
    }
    else {
      BookStore.buttonsInfo.push({ id: book.id, is_in: disableButton, audio_url: book.audio_url })
    }
  }

  const focusOnReviews = () => reviewRef.current.focus()

  return (
    (notFound ?
      <NoPage />
      :
      ((BookStore.ifError || DashboardStore.error) ?
        <ErrorPage error={BookStore.ifError || DashboardStore.error} />
        :
        (!book ?
          <CircleLoading style={{ height: "80vh", display: "flex", alignItems: "center", justifyContent: "center", }} />
          :
          <div className="slide-in-elliptic-top-fwd">
            <div className={!isMobile ? "web-version" : "mobile-version"}>
              <div className="book-page">

                {!isMobile ? (
                  <div className="head-of-the-page">
                    <button className="back-to-lidrary" onClick={back}>חזרה לספריה</button>
                    <div className={bpctx.popup ? "front popup-exists" : "front"}>
                      <div className="img-info-box">
                        <BookSquare book={book} noClick={true} />

                        <div className="book-info">
                          <h3 className="book-title no-padding">
                            {book.book_name}
                          </h3>
                          <h4 className="book-author no-padding">{author || ""}</h4>
                          <BookLength book={book} isTime={true} />
                          <div className="no-padding">
                            {book.avgRating > 0 ?
                              <div className="genRating">
                                <h5 className="genR">דירוג כללי</h5>
                                <div>
                                  <Rating rating={book.avgRating} />
                                  <h5 className="seeMore">
                                    <AnchorLink onClick={focusOnReviews} href="#book-reviews">
                                      לחצ/י כאן על מנת לראות את כל הדירוגים
                                    </AnchorLink>
                                  </h5>
                                </div>
                              </div> :
                              null
                            }
                          </div>
                        </div>
                      </div>
                      {disableButton !== undefined ?
                        <Buttons
                          book={book}
                          url={audioUrl}
                          setUrl={setAudioUrl}
                          author_name={author}
                          disableButton={disableButton}
                          isStudent={userctx.profileType === 'student'}
                          setDisableButton={setDisableButton}
                          setAudio={setAudio}
                          isCatalog={false}
                          isBookPage={true} />
                        : null}
                    </div>
                  </div>
                ) : (
                  <div className="head-mobile">
                    <button className="back-to-lidrary-mobile" onClick={back}><img src="/media/icons/closeBlack.svg" alt="close button" /></button>
                    <div className={bpctx.popup ? "front popup-exists" : "front"}>
                      <h3 className="book-title">{book.book_name}</h3>
                      <h4 className="book-author">{author || ""}</h4>
                      <div className='book-img' >
                        <BookSquare book={book} />
                      </div>
                      <BookLength book={book} isTime={true} />
                      {book.avgRating > 0 ?
                        <div className="genRating">
                          <h5 className="genR">דירוג כללי</h5>
                          <div>
                            <Rating rating={book.avgRating} />
                            <h5 className="seeMore">
                              <AnchorLink onClick={focusOnReviews} href="#book-reviews">
                                לחצ/י כאן על מנת לראות את כל הדירוגים
                              </AnchorLink>
                            </h5>
                          </div>
                        </div> :
                        " "
                      }
                    </div>
                  </div>
                )
                }

                <div className={`${!isMobile ? "" : "phone-version"} ${playerctx.littlePlayer ? "bottom-player" : "bottom"} ${book.avgRating > 0 ? "" : "no-rating-bottom"}`}>

                  {(isMobile && disableButton !== undefined) ? (
                    <Buttons
                      book={book}
                      url={audioUrl}
                      setUrl={setAudioUrl}
                      isCatalog={false}
                      author_name={author}
                      disableButton={disableButton}
                      setDisableButton={setDisableButton}
                      isStudent={userctx.profileType === 'student'}
                      isTeacher={userctx.profileType === 'teacher'}
                      setAudio={setAudio}
                      isBookPage={true}
                    />
                  ) : null}

                  <div className="book-page-summary">
                    <h5 className="book-page-summary-title"> תקציר: </h5>
                    <div className="book-page-summary-text">{!book.summary || book.summary === "null"? 'לא נמצא תקציר': book.summary}</div>
                  </div>

                  <div className="book-page-summary end-info">
                    {book.translator !== 'null' ? <p><span className="little-title"> תרגום: </span>{book.translator}</p> : null}
                    {book.publication_house ? (
                      <p><span className="little-title">הוצאה:</span> {book.publication_house}.</p>
                    ) : null}
                    {book.publication_date ? (
                      <p><span className="little-title">תאריך הוצאה: </span> {book.publication_date.split("T")[0]}.</p>
                    ) : null}
                    <p><span className="little-title">ז'אנר: </span> {book.genre}</p>
                    <p><span className="little-title">סופר: </span>{author || null}.</p>
                  </div>

                  {/* <h1 ref={reviewRef} tabIndex={0} id="book-reviews">מה אומרים הקוראים</h1> //! this feature relate to books ratings
                {book.avgRating > 0 ?
                  <div className="ratings">
                    {book.ratings.map((_item, index) => (
                      <Ratings
                        key={index}
                        profileIcon="/media/icons/rating-icon.svg"
                        name={book.ratings[index].name}
                        general={book.ratings[index].general}
                        quality={book.ratings[index].quality}
                        story={book.ratings[index].story}
                        headline={book.ratings[index].reviewHeadline}
                        review={book.ratings[index].review} />
                    ))}
                  </div>
                  :
                  <div className="ratings no-rating">
                    אף אחד לא דירג את הספר עדיין
                  </div>
                } */}
                </div>
              </div>
            </div>
          </div>
        )
      )
    )
  );
}

import { useObserver } from "mobx-react";
import React, { useState, useEffect } from "react";
import Book from "../../pages/Book";
import "./../style/books-table.scss"

/*Table of books acording what the student is listening to */
export default function BooksTable(props) {
    const rankings = ["צב", "דבורה", "חד קרן", "קואלה", "חתול", "סוס", "תולעת", "אריה",];

    const [myBooks, setMyBooks] = useState([]);
    const [doneBooks, setDoneBooks] = useState([]);
    const [progressBooks, setProgressBooks] = useState([]);
    const [rank, setRank] = useState(rankings[0]);

    /*Change books according to incoming props*/
    useEffect(() => {
        if (props.books) {
            setMyBooks(props.books)
        }
    }, [props.books.length])

    useEffect(() => {
        sortBooks()
    }, [myBooks])

    useEffect(() => {
        if (doneBooks.length < 5) {
            setRank(rankings[0]);
        } else if (doneBooks.length < 8) {
            setRank(rankings[1]);
        } else if (doneBooks.length < 11) {
            setRank(rankings[2]);
        } else if (doneBooks.length < 14) {
            setRank(rankings[3]);
        } else if (doneBooks.length < 17) {
            setRank(rankings[4]);
        } else if (doneBooks.length < 20) {
            setRank(rankings[5]);
        } else if (doneBooks.length < 23) {
            setRank(rankings[6]);
        } else {
            setRank(rankings[7]);
        }
    }, [doneBooks])

    /*Sorts all the books in the bookshelf to in progress books and done books*/
    function sortBooks() {
        let pb = [];
        let db = [];
        myBooks?.forEach(item => {
            if (item.book_finished) {
                db.push(item);
            }
            else {
                pb.push(item);
            }
        })
        setDoneBooks(db);
        setProgressBooks(pb);
    }

    /*Calculates the progress time for the progress bar- between 0 to 100*/
    function calculateTime(currentTime, length) {
        let timePercentage = (currentTime / length) * 100
        return timePercentage
    }

    return useObserver(() => (
        <React.Fragment>
            <div className="profile">
                <div className="profile2"> {/*to make it in the same height */}
                    <div className="name-flex">
                        <div className="fullName">{props.name}</div>
                        <div className="className">{props.class}</div>
                    </div>
                    <div className="rank" onClick={() => console.log("🐛")}>דירוג: {rank} ספרים</div>
                    <div className="listening">סיים האזנה ל
                        <span>{doneBooks.length}</span>
                        ספרים
                    </div>
                </div>
            </div>
            <div className="page">
                {
                    progressBooks.length ?
                        <div className="progress">
                            <p className="bs-title">{props.name && props.name.split(" ")[0]} מאזין כרגע</p>
                            <div>
                                {progressBooks.map((book, index) => {
                                    return <div key={index}>
                                        <Book book={book} key={index + 1} index={index} progress={true} current_time={calculateTime(book.current_time, book.audio_length)} />
                                    </div>
                                })}
                            </div>
                        </div>
                        :
                        <div></div>
                }
                {
                    doneBooks.length ?
                        <div className="done">
                            <p className="bs-title gray">ספרים ש{props.name.split(" ")[0]} סיים</p>
                            <div>
                                {doneBooks.map((title, index) => (
                                    <div key={index}>
                                        <Book book={title} key={index + 1} index={index} progress={false} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <div></div>
                }
            </div>
        </React.Fragment>
    ))
}
import React from "react";
import { GenericButton } from "../../components/GenericButton";

/*This toggles the play/pause image in the little player.*/
export function Play(props) {
    const play = (e) => {
        e.stopPropagation()
        props.setPlaying(!props.playing);
    };

    return (
        <GenericButton
            ariaLabel={props.playing ? "הפסק נגן" : "הפעל נגן"}
            className="playImg"
            onClick={play} >
            <img
                src={
                    !props.playing ? "/media/icons/play.svg" : "/media/icons/pause.svg"
                }
                alt={!props.playing ? "הפעלה" : "השהייה"}
            />
        </GenericButton>
    )
}


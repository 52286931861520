export const PLAY_IMG = {
    true: {
        src: 'media/icons/playDone.svg',
        alt: 'play audio button',
        className: 'play-button-done'
    },
    false: {
        src: 'media/icons/playDone.svg',
        alt: '',
        className: 'play-button-progress'
    }
}
import React, { useEffect, useContext, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import "../style/categories.scss";
import { ErrorPage } from "../components/ErrorPage";
import RecommendedAgeBooks from "../components/RecommendedAgeBooks";
import { Skeleton, useForkRef } from '@mui/material';
import { useBooks } from "../stores/Books.store";
import BookSlider from "../clientStudent/components/BookSlider";
import { Link } from "react-router-dom";
import { SubjectTitle } from "../components/SubjectTitle";

/*This is the main Library webpage. It displays the most recommended  
books, as well as the most recently published books in the database.*/
export default function Categories() {

  const { isDesktop } = useContext(DetectDeviceContext);
  const BookStore = useBooks();

  const [skeletonWidth, setSkeletonWidth] = useState();
  // const [skeletonMarginRight, setSkeletonMarginRight] = useState();
  // const [skeletonCube, setSkeletonCube] = useState();
  const [skelArray, setSkelArray] = useState([1, 2, , 3, 4, 5])


  const [emptyArr, setEmptyArr] = useState([])
  const [recommendedBooks, setRecommendedBooks] = useState(/* isDesktop
    ? BookStore.recommendedBooks.slice(0, 4)
    :  */BookStore.recommendedBooks);

  const [newBooks, setNewBooks] = useState(isDesktop
    ? BookStore.newBooks.slice(0, 4)
    : BookStore.newBooks);

  useEffect(() => {
    sessionStorage.removeItem("searchInput");
    if (BookStore.search) {
      BookStore.clearSearch();
    }
  }, []);

  //for skeleton
  useEffect(() => {
    if (isDesktop) {
      setSkeletonWidth('15vw');
      // setSkeletonMarginRight('23.4vh')
      // setSkeletonCube('16vw')
      setSkelArray([1, 2, 3, 4, 5])
    } else {
      setSkeletonWidth('45vw');
      // setSkeletonMarginRight('2vh');
      // setSkeletonCube('42vw')
      setSkelArray([1, 2])
    }
  }, [isDesktop])

  /*If the real number of recommended books is less than are displayed, it adds empty book squares.*/
  useEffect(() => {
    if (isDesktop && BookStore.recommendedBooks.length < 4) {
      setEmptyArr([]);
      const empty = [];
      empty.push(...BookStore.recommendedBooks);
      for (let i = 0; i < 4 - BookStore.recommendedBooks.length; i++) {
        empty.push(i);
      }
      setEmptyArr(empty);
    }
    else if (!isDesktop && BookStore.recommendedBooks.length < 10) {
      setEmptyArr([]);
      const empty = [];
      empty.push(...BookStore.recommendedBooks);
      for (let i = 0; i < 10 - BookStore.recommendedBooks.length; i++) {
        empty.push(i);
      }
      setEmptyArr(empty);
    }
    else {
      setRecommendedBooks(/* isDesktop
        ? BookStore.recommendedBooks.slice(0, 4)
        :  */BookStore.recommendedBooks);
    }
  }, [BookStore.recommendedBooks.length]);

  useEffect(() => {
    BookStore.fetchRecommendedBooks();
  }, [BookStore.bookstudentEmpty]);

  useEffect(() => {
    setNewBooks(isDesktop
      ? BookStore.newBooks.slice(0, 4)
      : BookStore.newBooks);
  }, [BookStore.newBooks.length]);

  useEffect(() => {
    return () => {
      if (BookStore.ifError) {
        BookStore.error = "";
        BookStore.ifError = false;
      }
    };
  }, []);


  return useObserver(() => (
    <React.Fragment>

      <div className={isDesktop ? "web-version" : ""}>
        <div className="responsive-categories">
          <div className="categories-page">
            <div className="newBooks">
              <div className="displayedBooks">

                <SubjectTitle title={'ספרים מומלצים'} path={'/catalog'} />
              </div>
              <RecommendedAgeBooks />
              {((BookStore.recommendedBooks.length === 0 && !BookStore.bookstudentEmpty) || BookStore.newBooks.length === 0) ?
                BookStore.ifError ? (
                  <ErrorPage error={BookStore.error} height={"35vh"} />
                ) :
                  <div>
                    <div >

                      <SubjectTitle title='המשך לקרוא' path="mybooks" />
                      <div className="skeleton-box">
                        {skelArray.map((item) => {
                          return (
                            <div>
                              <Skeleton sx={{ borderRadius: '5px', marginLeft: '4vw', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={skeletonWidth} animation='wave' />
                              {isDesktop && <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'2vh'} animation='wave' />}
                            </div>
                          );
                        })}
                      </div>
                      <SubjectTitle title='חדשים' path="catalog" />
                      <div className="skeleton-box" >
                        {skelArray.map((item) => {
                          return (
                            <div>
                              <Skeleton sx={{ borderRadius: '5px', marginLeft: '4vw', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={skeletonWidth} animation='wave' />
                              {isDesktop && <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'2vh'} animation='wave' />}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div> :
                <div>


                  <BookSlider bookArray={BookStore.myBooks} title='המשך לקרוא' path="mybooks" />
                  <br />
                  <BookSlider bookArray={BookStore.newBooks} title="חדשים אצלינו בספריה" path="catalog" />
                </div>}

            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  ));
}

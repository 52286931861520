import { useIsAuthenticated, useLogout } from '@hilma/auth';
import { useAsyncEffect } from "@hilma/tools";
import { IconButton } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Menu as MenuIcon } from '@mui/icons-material';

import Profiles from '../components/Profiles';
import MenuPopup from "./MenuPopup";

import { HEADER_LINKS, HEADER_LINKS_UNAUTH } from '../consts/web-nav-bar.const';
import { DetectDeviceContext } from "../ctx/DetectDeviceContext";
import { InputContext } from "../ctx/InputContext";
import { UserContext } from "../ctx/UserContext";

import "./../style/navbar.scss";


/*This is the web navbar component. It allows the user to toggle between the library, bookshelf, home, and profile.*/
const Header = (props) => {

  let location = useLocation().pathname;
  let inCatalog = location.startsWith("/catalog");
  const { isMobile, isTablet } = useContext(DetectDeviceContext);
  const { name, profileIndex, setProfileIndex, setName, isSuperadmin } = useContext(UserContext);
  const { popupOpen } = useContext(InputContext);
  const history = useHistory();


  const currentPage = useMemo(() => ({
    // home: location === "/" ? "currentPage" : "",
    // profile: location === "/profile" || location === "/settings" ? "currentPage" : "",
    library: inCatalog || location === "/" ? "currentPage" : "",
    myBooks: location === "/mybooks" ? "currentPage" : "",
    qAndA: location === "/q-and-a" ? "currentPage" : "",
    contactUs: location === "/contact-us" ? "currentPage" : "",
    about: location === "/about" ? "currentPage" : "",
    login: location === "/login" ? "currentPage" : "",

  }), [location, inCatalog]);

  const [changeMenuPopup, setChangeMenuPopup] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  const logout = useLogout();

  const [rankImg, setRankImg] = useState({ src: "", alt: "" });

  useAsyncEffect(async () => {
    if (name || isSuperadmin !== false) return
    const user = (await axios.get("/api/user/getCurrentUserName")).data
    if (user) {
      setName(user.name);
      // setProfileIndex(user.profile_index)
      user.profile_index && user.profile_index !== 0 && setProfileIndex(user.profile_index);
    }


  }, [])

  useEffect(() => {
    if (profileIndex !== undefined) {
      const { src, alt } = Profiles[profileIndex]
      setRankImg({ src, alt })
    }
  }, [profileIndex]);




  return (
    <React.Fragment>
      <header className="nav-container">

        {isMobile || isTablet ?
          <div className="nav-mobile-container">
            <div className="nav-left-container">
              {props.isAgeBooks ?
                <button className="back-arrow-img-mobile" onClick={() => { history.push('/'); }}>
                  <img className='back-button' src='/media/icons/arrow-right-white.svg' />
                </button>
                :
                <MenuIcon className="nav-img nav-img__hamburger" alt="book hamburger for menu" onClick={() => setChangeMenuPopup(true)} />
              }
              <div className="nav-title">{props.title}</div>
              <img className="nav-img" src="/media/icons/pileOfBooks.png" alt="file of books image" /> {/* TODO:  maybe use blueIcon.png or dark-blue-icon.png? */}
              {/* <img className="nav-img-logo" src="/media/icons/mobileIcon.svg" alt="logo" /> */}
              <MenuPopup setOpen={setChangeMenuPopup} open={changeMenuPopup} unauth={isAuthenticated} />
            </div>
          </div>
          :
          <div className="nav-web-container">

            <Link className="nav-logo" to={"/"} onClick={popupOpen ? (e) => { e.preventDefault(); } : null}>
              <img className="nav-img" src="/media/icons/fullIcon.svg" alt="website logo" />
            </Link>

            <div className="nav-left-container">
              <div className={`nav-links ${isAuthenticated ? 'long' : 'sort'}`}>
                {!isAuthenticated ?

                  HEADER_LINKS.map((item) => {

                    return (
                      <Link
                        key={item.to}
                        to={item.to}
                        // onClick={popupOpen ? (e) => { e.preventDefault(); } : null}
                        className="nav-list"
                      >
                        <div aria-label={item.content} className={`nav-list-item ${currentPage[item.currentPage]}`}>{item.content}</div>
                      </Link>
                    );
                  })
                  :
                  HEADER_LINKS_UNAUTH.map((item) => {

                    return (
                      <Link
                        key={item.to}
                        to={item.to}
                        // onClick={popupOpen ? (e) => { e.preventDefault(); } : null}
                        className="nav-list"
                      >
                        <div aria-label={item.content} className={`nav-list-item ${currentPage[item.currentPage]}`}>{item.content}</div>
                      </Link>
                    );
                  })
                }

              </div>

            </div>
            {isAuthenticated ?
              <div className="header-drop-down">
                <div
                  className="header-profile"
                  onClick={() => {
                    if (dropDown === false) {
                      setDropDown(true);
                    } else {
                      setDropDown(false);
                    }
                  }}
                >
                  <div className="line"></div>
                  <div className="profile-name">{name}</div>
                  <IconButton>
                    <img
                      style={{ borderRadius: "50%", aspectRatio: "1/1", objectFit: "cover" }}
                      src={rankImg ? rankImg.src : "/media/icons/avatar.svg"}
                      alt={rankImg ? rankImg.alt : "profile"}
                      className="profile-pic"
                    />
                  </IconButton>
                </div>
                <div className={dropDown ? "dropdown-content show" : "dropdown-content"} >
                  <a style={{ flexDirection: 'row-reverse' }} href="/profile">פרופיל אישי
                    <img className="dropdown-log-out-icon" src='/media/icons/account.png' alt='אייקון פרופיל' />
                  </a>
                  <a onClick={logout} href="/">
                    <img className='dropdown-log-out-icon' src="/media/icons/logout.svg" alt="אייקון התנתקות" />
                    התנתקות
                  </a>
                </div>
              </div>
              : ""
            }
          </div>

        }

      </header>

    </React.Fragment >
  );
};

export default Header;
import React, { useState, useEffect, useContext } from "react";
import { useObserver } from "mobx-react-lite";
import { UserContext } from "../../ctx/UserContext"
import StudentTable from "../components/StudentTable";
import BooksTable from "../components/BooksTable";
import CircleLoading from "../../components/Loader.jsx";
import Slider from "../components/Slider.jsx";
import { bookArray, studentArray } from "./DashBoardFunction"
import "./../style/dashboard.scss";
import axios from "axios";
import { useBooks } from "../../stores/Books.store";
import { useDashboard } from "../../stores/Dashboard.store";

/*This page displays a dashboard of all the students belonging to a specific teacher as well as all the books
  in their bookshelves. The teacher can follow their students' progress by either viewing a single student and 
  all their books, or by looking at one book and seeing how far each of their students is into that book.*/
export default function DashBoard() {
  const STATUS1 = "student";
  const STATUS2 = "listen-follow";
  const DashboardStore = useDashboard()
  const BookStore = useBooks()

  const { setLogin } = useContext(UserContext);
  const [status, setStatus] = useState(STATUS1);
  const [searchType, setSearchType] = useState("הקלד שם פרטי או משפחה");
  const [array, setArray] = useState(null);
  const [studentResult, setStudentResult] = useState(0);
  const [bookResult, setBookResult] = useState(0);
  const [noResult, setNoResult] = useState("");


  /*This function clears the error and login every time the page loads.*/
  useEffect(() => {
    setLogin(false);
    return () => {
      if (BookStore.ifError) {
        BookStore.error = ""
        BookStore.ifError = false
      }
    }
  }, [])

  /*This function updates the search results every time the search input changes. It will either 
    search for student names or for names of books, depending on the status of the page.*/
  useEffect(() => {
    let resultS = studentResult;
    let resultB = bookResult;
    if (status === STATUS1)
      resultS = DashboardStore.searchByStudent(studentResult);
    if (status === STATUS2) resultB = DashboardStore.searchByBook(bookResult);
    if (resultS !== -1) setStudentResult(resultS);
    if (resultB !== -1) setBookResult(resultB);
    if (resultS === -1 || resultB === -1) {
      setNoResult(DashboardStore.search);
    } else {
      setNoResult("");
    }
  }, [DashboardStore.search]);

  /*This function updates the type of search that will happen when the status changes 
    (either for a student's name or a book's name). It also erases previous searches*/
  useEffect(() => {
    if (!DashboardStore.loading) {
      if (status === STATUS2) {
        setSearchType("הקלד שם ספר או שם סופר");
        setArray(() => bookArray(DashboardStore, BookStore));
      } else if (status === STATUS1) {
        setSearchType("הקלד שם פרטי או משפחה");
        setArray(() => studentArray(DashboardStore, BookStore))
      }
      deleteSearching();
    }
  }, [status, DashboardStore.loading]);

  /*This function searches for the user's input*/
  function searching(value) {
    value = value.target.value;
    DashboardStore.setSearch(value);
  }

  /*This function clears the search*/
  function deleteSearching() {
    DashboardStore.setSearch("");
  }

  const approveStudent = (username) => {
    axios.post('api/user/approveStudent', { username: username });
    DashboardStore.removeNotApproved(username);
  };

  const deleteStudentClass = username => {
    axios.post('api/user/deleteStudentClass', { username: username }); 
    DashboardStore.removeNotApproved(username);
  };

  /*If the component is loading, checks if there is an error. If there is, it displays an error message.*/
  const loader = BookStore.ifError ? (
    <div className="no-results-container">
      <div>
        <img className="sad-emoji" src={"/media/images/noResultsWorm.svg"} alt={"no result"} />
      </div>
      {/* Credits: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
      <p style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
        {BookStore.error}
      </p>
    </div>
  ) : <CircleLoading />;

  const isStudentYours = (name) =>
    <div key={name.username} className="student">
      <div>{name.name}</div>
      <img src='/media/icons/checked.png' alt="check" onClick={() => approveStudent(name.username)} className="image" />
      <img src='/media/icons/cancel.png' alt="cancel" onClick={() => deleteStudentClass(name.username)} className="image" />
    </div>

  return useObserver(() =>
    DashboardStore.loading ? (
      loader
    ) : (
      <div className="dash">
        {DashboardStore.studentsNotApproved.length ?
          <div className="popup-background approved-popup">
            <div className="saved-popup">
              נא לאשר שהתלמיד/ים שייכים לך
              {DashboardStore.studentsNotApproved.map(user => isStudentYours(user))}
            </div>
          </div> : undefined}
        <div className="choose">
          <div className="whichComp">
            <div className="elements">
              <div className="element student" onClick={() => { setStatus(STATUS1); }}> התלמידים שלי </div>
              <div className="element listen-follow" onClick={() => { setStatus(STATUS2); }}> מעקב האזנה </div>
            </div>
            <div className="pink-container" style={{ paddingRight: status === STATUS1 ? "0" : "11vw" }}>
              <div className="shortpink"></div>
            </div>
          </div>
          <div className="search-container">
            <div className="search">
              <img className="button" alt="search" id="searchButton" src="/media/icons/magnifyingGlass.svg" />
              <input type="text" id="searchInput" placeholder={searchType} value={DashboardStore.search} onChange={searching} autoComplete="off" maxLength={30} />
              <img style={{ display: DashboardStore.search ? "initial" : "none" }} className="button" id="clearButton" src="/media/icons/delete.svg" alt="clear search" onClick={deleteSearching} />
            </div>
            {noResult ? (
              <div className="noResult">לא נמצאו תוצאות ל"{noResult}"</div>
            ) : (
              ""
            )}
          </div>
        </div>
        {BookStore.error || DashboardStore.error ? (
          <div className="no-results-container" aria-live="assertive" >
            <p style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
              {BookStore.error || DashboardStore.error}
            </p>
          </div>
        ) : (<div></div>)}
        {array ? (
          <Slider array={array} setResult={status === STATUS1 ? setStudentResult : setBookResult} result={status === STATUS1 ? studentResult : bookResult} DashboardStore={DashboardStore} />
        ) : (
          <CircleLoading />
        )}
        {status === STATUS1
          ? DashboardStore.students && DashboardStore.students.length && DashboardStore.students[studentResult]
            ? (
              <BooksTable {...DashboardStore.students[studentResult && studentResult !== -1 ? studentResult : 0]} />
            ) :
            <>
              <div className="profile">אנא בחר בתלמיד</div>
              <div className="page"></div>
            </>
          : DashboardStore.books && DashboardStore.books.length && DashboardStore.books[bookResult]
            ? (
              <StudentTable {...DashboardStore.books[bookResult && bookResult !== -1 ? bookResult : 0]} />
            )
            : <div className="allStudentTable" style={{ height: "30vh", font: "normal normal bold 1.6vw/10vh Rubik" }}>אנא בחר בספר</div>
        }
      </div>
    )
  );
}

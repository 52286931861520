import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import '../style/error-page.scss';

export const ErrorPage = (props) => {
    const history = useHistory()
    const { isDesktop } = useContext(DetectDeviceContext);


    const backToCatalog = (e) => {
        e.preventDefault();
        history.push('/');
    }

    return (
        <div className="page-error" style={{ height: isDesktop ? "" : props.height || "90vh" }}>
            <img className="warning-icon" src="/media/images/warning.png" alt="אייקון של שגיאה" />
            {/* credits: Icons made by <a href="https://www.flaticon.com/authors/google" title="Google">Google</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a> */}
            <p className="message">
                {props.error || "ארעה שגיאה"}
            </p>
            <button className="back-button" onClick={backToCatalog}>
                <img className="back-button--arrow"
                    src="/media/icons/backArrow.svg"
                    alt="אייקון חזור, חזרה לקטלוג הספרים" />
                <span className="text">בחזרה לקטלוג ספרים</span>
            </button>
        </div>
    )
}

import React, { useContext, useState, useEffect, useMemo } from "react";
import LinearProgressBar from "./../components/ProgressBar";
import { UserContext } from "../ctx/UserContext";
import { useGenAlert } from "../ctx/GenAlertContext";
import ErrorFunction from "../errorFunction";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import BookLength from "../components/BookLength";
import BookSquare from "../components/BookSquare";
import { Link } from "react-router-dom";
import Rating from "../components/Rating.jsx";
import Buttons from "../components/Buttons"
import axios from "axios";
import { PLAY_IMG } from '../consts/books.const';
import "../style/book.scss";
import { useBooks } from "../stores/Books.store";
import { useDashboard } from "../stores/Dashboard.store";
import usePlayer from "../ctx/PlayerContext";


/*This page is used to display the book in all of the webpages except for the single book page (i.e. the bookshelf, dashboard, home,
  library, etc.) It determines what information appears about the book. It checks whether the user is a student or a teacher, if
  they are using a computer or phone, and what page the user is on, and displays different information for different situations.*/
export default function Book(props) {
  const { isDesktop } = useContext(DetectDeviceContext);
  const { chapter, bookId, setIfPlayer, setBookId, setNameBook, setNameAuthor, studentBooks, setBookStudentId, setBookCode, saveLastElement } = usePlayer()
  const userctx = useContext(UserContext);
  const genAlertCtx = useGenAlert();
  const DashboardStore = useDashboard()
  const BookStore = useBooks()

  const [shortTitle, setShortTitle] = useState(false);
  const [disableButton, setDisableButton] = useState();
  const [audioUrl, setAudioUrl] = useState();
  let currentAudio

  const location = useMemo(() => window.location.pathname, [window.location.pathname]);

  const { inCatalog, inBookshelf, inDashboard, isStudent } = useMemo(() => ({
    inCatalog: location === "/catalog" || location === "/elementary-school" || location === "/middle-school" || location === "/high-school",
    inBookshelf: location === "/mybooks",
    inDashboard: location === "/" && userctx.profileType === "teacher",
    isStudent: userctx.profileType === "student"
  }), [location, userctx.profileType]);


  const color = useMemo(() => {
    return ((props.book.current_time / props.book.audio_length) * 100) > 75 ? "green" :
      ((props.book.current_time / props.book.audio_length) * 100) > 25 ? "orange" : "dark-red"
  }, [props.book.current_time, props.book.audio_length])

  useEffect(() => {
    if (window.innerWidth < 650) {
      let titleLength = isDesktop ? props.progress ? 26 : 30 : 41
      if (props.book.book_name?.length > titleLength) {
        let tempTitle = props.book.book_name.slice(0, titleLength - 1)
        setShortTitle(tempTitle + '...')
      }
    }
  }, [])

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (isStudent) {
  //         let result = await axios.get(`/api/user/getUrlsChapters/${props.book.code}`)
  //         setAudioUrl(result.data.Files[1].Url);
  //       }
  //     } catch (error) {
  //       genAlertCtx.openGenAlert({ text: ErrorFunction(error) })
  //     }
  //   })()
  // }, [props.book])

  useEffect(() => {
    if (isStudent) setDisableButton(props.book.is_in);
    else setDisableButton(DashboardStore.pushedBooks.includes(props.book.id));
  }, [BookStore.books])

  /*This function determines if any of the books are in the student's bookshelf. If they are, the app stores
    the id of the book that belongs to the student. This is later used for rating the books*/
  const getBookStudentId = () => {
    for (let i = 0; i < studentBooks?.length; i++) {
      if (studentBooks[i].book.id === props.book.id) {
        setBookStudentId(studentBooks[i].id);
        return;
      }
    }
  }

  const playAudio = (e) => {
    setNameBook(props.book.book_name);
    setNameAuthor(props.book.author_name);
    setBookCode(props.book.code)
    setIfPlayer(true);
    saveLastElement(e.target)
    setBookId(props.book.id);
    getBookStudentId()
  }

  return (
    <React.Fragment>
      <div className={isDesktop ? "web-version" : "phone-bs"}>
        <div className={"container " + (location === "/" && isStudent ? "no-padding" : inBookshelf ? props.lastBook ? "center-it last" : "center-it" : "")}>
          <BookSquare
            book={props.book}
            index={props.index}
            getBookStudentId={getBookStudentId} />

          {inBookshelf || inDashboard ? (
            <div className={inBookshelf && userctx.profileType === "teacher" ? "bs-teacher" : ""}>
              <div className="align-items">
                <div className="bs-centering">
                  <div className="bs-container">
                    <div className={isDesktop ? "bs-progress-download" : ""}>
                      <div className="bs-author">
                        <h4 className="title">{shortTitle ? shortTitle : props.book.book_name}</h4>
                        <h5 className="author">{props.book.author_name}</h5>
                      </div>
                    </div>
                  </div>

                  {!inDashboard && isDesktop ? (
                    <h6 className="bs-summary">
                      <strong className="summary-header">תקציר:</strong>
                      {isDesktop ? <br /> : null}
                      {props.book.summary == "null" ? 'לא נמצא תקציר' : props.book.summary}
                    </h6>
                    //   <h6 className={`bs-summary ${props.book.summary?.length > 26 ? "two-line-summary" : ""}`}>
                    //   תקציר: {isDesktop && <br />}
                    //   {props.book.summary}
                    // </h6>
                  ) : null}

                  {isStudent ? <div className="progsress-bar-book-length">
                    <BookLength
                      book={props.book}
                      progress={props.progress}
                      currentChapter={props.book.id === bookId ? chapter ? chapter : props.book.chapter : props.book.chapter}
                      totalChapters={props.book.chapters_number}
                    />

                    <div className="bs-progress-bar">
                      <LinearProgressBar
                        value={props.book.id === bookId ? chapter ? chapter : props.book.chapter : props.book.chapter}
                        max={props.book.chapters_number} className="progress-bar"
                      />
                    </div>
                  </div> : null}
                  {inDashboard && props.progress ? (
                    <div className="progression">
                      <div className="span"> התקדמות</div>
                      <div className="circle">
                        <div title={`התלמיד קרא ${((props.book.current_time / props.book.audio_length) * 100)}%`} className={`progress ${color}`}></div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="bs-buttons">
                  {isStudent &&
                    (isDesktop && !inDashboard ? (
                      <button aria-label={props.progress ? 'פתיחת חלון ניגון' : ''} className={PLAY_IMG[!props.progress].className} onClick={playAudio} >
                        <img src={PLAY_IMG[!props.progress].src} alt={PLAY_IMG[!props.progress].alt} />
                      </button>
                    ) : null)}
                </div>
              </div>
            </div>
          ) : (
            <div className={inCatalog ? "info" : ""}>
              <Link to={`/catalog/${props.book.id}`} className="link">
                <h4 style={{ cursor: "pointer" }} aria-label={props.book.book_name} className={inCatalog ? "title" : ""}>
                  {props.book.book_name}
                </h4>
              </Link>
              <h5 className={inCatalog ? "author" : ""}>
                {props.book.author_name}
              </h5>

              {inCatalog ? (
                <>
                  {!isDesktop ? <BookLength book={props.book} /> : null}

                  <h6 className={`summary ${isDesktop && props.book.summary?.length > 26 ? "two-line-summary" : ""}`}>
                    תקציר: {isDesktop && <br />}
                    {props.book.summary == "null" ? 'לא נמצא תקציר' : props.book.summary}
                  </h6>

                  {isDesktop ? <BookLength book={props.book} /> : null}

                  {/* {!isDesktop ? (
                    <Link to={`/catalog/${props.book.id}`} className="summary link">
                      קרא עוד
                    </Link>
                  ) : null} */}

                  {isDesktop ? (
                    <div className="">
                      {props.book.avg_rating > 0 ?
                        <div className="s-genRating">
                          <h5 className="s-genR">דירוג כללי</h5>
                          <Rating rating={props.book.avg_rating} />
                        </div>
                        : null}
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          )}

          {isDesktop && inCatalog ? (
            <div className="catalog-buttons">
              <Buttons
                book={props.book}
                url={audioUrl}
                setUrl={setAudioUrl}
                author_name={props.book.author_name}
                disableButton={disableButton}
                isStudent={isStudent}
                setDisableButton={setDisableButton}
                setAudio={props.setAudio}
                isCatalog={true} />
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment >
  );
}

import React from 'react'
import { Link } from 'react-router-dom';
import '../style/subject-title.scss'

interface SubjectTitleProps {
    title: string;
    path?: string;
}

export const SubjectTitle: React.FC<SubjectTitleProps> = (props) => {
    return (
        <>
            {
                props.path ?
                    <Link to={props.path} className="linkB" aria-label={props.title}>
                        {props.title}<span className="arrow-move"> &gt;</span>
                    </Link> :
                    <>
                        {props.title}<span className="arrow-move"> &gt;</span>
                    </>
            }
        </>
    )
}

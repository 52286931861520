import React from 'react';
import '../style/loading-balls.scss'

export const LoadingBalls = () => {
    return (
        <div className="spinner">
            {[1, 2, 3].map((num) =>
                <div key={num} style={{ width: "1.5vh", height: "1.5vh", marginRight: "1vh" }} className={`bouncer${num}`}></div>
            )}
        </div>
    )
}

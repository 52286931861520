export const INPUTS = [
    {
        id: "nameInput",
        valueName: "name",
        label: "שם",
        type: "text",
        errorField: 1,
        name: "search",
        placeHolder: "",
        funcName: "setName",
        autoComplete: "off"
    }, {
        id: "emailInput",
        valueName: "email",
        label: "אימייל",
        type: "email",
        errorField: 2,
        name: "search",
        placeHolder: "",
        funcName: "setEmail",
        autoComplete: "off"
    }
]

export const SMALL_SCREEN_LINKS = [
    {
        a: {
            href: "https://www.hilma.tech/",
            target: "_blank",
            rel: "noopener noreferrer",
            key: "logo of Hilma organization"
        },
        img: {
            src: "/media/images/logoHilma.svg",
            alt: "logo of Hilma organization",
            className: "cfimg-hilma",
            style: { cursor: "pointer" }
        }
    }, {
        a: {
            href: undefined,
            target: "",
            rel: "",
            key: "Website logo"
        },
        img: {
            src: "/media/icons/blackLogo.svg",
            alt: "Website logo",
            className: "cfimg",
            style: {}
        }
    },
    {
        a: {
            href: "https://www.clfb.org.il/heb/main/",
            target: "_blank",
            rel: "noopener noreferrer",
            key: "blind library logo"
        },
        img: {
            src: "/media/images/blindLibrary.png",
            alt: "blind library logo",
            className: "cfimg",
            style: { cursor: "pointer" }
        }
    }
]

export const LARGE_SCREEN_LINKS = [
    SMALL_SCREEN_LINKS[0],
    SMALL_SCREEN_LINKS[1],
    {
        a: {
            href: "https://www.israelaudiobookproject.org/",
            target: "_blank",
            rel: "noopener noreferrer",
        },
        className: "ontact-footer",
        content1: "כל הזכויות שמורות לספריה לעוורים ולקויי קריאה",
        content2: "ולפרוייקט ספריה בהקשבה",
        isP: true,
        key: "rights"
    },
    SMALL_SCREEN_LINKS[2]
]

export const LARGE_SCREEN_SIZE = 1025
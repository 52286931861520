import React, { useState, useEffect, useContext, useMemo } from "react";
import { UserContext } from "../ctx/UserContext";
import { useGenAlert } from "../ctx/GenAlertContext";
import ErrorFunction from "../errorFunction";
import { rankings } from "../consts/books-read.consr";
import TagsPopup from "../components/ProfilesPopup";
import Profiles from "../components/Profiles.js";
import Axios from "axios";
import { DetectDeviceContext } from "../ctx/DetectDeviceContext";

/*This component displays the student's profile icon as well as a bubble with the   
  number of books they have read. There is also an option to change the icon*/
const BooksRead = ({ booksRead, ...props }) => {
  const [profilePopup, setProfilePopup] = useState(false);
  const [rankImg, setRankImg] = useState({ src: "", alt: "" });
  const { isDesktop } = useContext(DetectDeviceContext);
  const userctx = useContext(UserContext);
  const genAlertCtx = useGenAlert();

  const { level, rank } = useMemo(() => {
    let rank = "";
    let level = null;

    if (booksRead < 5) {
      level = 0;
      rank = rankings[0];
    } else if (booksRead < 8) {
      level = 1;
      rank = rankings[1];
    } else if (booksRead < 11) {
      level = 2;
      rank = rankings[2];
    } else if (booksRead < 14) {
      level = 3;
      rank = rankings[3];
    } else if (booksRead < 17) {
      level = 4;
      rank = rankings[4];
    } else if (booksRead < 20) {
      level = 5;
      rank = rankings[5];
    } else if (booksRead < 23) {
      level = 6;
      rank = rankings[6];
    } else {
      level = 7;
      rank = rankings[7];
    }

    return { level, rank };
  }, [booksRead]);

  useEffect(() => {
    if (userctx.profileIndex !== undefined) {
      const { src, alt } = Profiles[userctx.profileIndex];
      setRankImg({ src, alt });
    }
  }, [userctx.profileIndex]);


  useEffect(() => {
    (async () => {
      try {
        if (userctx.profileIndex !== undefined) {
          await Axios.post(
            `/api/user/updateProfileIndex/${userctx.profileIndex}`
          );
        }
      } catch (error) {
        genAlertCtx.openGenAlert({ text: ErrorFunction(error) });
      }
    })();
  }, [userctx.profileIndex]);

  const openProfilePopup = () => setProfilePopup(true);

  return (
    <React.Fragment>
      <div className="profileCenter">
        <div id="quote">
          {isDesktop ? (
            <div className="booksRead">
              <div className="good-job">
                <p style={{ fontWeight: "bold" }}>כל הכבוד {props.name}!</p>
                <p>
                  האזנת לכ-{booksRead ? booksRead : 0} ספרים עד כה
                </p>
              </div>
              <div className="trophy-container">
                <img src="/media/images/trophy.svg" alt="אייקון של גביע" />
                <div className="trophy-books">
                  {booksRead ? booksRead : 0}
                </div>
              </div>
            </div>
          ) : (
            <div className="booksRead">
              <div className="trophy-container">
                <img src="/media/images/trophy.svg" alt="אייקון של גביע" />
                <div className="trophy-books">{booksRead}</div>
              </div>
              <div className="goodJob" style={{ fontWeight: "bold" }}>
                כל הכבוד {props.name.split(" ")[0]}!
              </div>
              <p>האזנת לכ-{booksRead} ספרים עד כה</p>
            </div>
          )}
        </div>
        <div className="profileLevel">
          <h3>דירוג: {rank} ספרים</h3>
          <button aria-label="עריכה של תמונת פרופיל, מעבר לדיאלוג" className="edit-profile-img" onClick={openProfilePopup}>
            <div className="profileLevelImg">
              <img src={rankImg.src} alt={rankImg.alt} className={`ranking ${isDesktop ? "rankingBrowser" : ""}`} />
            </div>
            <img src={"/media/icons/pencil.svg"} className={`pencil ${isDesktop ? "pencilBrowser" : ""}`} alt="אייקון של עריכה" />
          </button>
        </div>
        <TagsPopup level={level} profilePopup={profilePopup} setProfilePopup={setProfilePopup} doneBooks={booksRead} />
      </div>
    </React.Fragment>
  );
}

export default BooksRead;
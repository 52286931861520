import React, { useState } from "react";
import { useEffect } from "react";
import "../style/rating.scss";

/*This component gets a number from 0.5-5 and returns a star rating that corresponds to that number.*/
function Rating(props) {
  const [stars, setStars] = useState([]);
  const [altStar, setAltStar] = useState([]);

  useEffect(() => {
    /*This reads in a number (0.5-5) that represents the rating*/
    const rating = props.rating;
    const newStars = [];
    const newAltStar = [];

    for (let i = 0; i < 5; i++) {
      /*If the rating is a whole number*/
      if (rating % 1 === 0) {
        if (i < rating) {
          newStars.push("/media/images/fullStar.png");
          newAltStar.push("full star");
        } else {
          newStars.push("/media/images/emptyStar.png");
          newAltStar.push("empty star");
        }
      }
      /*If the rating is not a whole number*/
      else {
        if (i < rating - 1) {
          newStars.push("/media/images/fullStar.png");
          newAltStar.push("full star");
        } else if (i < rating) {
          newStars.push("/media/images/halfStar.png");
          newAltStar.push("half star");
        } else {
          newStars.push("/media/images/emptyStar.png");
          newAltStar.push("empty star");
        }
      }
    }

    setStars(newStars);
    setAltStar(newAltStar);
  }, [props.rating])

  // credits: Icons made by <a href="https://fontawesome.com/v3.2.1/icon/star" title="FontAwesome">FontAwesome</a> from <a href="https://fontawesome.com/v3.2.1/" title="FontAwesome"> www.fontawesome.com</a> 
  // credits: Icons made by <a href="https://fontawesome.com/icons/star-half" title="FontAwesome">FontAwesome</a> from <a href="https://fontawesome.com/v3.2.1/" title="FontAwesome"> www.fontawesome.com</a> 

  return (
    <h5 className="stars">
      {stars.map((item, index) => (
        <img key={index} className="star" src={item} alt={altStar[index]} />
      ))}
    </h5>
  );
}
export default Rating;

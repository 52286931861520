export const RATING_PARTS = [
    {
        content: "דירוג כללי",
        className: "star1",
        rating: "general"
    }, {
        content: "ביצוע",
        className: "",
        rating: "quality"
    }, {
        content: "סיפור",
        className: "",
        rating: "story"
    }
]

export const STARS_STEPS = 0.5
import React from "react";
import "../style/tooltip.scss";

/*This is a general component for the tooltip. It takes in texts through props and returns a tooltip.*/
export default function Tooltip({ onClick, tooltiptext, popup, style, text, hiddenTooltipForAccessability, className }) {
    return (
        <div role={onClick && "button"} onClick={onClick} aria-label={`${tooltiptext} ${hiddenTooltipForAccessability ?? ''}`} tabIndex={(tooltiptext || hiddenTooltipForAccessability) && 0} className={`tooltip ${className}`}>
            {tooltiptext && !popup ?
                <div className="tooltiptext" style={{ opacity: 1, ...style }}>{tooltiptext}</div> :
                <div></div>}
            {text}
        </div>
    )
}
import React, { useContext, useMemo } from 'react';
import Header from '../components/Header.jsx'
import { useBooks } from '../stores/Books.store.js';
import SecondHeader from '../components/SecondHeader.jsx'
import Catalog from './Catalog.jsx'
import Categories from './Categories.jsx'

/*This page toggles between the Catalog and Categories components while keeping the header at  
  the top of the page. The header has the search input, and when the user is in the main   
  library page, and not search, it contains buttons for various genre filters.*/
export default function Library(props) {
  const BookStore = useBooks();
  const header = useMemo(() => { return <SecondHeader {...props} title="ספריה" /> }, [BookStore.search, props.location.pathname])

  return (
    <React.Fragment>
      {header}
      {
        window.location.pathname === '/catalog' ?
          <Catalog {...props} />
          :
          <Categories />
      }
    </React.Fragment>

  );
}
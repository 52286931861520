import React from 'react'
import '../style/generic-red-popup.scss'

interface PropsPropsGenericRedPopup {
    text: string;
    color: string;
}
export const PropsGenericRedPopup: React.FC<PropsPropsGenericRedPopup> = (props) => {
    return (
            <div className={`pink-popup ${props.color}`}>
                <div className="welcome">{props.text}</div>
            </div>
    )
}


import { useEffect, useState, useMemo, useContext } from "react";
import "../../style/catalog.scss";
import Book from "../../pages/Book";
import { GenericButton } from "../../components/GenericButton";
import { useHistory } from "react-router-dom";
import { useBooks } from "../../stores/Books.store";
import { DetectDeviceContext } from "../../ctx/DetectDeviceContext";
import Header from "../../components/Header";


function BooksByAge() {

    const BookStore = useBooks();
    const [audio, setAudio] = useState(null);
    const [ageGroup, setAgeGroup] = useState([]);
    const [ageGroupSearch, setAgeGroupSearch] = useState([]);
    const [title, setTitle] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const location = useMemo(() => window.location.pathname, [window.location.pathname]);
    const history = useHistory();
    const { isMobile } = useContext(DetectDeviceContext);


    useEffect(() => {
        if (location === '/elementary-school') {
            setAgeGroup(BookStore.elementaryBooks);
            setAgeGroupSearch(BookStore.elementaryBooks);
            setTitle('ליסודי:');
        } else if (location === '/middle-school') {
            setAgeGroup(BookStore.middleBooks);
            setAgeGroupSearch(BookStore.middleBooks);
            setTitle('לחטיבת ביניים:');
        } else if (location === '/high-school') {
            setAgeGroup(BookStore.highBooks);
            setAgeGroupSearch(BookStore.highBooks);
            setTitle('לתיכון:');
        }

    }, [location, BookStore.elementaryBooks, BookStore.middleBooks, BookStore.highBooks])



    async function search(e) {
        setSearchValue(e.target.value);
        const bla = ageGroup.filter((ee) => ee.book.book_name.includes(e.target.value));
        setAgeGroupSearch(bla);
    }


    function changeAudio(newAudio) {
        if (audio === newAudio) return;
        audio && audio.pause();
        setAudio(newAudio);
    }


    /*This function makes sure that if there is a really long word in the  
      search that it doesn't spill out of the borders of the search page.*/
    // const DividesTheWord = () => {
    //     if (BookStore.search === '' || ' ') return BookStore.genre;
    //     const indexSpace = BookStore.search.indexOf(' ');
    //     if (BookStore.search.length > 10 && indexSpace === -1) {
    //         const searchSlice = BookStore.search.slice(0, 10)
    //         return (`${searchSlice} ...`)
    //     } else {
    //         return (BookStore.search)
    //     }
    // }


    return (
        <div>
            {!isMobile ?
                <div className="second-header">
                    <div className='header age-category-header'>
                        <div className="first-side">
                            <GenericButton aria-label='חזרה לספריה' className='back-button' onClick={() => { history.push('/'); }}>
                                <img className='back-button' src='/media/icons/backArrow.svg' />
                            </GenericButton>
                            <h1 className='title'>{title}</h1>
                        </div>
                        <div className="search-container-top">
                            <div className='search'>

                                <img style={{ zIndex: 0 }} className="button" alt="" id="searchButton" src="/media/icons/magnifyingGlass.svg" />
                                <input style={{ width: '24vh' }} type="text" id="searchInput" value={searchValue} onChange={(e) => { search(e); }} placeholder="הקלד שם ספר, סופר או ז'אנר" />
                                {searchValue ?
                                    <div className="results-number" aria-live="assertive" >{ageGroupSearch.length} תוצאות</div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    <Header title={title} isAgeBooks={true} />
                    <div className="search-container-top age-category-search">
                        <div className='search'>

                            <img style={{ zIndex: 0 }} className="button" alt="" id="searchButton" src="/media/icons/magnifyingGlass.svg" />
                            <input style={{ width: '24vh' }} type="text" id="searchInput" value={searchValue} onChange={(e) => { search(e); }} placeholder="הקלד שם ספר, סופר או ז'אנר" />
                            {searchValue ?
                                <div className="results-number" aria-live="assertive" >{ageGroupSearch.length} תוצאות</div>
                                : ''}
                        </div>
                    </div>
                </>
            }
            <div className="init" style={{ paddingBottom: '5vh' }}>
                {ageGroupSearch.map((title, index) => {
                    return (
                        <div style={{ margin: 'auto', width: '90vw' }}>
                            < Book book={{ ...title }.book} key={index} index={index} setAudio={changeAudio} />
                        </div>
                    );
                })}

                {!searchValue.length || ageGroupSearch.length ?
                    <div className="no-more-books">
                        נראה שעברתם על כל הספרים, מוזמנים לחפש ספרים נוספים <a href="/">בספריה</a>
                    </div>
                    :
                    <div className="no-results-container">
                        <div>
                            <img className="sad-emoji" src={"/media/images/noResultsWorm.svg"} alt={"אין תוצאות אייקון של תולעת ספרים"} />
                        </div>
                        <div className="no-results">{`לא הצלחנו למצוא את "${/*DividesTheWord()*/ searchValue}"`}</div>
                        <div className="no-results">בטוחים שהקלדתם נכון?</div>
                    </div>
                }
            </div>
        </div>
    );

}

export default BooksByAge;
export const CATEGORIES = [
    {
        value: 0,
        text: "כל הספרים",
        part: "myBooks",
        emptyMessage: "אין לך כלל ספרים",
        // Back: "libary"

    }, {
        value: 1,
        text: "עוד לא התחלתי",
        part: "notStartedBooks",
        emptyMessage: "אין לך ספרים שעדיין לא התחלת",
        // Back: "myBooks"
    }, {
        value: 2,
        text: "התחלתי",
        part: "progressBooks",
        emptyMessage: "אין לך ספרים שכבר התחלת",
        // Back: "myBooks"
    }, {
        value: 3,
        text: "סיימתי",
        part: "doneBooks",
        emptyMessage: "אין לך ספרים שסיימת",
        // Back: "myBooks"
    }
];

export const BOOKS_PAGE_PARTS = [
    {
        bookStorePart: "progressBooks",
        className: "progress",
        operator: ">",
        pressed1: 2,
        pressed2: 0,
        title: "מאזין כרגע",
        progress: true,
        lastBook: "progressBooks"
    }, {
        bookStorePart: "notStartedBooks",
        className: "done",
        operator: ">",
        pressed1: 1,
        pressed2: 0,
        title: "ספרים שעוד לא התחלתי",
        progress: "true",
        lastBook: "progressBooks"
    }, {
        bookStorePart: "doneBooks",
        className: "done",
        operator: "!==",
        pressed1: 3,
        pressed2: 0,
        title: " ספרים שסיימתי ",
        progress: false,
        lastBook: ""
    }
];

export const SET_STATE_PAGE_PARTS = [
    {
        bookStorePart: "progressBooks",
        pressed: 2,
        content1: "עדיין אין ספרים שהתחלת.",
        content2: "לך ל",
        onClickValue: 0,
        linkText: "כל הספרים",
        content3: " והתחל להאזין לספרים."
    }, {
        bookStorePart: "doneBooks",
        pressed: 3,
        content1: "עדיין אין ספרים שסיימת.",
        content2: "לך ל",
        onClickValue: 0,
        linkText: "כל הספרים",
        content3: " והמשך להאזין לספרים."
    }
];
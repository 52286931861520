import React, { useContext, useState } from "react";
import { ValidateFields } from "@hilma/tools";
import { useGenAlert } from "../ctx/GenAlertContext"
import ErrorFunction from "../errorFunction";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import Popup from "../components/Popup.jsx";
import Axios from "axios";
import { PasswordInput } from "../components/PasswordInput";
import { GenericButton } from "../components/GenericButton";
import "../style/open.scss";

/*This is the forgot password webpage.*/
export default function ForgotPassword(props) {
    const { isDesktop } = useContext(DetectDeviceContext);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [submitButton, setSubmitButton] = useState(false);

    const genAlertCtx = useGenAlert();

    /*This function validates all of the user's inputs; It checks that the password and confirm password are
      in the right format and that they match. If they do, then the function sends a call to the server to 
      change the user's password. This will only happen if there is a token and email in the url. These come
      from the email that is sent to the user with the link to this page. This increases the user's security.*/
    const validateAll = async () => {
        let passwordE = ValidateFields.validatePasswordInput(password, true);
        let confirmPasswordE = ValidateFields.validateConfirmPasswordInput(
            password,
            true,
            confirmPassword
        );

        setSubmitButton(true);
        setErrors({
            password: passwordE,
            confirmPassword: confirmPasswordE,
        });

        if (!passwordE && !confirmPasswordE) {
            try {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const token = urlParams.get('token')
                const email = urlParams.get('email')

                await Axios.post("/api/user/changePassword", {
                    token: token,
                    email: email,
                    newPassword: password
                });

            }
            catch (error) {
                genAlertCtx.openGenAlert({ text: ErrorFunction(error) })
            }
        }

    };

    /*Once the user successfully changes their password, there is a popup confirming the 
      change. This function closes the popup and sends the user to the login page.*/
    const closePopup = () => {
        setSubmitButton(false);
        props.history.push("/");
    }

    return (
        <div className={`open-${isDesktop ? 'web' : 'phone'}`}>
            <img src={`/media/images/${isDesktop ? 'imgTop.svg' : 'colorsUp.svg'}`} alt="" className="imgtop" />

            <div className="forgot-pass-form">
                <div className="info-form">
                    <div className="imglogo">
                        <img src="/media/icons/whiteTextIcon.png" className="imgop" alt="" />
                        {/* <p className="library">ספריה בהקשבה</p> */}
                    </div>
                    <div className="info-input">
                        <PasswordInput className='password-field' htmlFor="password" label={'סיסמה חדשה'} value={password} onChange={(e) => setPassword(e.target.value)} error={errors.password} />
                        <PasswordInput className='password-field' htmlFor="confirm-password" label={'אימות סיסמא'} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} error={errors.confirmPassword} />
                        <GenericButton className="register-me1 imgin margin-top" onClick={validateAll} text='שנה סיסמא' />
                    </div>
                </div>
            </div>

            {submitButton && !errors.password && !errors.confirmPassword ?
                <Popup text="הסיסמה שונתה בהצלחה!" delete="/media/icons/delete2.svg" close={() => { closePopup() }} /> : ""}

            <img src={`/media/images/${isDesktop ? 'imgBotton.svg' : 'colorsDown.svg'}`} alt="" className="imgbottom" />

        </div>
    );

}
import React, { useState, useEffect, useContext } from "react";
import { ContactContext } from "../ctx/ContactContext";
import { LoadingBalls } from "./LoadingBalls";
import ErrorFunction from "../errorFunction"
import ButtonsPopup from "./ButtonsPopup";
import Tooltip from "../components/Tooltip";
import Axios from "axios";
import { GenericButton } from "./GenericButton";
import { useBooks } from "../stores/Books.store";
import { useDashboard } from "../stores/Dashboard.store";
import axios from "axios";
import GenericLoading from "./GenericLoading";
import usePlayer from "../ctx/PlayerContext";
import { useAsyncEffect } from "@hilma/tools";
import { useGenAlert } from "../ctx/GenAlertContext"



/*This component displays and gives functionality to the "play an example" and "add to bookshelf" buttons.*/
export default function Buttons(props) {

    const genAlertCtx = useGenAlert()
    const [loading, setLoading] = useState(false);
    const [audio, setAudio] = useState({});
    const [audioIcon, setAudioIcon] = useState(true);
    const [text, setText] = useState("");
    const [clicked, setClicked] = useState();
    const [, setTimer] = useState(0);
    const [isUrlNull, setIsUrlNull] = useState(false);
    // const [audioServer, setAudioServer] = useState()
    const [audioloading, setAudioLoading] = useState(false);
    const { studentBooks, setBookStudentId, ifPlayer, littlePlayer, setPlaying, setIfPlayer, setBookId, setNameBook, setNameAuthor, setBookCode, saveLastElement } = usePlayer()

    const bpctx = useContext(ContactContext);
    const BookStore = useBooks()
    const DashboardStore = useDashboard()

    useEffect(() => {
        if (audio.paused) {
            changeAudioIcon(true)
        } else if (audio.paused === false) {
            changeAudioIcon(false)
        }

    }, [audio.paused])
    const changeAudioIcon = (flag) => {
        setAudioIcon(flag)
    }
    useEffect(() => {
        let interval = null
        if (!audioIcon)
            interval = setInterval(() => {
                setTimer((timer) => timer + 1)
            }, 1000)
        else clearInterval(interval)
        return (() => {
            clearInterval(interval)
        })
    }, [audioIcon])

    useAsyncEffect(async () => {

        try {
            let newAudio
            if (props.url) {
                newAudio = new Audio(props.url)
                setAudio(newAudio);
                props?.setAudio(newAudio)
                setIsUrlNull(false);
                await newAudio.play()
                changeAudioIcon(false)

            } else {
                setIsUrlNull(true)
            }
            if (props.isStudent)
                return (() => {
                    if (props.url)
                        newAudio.pause()
                })

        } catch (err) {
            console.error(err);
        }
    }, [props.url])

    useEffect(() => {
        if (DashboardStore.pushedBooks.includes(props.book.id))
            props.setDisableButton(true)
    }, [DashboardStore.pushedBooks.length])

    /*If the user is a student, this function is called when they press the
    add book button. It adds the book to the current user's bookshelf.*/
    async function addBook() {
        setClicked(true)
        setLoading(true);
        let res;
        try {
            res = await Axios.post(`/api/user/addBook`, {
                book: props.book.id,
            });
            if (res.status === 201) {
                setLoading(false);
                setText("הספר נוסף בהצלחה למדף הספרים שלך!")
                bpctx.setPopup(true);
                props.book.is_in = true
                props.setDisableButton(true)
                let index = BookStore.buttonsInfo.findIndex(item => item.id === props.book.id)
                if (index !== -1) {
                    BookStore.buttonsInfo[index].is_in = true
                }
                let index2 = BookStore.booksInfo.findIndex(item => item.bookInfo.id === props.book.id)
                if (index2 !== -1) {
                    BookStore.booksInfo[index2].bookInfo.is_in = true
                }
                //DO NOT DELETE
                // const chapterResult = await Axios.get(
                //     `/api/chapters/getLengthChapters/${props.book.id}`
                // );
                const chapterResult = 0;
                let temp = {
                    id: props.book.id,
                    book_name: props.book.book_name,
                    author_name: props.author_name,
                    summary: props.book.summary,
                    chapter: 1,
                    chapters_number: chapterResult.data,
                    audio_length: props.book.audio_length,
                    audio_url: props.url,
                    is_in: true
                }
                BookStore.myBooks.push(temp);
                BookStore.notStartedBooks.push(temp);
                BookStore.updateLastBook(temp, "new");
            } else {
                setClicked(false)
                setText("קרתה תקלה. אנא נסה שנית מאוחר יותר.")
                bpctx.setPopup(true);
            }
        } catch (error) {
            setLoading(false)
            bpctx.setPopup(true);
            setText(ErrorFunction(error));
        }
    }

    /*If the user is a teacher, this function is called when they press the add
    book button. It adds the book to all of his/her students' bookshelves.*/
    async function teacherAddBook() {
        setClicked(true)
        setLoading(true);
        let res;
        try {
            let arr = []
            if (!DashboardStore.students.length) {
                setLoading(false);
                setText("אבל... אין לך תלמידים כפרע")
                bpctx.setPopup(true);
                setClicked(false)
                return
            }
            DashboardStore.students?.forEach(child => {
                let isIn = false;
                for (let j = 0; j < child.books.length && !isIn; j++) {
                    let tempBook = child.books[j]
                    if (tempBook.id === props.book.id)
                        isIn = true
                }
                !isIn && arr.push(child.username)
            })
            if (DashboardStore.students.length && arr.length === 0) {
                setLoading(false);
                setText("הספר כבר נמצא במדפי הספרים של התלמידים שלך!")
                bpctx.setPopup(true);
                props.setDisableButton(true)
                return
            }
            else {
                res = await Axios.post(`/api/book-student/addBookForTeacher`, {
                    users: arr,
                    book: props.book.id
                });
                if (res.status === 201) {
                    setLoading(false);
                    setText("הספר נוסף למדפי הספרים של התלמידים שלך !")
                    bpctx.setPopup(true);
                    props.setDisableButton(true)
                    DashboardStore.pushedBooks.push(props.book.id)
                    //DO NOT DELETE
                    // const chapterResult = await Axios.get(
                    //     `/api/chapters/getLengthChapters/${props.book.id}`
                    // );
                    const chapterResult = 0;
                    DashboardStore.addBooksToStudents(arr, {
                        id: props.book.id,
                        book_name: props.book.book_name,
                        author_name: props.author_name,
                        summary: props.book.summary,
                        chapter: 1,
                        chapters_number: chapterResult.data,
                        audio_length: props.book.audio_length,
                    })
                } else {
                    setClicked(false)
                    setText("קרתה תקלה. אנא נסה שנית מאוחר יותר.")
                    bpctx.setPopup(true);
                }
            }
        } catch (error) {
            setLoading(false)
            bpctx.setPopup(true);
            setText(ErrorFunction(error));
        }
    }

    /*This function plays a short example of the audio of that specific book. It also toggles the play/pause icon.*/
    async function playExample() {

        // props.setAudio(null)
        if (audio && audio.paused === false) {// if audio is running just stop it and change the button logo
            audio.pause()
            setAudioIcon(true)
            return
        }
        if (audio && audio?.paused) {// if the audio is already requseted from the server just play it without unnescery requset from the server
            audio.play()
            props.setUrl(audio.src) // will change the parent Audio Url
            props.setAudio(audio)
            setAudioIcon(false)
            return
        }

        setAudioLoading(true)
        try{

            const serverResult = await axios.get(`/api/user/getUrlsChapters/${props.book.code}`)
            setAudioLoading(false)
            const urlToAudio = serverResult.data.Files[1].Url
            props.setUrl(urlToAudio) // will change the parent Audio Url
        } catch(error){
            genAlertCtx.openGenAlert({ text: "משהו השתבש" })

        }


        if (ifPlayer || littlePlayer) {
            setPlaying(false);
        }
        // setAudioIcon(false);
    }



    /*Gives functionality to the add book button based on whether it was already clicked (whether the book is already in the bookshelf)*/
    const addButton = () => {
        return (<>
            <GenericButton
                disabled={props.disableButton || clicked}
                style={{ backgroundColor: props.disableButton && 'gray', cursor: props.disableButton ? 'default' : "" }}
                className={`bpButton addShelf ${!props.isStudent ? "addShelfTeacher" : ""}`}
                onClick={props.isStudent ? addBook : teacherAddBook}
                text={loading ?
                    <LoadingBalls /> :
                    (
                        props.disableButton ? `הספר נמצא על המדף ${props.isStudent ? '' : 'של התלמיד'}` : `+ הוסף למדף הספרים ${props.isStudent ? '' : 'של התלמיד'}`
                    )} >

            </GenericButton>
        </>)
    };

    const getBookStudentId = () => {
        for (let i = 0; i < studentBooks?.length; i++) {
            if (studentBooks[i].book.id === props.book.id) {
                setBookStudentId(studentBooks[i].id);
                return;
            }
        }
    }

    const playAudio = (e) => {
        setNameBook(props.book.book_name);
        setNameAuthor(props.book.author_name);
        setBookCode(props.book.code)
        setIfPlayer(true);
        saveLastElement(e.target)
        setBookId(props.book.id);
        getBookStudentId()
    }

    return (
        <React.Fragment>
            {(bpctx.popup && text.length1 > 0) ?
                <ButtonsPopup
                    popup={bpctx.popup}
                    setPopup={bpctx.setPopup}
                    text={text}
                    setText={setText}
                    imgsrc="/media/images/audiobook.png" /> : null}
            <div className="buttons">
                {props.isStudent ?
                    !props.disableButton ?
                        <GenericButton aria-label="נגן דוגמה" className={`bpButton examplePlayer ${props.isBookPage ? "first-button" : ""}`} onClick={playExample}>
                            {audioloading ?
                                <GenericLoading /> :
                                <div className={props.isBookPage ? "text-on-button" : ""}>
                                    <img className="play" src={audioIcon ? "/media/icons/playButton.svg" : "/media/icons/pauseButton.svg"} alt={`כפתור של ${audioIcon ? 'ניגון' : 'השהייה'}`} />
                                    נגן דוגמה
                                </div>
                                // /*{ Credits: Icons made by <a href="https://www.flaticon.com/authors/google" title="Google">Google</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>} */
                            }
                        </GenericButton> :
                        <GenericButton aria-label="נגן" className={`bpButton examplePlayer ${props.isBookPage ? "first-button" : ""}`} onClick={playAudio}>
                            {audioloading ?
                                <GenericLoading /> :
                                <div className={props.isBookPage ? "text-on-button" : ""}>
                                    <img className="play" src="/media/icons/playButton.svg" alt={`כפתור של ניגון`} />
                                    נגן
                                </div>
                            }
                        </GenericButton>
                    : undefined
                }
                <br />
                <Tooltip
                    text={addButton()}
                    popup={bpctx.popup} />
            </div>
        </React.Fragment>
    )
}
import { React, useContext, useEffect, useState } from "react";
import "../style/recommended-age-books.scss";
// import { StoreContext } from "../../stores/StoreProvider";
import { useHistory } from 'react-router-dom';
import { useAsyncEffect } from "@hilma/tools";
import { Skeleton } from "@mui/material";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { useBooks } from "../stores/Books.store";

function RecommendedAgeBooks() {

    const BookStore = useBooks()
    const { isDesktop } = useContext(DetectDeviceContext);
    const [arr, setArr] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useAsyncEffect(async () => {

        let newArr = [{ name: 'ליסודי', books: [], path: 'elementary-school', length: 0 }, { name: 'לחטיבת ביניים', books: [], path: 'middle-school', length: 0 }, { name: 'לתיכון', books: [], path: 'high-school', length: 0 }];
        await BookStore.elementaryBooks?.forEach((item) => {
            if (newArr[0].books.length < 4 && { ...item.book }.cover !== null) {
                newArr[0].books.push({ ...item.book }.cover);
            }
        });

        newArr[0].length = BookStore.elementaryBooks.length;

        await BookStore.middleBooks?.forEach((item) => {
            if (newArr[1].books.length < 4 && { ...item.book }.cover !== null) {
                newArr[1].books.push({ ...item.book }.cover);
            }
        });

        newArr[1].length = BookStore.middleBooks.length;

        await BookStore.highBooks?.forEach((item) => {
            if (newArr[2].books.length < 4 && { ...item.book }.cover !== null) {
                newArr[2].books.push({ ...item.book }.cover);
            }
        });

        newArr[2].length = BookStore.highBooks.length;
        setArr(newArr);
        setLoading(false);
    }, [BookStore.elementaryBooks, BookStore.middleBooks, BookStore.highBooks]);

    return (
        <div className='age-recommended-container'>
            {loading ?
                <div className="skeleton-box" style={{ marginLeft: '25vw' }}>
                    {[1, 2, 3].map((item, index) => {
                        return (
                            <div key={index} >
                                <Skeleton sx={{ borderRadius: '5px', marginLeft: '4vw', marginBottom: '1vh' }} variant="rectangular" width={'16vw'} height={'16vw'} animation='wave' />
                            </div>
                        );
                    })}
                </div>


                : arr ?
                    arr.map((item, index) => {
                        return (
                            <button style={{ border: 'none', background: 'none' }} aria-label={item.name} key={index} onClick={() => { history.push(`/${item.path}`); }}>
                                <div className={`gray-background , ${isDesktop ? "web-version" : ''}`}>
                                    <div className="grid-inner-container">

                                        {item.books.map((item, index) => {
                                            return (
                                                    <img src={item} key={index} className='book-image' />
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className='recommended-for-footer'>
                                    <p className='name'>{item.name}</p>
                                    <p className='number'>{`${item.length} ספרים`}</p>
                                </div>
                            </button>
                        );
                    }) : ''

            }
        </div >
    );
}

export default RecommendedAgeBooks;
function ErrorFunction(err) {
  if ( typeof err === "undefined"||err.message === "NO_INTERNET" || err.message === "Network Error" ) return "משהו השתבש, אנא וודא שהחיבור לרשת תקין";
  if (!err) return;
  if (err.response && err.response.data && err.response.data.error) {
    return err.response.data.error
  }

 
  if (typeof err.message === "string")

if (err.error) {
    if (typeof err.error.details === "object") {
      err.error = err.error.details[0];
    }
    if (err.error.code) {
      switch (err.error.code) {
        case "ER_BAD_FIELD_ERROR":
          return "משהו השתבש בעת קבלת הנתונים, אנא נסה שוב מאוחר יותר";
        case "UNAUTHORIZED":
          return "מצטערים אין לך רשות לדף/פעולה זה/זאת";
        default:
          return "משהו השתבש, אנא נסה שנית מאוחר יותר";
      }
    }

    if (err.error.message)
      switch (err.error.message) {
        case "No response, check your network connectivity":
          return "משהו השתבש, אנא וודאו שהחיבור לרשת תקין";
        case "Authorization Required":
          return "משהו השתבש, אין לך הרשאת כניסה לעמוד זה";
        default:
          return "משהו השתבש, נסו שנית מאוחר יותר";
      }

    if (err.error.msg) return err.error.msg;
  }

  return "משהו השתבש, נסו שנית מאוחר יותר";
}

export default ErrorFunction;

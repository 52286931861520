//creating a type blob file for downloads 

const downloadBlob = async (blob: Blob | string, fileName: string) => {
    const url = blob instanceof Blob ? URL.createObjectURL(blob) as string : blob;
    const link = document.createElement('a');

    link.href = url;
    link.download = fileName;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default downloadBlob;
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PasswordInput = ({ value, onChange, error, className, label, extraLabel, htmlFor }) => {
    const [isShowPassword, setIsShowPassword] = useState(false)

    const showPassword = () => {
        setIsShowPassword(prev => !prev)
    };

    return (
        <>
            <div className={`login-input info-input ${className} `}>
                <div className="info-label">
                    <label htmlFor={htmlFor || 'password'}>{label || 'סיסמא'}</label>
                    <div className="extra-label">{extraLabel}</div>
                </div>
                <div className={`verify-pass ${error ? "error-border" : "no-error-border"}`}>
                    <input type={isShowPassword ? 'text' : 'password'} id={htmlFor || 'password'} name={htmlFor || 'password'} className={"no-error-border"} value={value} onChange={onChange} maxLength="20" />
                    <FontAwesomeIcon onClick={showPassword} icon={["fas", "eye"]} color={!isShowPassword ? '#787a81' : '#ee5c5c'} />
                </div>
            </div>
            <div className="error" aria-live="assertive" >{error}</div>
        </>
    )
}

/*This holds all of the urls for all of of the profile images*/
export default  [
    //turtle
    {src:"/media/profile/turtle.svg", level:0 ,alt:"turtle" ,booksRead:0},
    {src:"/media/profile/turtle1.svg", level:0,alt:"turtle" ,booksRead:0},
    {src:"/media/profile/turtle2.svg", level:0,alt:"turtle" ,booksRead:0},
    //bumblebee
    {src:"/media/profile/bee.svg", level:1 ,alt:"bumblebee" ,booksRead:5},
    {src:"/media/profile/bee1.svg", level:1,alt:"bumblebee" ,booksRead:5},
    {src:"/media/profile/bee2.svg", level:1 ,alt:"bumblebee" ,booksRead:5},
    //unicorn
    {src:"/media/profile/unicorn.svg", level:2 ,alt:"unicorn" ,booksRead:8},
    {src:"/media/profile/unicorn1.svg", level:2,alt:"unicorn" ,booksRead:8},
    {src:"/media/profile/unicorn2.svg", level:2 ,alt:"unicorn" ,booksRead:8},
    //koala
    {src:"/media/profile/koala.svg", level:3 ,alt:"koala" ,booksRead:11},
    {src:"/media/profile/koala1.svg", level:3,alt:"koala" ,booksRead:11},
    {src:"/media/profile/koala2.svg", level:3 ,alt:"koala" ,booksRead:11},
    //cat
    {src:"/media/profile/cat.svg", level:4 ,alt:"cat" ,booksRead:14},
    {src:"/media/profile/cat1.svg", level:4,alt:"cat" ,booksRead:14},
    {src:"/media/profile/cat2.svg", level:4 ,alt:"cat" ,booksRead:14},
    //horse
    {src:"/media/profile/horse.svg", level:5 ,alt:"horse" ,booksRead:17},
    {src:"/media/profile/horse1.svg", level:5,alt:"horse" ,booksRead:17},
    {src:"/media/profile/horse2.svg", level:5 ,alt:"horse" ,booksRead:17},
    //worm
    {src:"/media/profile/worm.svg", level:6 ,alt:"worm" ,booksRead:20},
    {src:"/media/profile/worm1.svg", level:6 ,alt:"worm" ,booksRead:20},
    {src:"/media/profile/worm2.svg", level:6 ,alt:"worm" ,booksRead:20},
    //lion
    {src:"/media/profile/lion.svg", level:7 ,alt:"lion" ,booksRead:23},
    {src:"/media/profile/lion1.svg", level:7 ,alt:"lion" ,booksRead:23},
    {src:"/media/profile/lion2.svg", level:7 ,alt:"lion" ,booksRead:23},
]


// div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
//<div>Icons made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">Vectors Market</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
//<div>Icons made by <a href="https://www.flaticon.com/authors/dinosoftlabs" title="DinosoftLabs">DinosoftLabs</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    



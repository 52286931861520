import React, { useContext, useEffect } from "react";
import { isIOS } from 'react-device-detect';
import { InputContext } from "../ctx/InputContext";
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { DetectDeviceContext } from "../ctx/DetectDeviceContext";


/*This popup appears with a confirmation message when the user adds a book to the bookshelf.*/
function ButtonsPopup(props) {
    const { setPopupOpen } = useContext(InputContext);
    const { isDesktop } = useContext(DetectDeviceContext);

    useEffect(() => {
        if (isIOS && props.popup) setPopupOpen(true);
    }, [props.popup, isIOS]);

    const closePopUp = (e) => {
        e.preventDefault();
        props.setPopup(false);
        props.setText?.("");
        setPopupOpen(false);
    };

    return (
        <div className="bp-popup">
            <Dialog open={props.popup} aria-labelledby="reset-modal">
                <div className={`popup-background ${isDesktop ? "web-version" : ""}`}>
                    <div className="bounce-7">
                        <div className='body saved-popup' style={{ textAlign: "center", direction: "rtl" }}>
                            <button className="close-popup" onClick={closePopUp} >
                                <img src={"/media/icons/delete2.svg"} alt="כפתור סגירה" className="popup-icon" />
                            </button>
                            <DialogContent className="p-0">
                                <div style={{ height: '100%', overflow: 'hidden' }}>
                                    <img src={props.imgsrc} alt="" />
                                    {/* Credits:  <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>*/}
                                    <div className="popup-text"> {props.text} </div>
                                </div>
                            </DialogContent>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>);

} export default ButtonsPopup;
import React, { useContext } from "react";
import usePlayer from "../../ctx/PlayerContext";
import { GenericButton } from "../../components/GenericButton";
import { DetectDeviceContext } from "../../ctx/DetectDeviceContext";

/*This component allows the user to toggle between chapters using the arrows.*/
export default function ChapterChangeArrows(props) {
    const { isDesktop } = useContext(DetectDeviceContext);
    const { chapter, chapters, setChapter, littlePlayer } = usePlayer()

    /*Move to previous chapter*/
    const previous = () => {
        console.log("previous");

        chapter !== chapters[0] && setChapter(chapter - 1);
    };

    /*Move to next chapter*/
    const next = () => {
        console.log("next");
        chapter !== chapters[chapters.length - 1] &&
            chapters[chapter] &&
            setChapter(chapter + 1);
    };

    return (
        <>
            {props.move === "previous" ? (
                <GenericButton
                    style={{ backgroundColor: "inherit", width: "fit-content", borderRadius: '0' }}
                    onClick={!props.miniLoading ? previous : undefined}
                    disabled={chapter === chapters[0]}
                    ariaLabel="הפרק הקודם" >
                    <img
                        src={littlePlayer ? "/media/icons/previousLittle.svg" : "/media/icons/previous.svg"}
                        alt="הפרק הקודם"
                        style={
                            chapter === chapters[0]
                                ? { opacity: "0.5", cursor: "default" }
                                : !props.miniLoading && isDesktop ? { cursor: "pointer", opacity: "1" } : { opacity: "1" }
                        }
                    />
                </GenericButton>
            ) : (props.move === "next" ?
                (
                    <GenericButton
                        style={{ backgroundColor: "inherit", width: "fit-content", borderRadius: '0' }}
                        onClick={!props.miniLoading ? next : undefined}
                        disabled={chapter === chapters[chapters.length - 1] || !chapters[chapter]}
                        ariaLabel="הפרק הבא" >
                        <img
                            src={littlePlayer ? "/media/icons/nextLittle.svg" : "/media/icons/next.svg"}
                            alt="הפרק הבא"
                            style={
                                chapter === chapters[chapters.length - 1] ||
                                    !chapters[chapter]
                                    ? { opacity: "0.5", cursor: "default" }
                                    : !props.miniLoading ? { cursor: "pointer", opacity: "1" } : { opacity: "1" }
                            }
                        />
                    </GenericButton>
                )
                :
                null
            )
            }
        </>
    )
}

import React, { useContext, useState, useEffect } from 'react';
import '../style/menuPopup.scss'
import { Link, useLocation } from "react-router-dom";
import { Modal, IconButton } from '@mui/material';
import { LogOut } from './LogOut';
import { Slide } from '@material-ui/core';
import { UserContext } from "../ctx/UserContext";
import { MENU_LINKS, MENU_LINKS_UNAUTH } from '../consts/web-nav-bar.const';
import { InputContext } from '../ctx/InputContext';
import { useHistory } from "react-router-dom";
import Profiles from '../components/Profiles';

interface GenericPopupProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    unauth: boolean;
}

const MenuPopup: React.FC<GenericPopupProps> = (props) => {

    const { name } = useContext(UserContext);
    let location = useLocation().pathname;
    const history = useHistory();

    const userctx = useContext(UserContext);
    const [rankImg, setRankImg] = useState({ src: "", alt: "" });
  
    useEffect(() => {
      if (userctx.profileIndex !== undefined) {
        const { src, alt } = Profiles[userctx.profileIndex]
        setRankImg({ src, alt })
      }
    }, [userctx.profileIndex]);

    // const { popupOpen } = useContext(InputContext);

    const close = () => {
        props.setOpen(false);
    }

    return (

        <Modal
            open={props.open}
            onClose={close}
            disableEscapeKeyDown
        >
            <Slide in={props.open} direction="left">
                <div className={`menu-popup ${props.open ? "closed" : "open"}`}>
                    <div className="menu-inside">
                        {props.unauth ?

                            <div className="menu-top-auth">
                                <div className="header-profile" >
                                    <div className="profile-name" onClick={() => { history.push("/profile") }}>{name}</div>
                                    <IconButton onClick={() => { history.push("/profile") }}>
                                        <img
                                            style={{ borderRadius: "50%", aspectRatio: "1/1", objectFit: "cover" }}
                                            src={rankImg? rankImg.src : "/media/icons/avatar.svg"}
                                            alt={rankImg? rankImg.alt : "profile"}
                                            className="profile-pic"
                                        />
                                    </IconButton>
                                </div>
                                <hr />
                            </div>
                            : ""
                        }

                        <div className={`nav-links ${props.unauth ? 'long' : 'sort'}`}>
                            {!props.unauth ?

                                MENU_LINKS.map((item) => {

                                    return (
                                        <Link
                                            key={item.to}
                                            to={item.to}
                                            // onClick={popupOpen ? (e) => { e.preventDiefault(); } : null}
                                            className="nav-list"
                                        >
                                            <div aria-label={item.content} className={`nav-list-item ${item.currentPage === location ? "currentPage" : ""}`}>{item.content}</div>
                                        </Link>
                                    )
                                })
                                :
                                MENU_LINKS_UNAUTH.map((item) => {

                                    return (
                                        <Link
                                            key={item.to}
                                            to={item.to}
                                            // onClick={popupOpen ? (e) => { e.preventDefault(); } : null}
                                            className="nav-list"
                                        >
                                            <div aria-label={item.content} className={`nav-list-item ${item.currentPage === location ? "currentPage" : ""}`}>{item.content}</div>
                                        </Link>
                                    )
                                })
                            }

                        </div>

                        <div className="menu-bottom">
                            {props.unauth ? <LogOut /> : ""}

                            <hr />
                            <div className="text-bottom first">כל הזכויות שמורות לספריה לעיוורים ולקויי </div>
                            <div className="text-bottom">קריאה ולפרוייקט ספריה בהקשבה</div>
                            <img src="/media/icons/HilmaWhiteLogo.svg" className="bottom-img" />
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}

export default MenuPopup;
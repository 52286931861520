import React from 'react'
import ChapterChangeArrows from "../components/ChapterChangeArrows";
import { isBrowser } from "react-device-detect";
import { GenericButton } from '../../components/GenericButton';
import ChangeTime from "../components/ChangeTime";
import { Play } from "../components/Play";
import Cover from "../components/Cover";
import Move from "../components/Move.jsx";

// This displays the mini player
export default function LittlePlayer(props) {

    const goToBigScreen = (e) => {
        if (!isBrowser) props.bigScreen();
    }

    return (
        <>
            <div
                className="background"
                role={!isBrowser ? 'button' : 'div'}
                aria-label={!isBrowser ? 'הגדלת חלון השמע' : ''}
                onClick={goToBigScreen}
            >
                <div className='emptySpace'></div>
                {isBrowser ?
                    (
                        <div className="playLitlle">
                            <ChangeTime
                                player={props.player}
                                setPlaying={props.setPlaying}
                                setRatingPopup={props.setRatingPopup}
                                setPopupOpen={props.setPopupOpen}
                                move={"forward"} />
                            <ChapterChangeArrows
                                miniLoading={props.miniLoading}
                                move={"next"} />
                            <Play
                                playing={props.playing}
                                setPlaying={props.setPlaying} />
                            <ChapterChangeArrows
                                miniLoading={props.miniLoading}
                                move={"previous"} />
                            <ChangeTime
                                player={props.player}
                                setPlaying={props.setPlaying}
                                setRatingPopup={props.setRatingPopup}
                                setPopupOpen={props.setPopupOpen}
                                move={"rewind"} />
                        </div>
                    ) :
                    (
                        <Play
                            playing={props.playing}
                            setPlaying={props.setPlaying}
                        />
                    )
                }
                <div className='leftSide'>
                    <div className="bookInfoL">
                        <span className={`bookTitleNameL ${props.nameBook.length > 28 ? 'spotifyAnimation' : ''}`}>
                            {props.nameBook}
                        </span>
                        <div className="bookCTL">
                            <p className="bookChapterL"> קטע {props.chapter} </p>
                            <p className="bookTimeL"> {props.leftTime()}</p>
                        </div>
                    </div>
                    {isBrowser ? (
                        <GenericButton
                            className="arrowBL"
                            ariaLabel='הגדלת חלון שמע'
                            style={{ backgroundColor: 'inherit' }}
                            onClick={props.bigScreen} >
                            <img
                                src="/media/icons/arrowupwhite.svg"
                                alt="חץ כלפי מעלה"
                                className="arrowBL--img"
                            />
                        </GenericButton>
                    ) : null}
                    <div className={`book-imgL lightblue `}>
                        <Cover />
                    </div>
                </div>
            </div>
        </>
    )
}
import React, { useContext, useEffect } from "react";
import { isIOS } from 'react-device-detect';
import { InputContext } from "../ctx/InputContext";
import { UserContext } from "../ctx/UserContext";
import DialogContent from "@material-ui/core/DialogContent";
import Profiles from "./Profiles.js";
import Tooltip from "./Tooltip";
import Dialog from "@material-ui/core/Dialog";
import "../style/popupProfile.scss";
import { DetectDeviceContext } from "../ctx/DetectDeviceContext";

/*This popup displays all of the icons that the student is able to choose from.*/
const ProfilesPopup = (props) => {
	const { isDesktop, isMobile } = useContext(DetectDeviceContext);
	const { setPopupOpen } = useContext(InputContext);
	const userctx = useContext(UserContext);

	useEffect(() => {
		if (isIOS && props.profilePopup) setPopupOpen(true);
	}, [isIOS, props.profilePopup]);

	const closePopUp = (e) => {
		e.preventDefault();
		props.setProfilePopup(false);
		setPopupOpen(false);
	};

	const onImgClick = (value, index) => {
		if (props.level >= value.level) {
			userctx.setProfileIndex(index);
			props.setProfilePopup(false);
			setPopupOpen(false);
		} else return;
	};

	return (
		<div>
			<Dialog className={`containTagsPopup ${isDesktop ? "containTagsPopupBrowser" : ""}`}
				open={props.profilePopup} aria-labelledby="reset-modal">
				<div className="tagsPopup">
					<div className="containXinTagsPopup">
						<button className="close-profile-popup" onClick={closePopUp}>
							<img src={"/media/icons/delete2.svg"} alt="כפתור סגירה לדיאלוג" />
						</button>
						<h1 className="profile-popup-title">
							תמונת הפרופיל שלי
						</h1>
					</div>

					<div className="gradientBottom"></div>

					<DialogContent className="p-0">
						<div style={isMobile ? { height: "100%", overflow: "hidden", display: "flex", width: "100vw", alignItems: "center" } : { height: "100%", overflow: "hidden" }}>
							<div className="containTags" style={isMobile? { height: isIOS ? "85vh" : "100vh", margin: "unset", width: "85%" }:{ height: isIOS ? "85vh" : "100vh" }}>
								{Profiles?.map((value, index) => {
					

									return (
										/*If the student has read enough books to use a certain icon, it will display normally.
										  If not, the image is gray and unclickeable, with a tooltip saying how many books the
											student has left to read before they will be able to access that icon.*/
										<div key={index} className={`containOneTag loadPicturesVeryFast-${index}`}>
											<Tooltip style={!isDesktop ? { width: "30vw", left: "80%" } : {}}
												className="profile-img"
												hiddenTooltipForAccessability={value.alt}
												tooltiptext={value.booksRead - props.doneBooks === 1 ?
													"נשארו לכם ספר אחד לקרוא כדי לקבל תמונת פרופיל זאת" :
													props.level < value.level ?
														`נשארו לכם ${value.booksRead - props.doneBooks} ספרים לקרוא כדי לקבל תמונת פרופיל זאת` : ''}

												onClick={() => onImgClick(value, index)}
												text={
													<img className={props.level < value.level ? "" : "containOneTagImg"}
														style={props.level < value.level ? { filter: "grayscale(100%)" } : {}}
														src={value.src} width="100%" alt='' />
												}
											/>
										</div>
									);
								}) || null}
							</div>
						</div>
					</DialogContent>
				</div>
			</Dialog>
		</div>
	);
};

export default ProfilesPopup;

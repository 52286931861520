import React, { useContext } from "react";
import "../style/sign-up-page.scss";
import { DetectDeviceContext } from "../ctx/DetectDeviceContext";
import Header from "../components/Header";
import Footer from "../components/Footer";
import downloadServerFile from "../fucntions/download-server-file";


/*This page renders all of the various sign up pages, both for students and teachers. They are all
  displayed in one big flex, and the margin on the div determines which component the user sees.*/
const SignUpPage = () => {

  const { isMobile } = useContext(DetectDeviceContext);

  const handleDownloadPDF = async () => {
    try {
      return downloadServerFile("file/signup.docx", 'טופס הרשמה  לספריה בהקשבה -180722.docx');
    }
    catch (error) {
      throw error;
    }
  }

  return (
    <div className="signup-page">
      <Header title="הרשמה" />
      <div className="signup-component">
        <div className="signup-titles--container">
          <h4 className="signup-title">רישום תלמיד חדש</h4>
          <div className="signup-subtitle">תהליך ההרשמה יכלול מספר שלבים המפורטים כאן, ומיועד לכל מי שמוכר כבעל מוגבלות קריאה</div>
        </div>

        <div className="signup-texts-pic">
          <div className="signup-texts">
            <div className="first-container">
              <div className="first-container-num">01</div>
              <div className="first-container-text">
                <span>
                  יש למלא את פרטי התלמיד באתר הספריה המרכזית לעיוורים ולבעלי מוגבלויות קריאה, ואת פרטי ההורה תחת סעיף "מלווה לענייני הספריה".
                  היכנסו ללינק
                  <a className="sign-up-link" href="https://www.clfb.org.il/heb/Portal/Registration/#start" target="_blank">כאן</a>
                </span>
              </div>
            </div>
            <div className="first-container">
              <div className="first-container-num signup-blue">02</div>
              <div className="first-container-text">
                <span>
                  לאחר מילוי פרטים באתר הספריה הראשי,
                  יש למלא טופס פרטים אישיים ובו הסכמת ההורה לרישום התלמיד לספריה, וכן אישור מאת גורם מקצועי המעיד על זכאותו של התלמיד להצטרף כמנוי לספריה.
                  הורידו את הטופס
                  <a className="sign-up-link black" onClick={handleDownloadPDF}>כאן</a>
                </span>
              </div>
            </div>
            <div className="first-container">
              <div className="first-container-num signup-yellow">03</div>
              <div className="first-container-text">
                <div>
                  את הקובץ החתום יש לשלוח למייל
                  {" "}<a target="_blank" href="mailto://info@clfb.org.il">info@clfb.org.il</a>{" "}
                  או להעלותו למקום המיועד לכך באתר הספריה (שלב 1 לעיל)
                </div>
              </div>

            </div>
            <div className="first-container">
              <div className="first-container-num signup-green">04</div>
              <div className="first-container-text">
                תוך מספר ימים לאחר השלמת תהליך הרישום הראשוני,
                הספריה תשלח אליכם מייל ובו אישור הרשמתכם לספריה והנחיות לגבי יצירת סיסמת כניסה.
                מיד לאחר מכן – תוכלו להיכנס אל העולם המופלא של הספריה בהקשבה!
              </div>
            </div>

            {isMobile ?
              <>
                <div className="signup-help">
                  <span className="help-text">צריכים עזרה?</span>
                  <span> מוזמנים לפנות אלינו:</span>
                  <div className="help-nums"> 03-775-6800 | 054-866-3452</div>
                  <div className="help-nums">או לצפות בסרטון ההדרכה <a href="https://youtu.be/43QeRYkHmz0" target={'_blank'}>כאן</a></div>
                </div>
              </>
              :
              <>
                <span className="d-flex-column">
                  <div className="signup-help">
                    <span className="help-text">צריכים עזרה? </span>
                    <span>מוזמנים לפנות אלינו: 03-775-6800 | 054-866-3452</span>
                  </div>
                  <span>או לצפות בסרטון ההדרכה <a href="https://youtu.be/43QeRYkHmz0" target={'_blank'}>כאן</a></span>
                </span>
              </>
            }
          </div>
          {isMobile ? "" : <img src="media/images/signupImg.png" className="signup-pic" />}
        </div>
      </div>

      {isMobile ? <img src="media/images/newbackg.png" className="signup-pic" /> : <Footer noPositionFixed />}
    </div >

  );
};

export default SignUpPage;
import { useLogout } from '@hilma/auth';
import { GenericButton } from './GenericButton';
import '../style/log-out.scss';




export const LogOut = (props) => {

  const logout = useLogout();
  return (
    <>
      {!props.menu ?
        <button className='log-out-container'>
          <img className='log-out-icon' src="/media/icons/log-out.svg" alt="אייקון התנתקות" />
          <GenericButton text={'התנתקות'}
            onClick={logout}
            className={'log-out-button'}
          />
        </button>
        :

        <button className='menu-log-out-container'>
          <img className='menu-log-out-icon' src="/media/icons/log-out.svg" alt="אייקון התנתקות" />
          <GenericButton
            text={'התנתקות'}
            onClick={logout}
            className={'menu-log-out-button'}
          />
        </button>
      }
    </>
  );
};

import React from "react";
import Profiles from "../../components/Profiles.js";
import "./../style/dashboard.scss";

/*These are the functions that belong to the teacher's dashboard.*/
  
/*This function displays all the books in the teacher's dashboard. 
  They appear in a slider that the teacher is able to toggle.*/ 
export const bookArray = (DashboardStore, BookStore) => {
    let cArr = [];
    let color = ["yellow", "lightblue", "red", "blue"];
    for (let i = 0; i < DashboardStore.books.length; i++) {
        const book = DashboardStore.books[i];
        let bookName = book.book_name;

        if (bookName.length > 25) {
            bookName = `${bookName.slice(0, 20)}...`;
        }
        const style = {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            borderRadius: "5px",
            fontSize: "1vw",
            lineHeight: "12.5vw",
            height: "12.5vw",
            width: "12.5vw",
        };
        const element = (
            <div style={style} className={color[i % 4]}>
                {bookName}
            </div>
        );
        const cover = (
            <img src={findCover(book.book_name, BookStore)} style={style} alt={book.book_name} />
        );
        const booki = findCover(book.book_name, BookStore) ? cover : element;
        cArr.push(booki);
    }
    for (let i = cArr.length; i <= 5; i++) {
        const style = {
            borderRadius: "5px",
            border: "2px solid #cccccc",
            height: "12.5vw",
            width: "12.5vw",
            backgroundColor: "#eeedec"
        };
        cArr.push(<div style={style}></div>)
    }
    return cArr;
};

/*This function searches for covers for all the books that appear in the dashboard.*/ 
const findCover = (bookName, BookStore) => {
    const index = BookStore.booksCovers.findIndex((value) => {
        return bookName === value.book_name;
    });
    if (index !== -1) {
        return BookStore.booksCovers[index].cover_url;
    } else return false;
};

/*This function displays all the students in the teacher's dashboard. 
  They appear in a slider that the teacher is able to toggle.*/ 
export const studentArray = (DashboardStore, BookStore) => {
    let cArr = [];
    const style = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        border: "3px solid black",
        borderRadius: "5px",
    };
    for (let i = 0; i < DashboardStore.students.length; i++) {
        const student = DashboardStore.students[i];
        const image = (
            <img
                src={Profiles[student.profileImg].src}
                alt={Profiles[student.profileImg].alt}
                style={{ fontSize: "10%", width: "12.5vw", padding: "10%" }}
            />
        );

        const nameStyle = {
            paddingTop: "3vh",
            whiteSpace: "wrap",
            fontSize: "3vh",
            lineHeight: "3vh"
        }

        const splitName = student.name.split(" ")
        const studentName = student.name.length > 14 ? `${splitName[0]} ${splitName[splitName.length - 1]}` : student.name
        const name = (
            <div style={{ height: "10vh" }}>
                <div style={{ height: "2vh" }}></div>
                <span style={nameStyle}>{studentName}</span>
            </div>
        );
        const element = (
            <div style={style}>
                {image}
                {name}
            </div>
        );
        cArr.push(element);
    };
    for (let i = cArr.length; i <= 5; i++) {
        cArr.push(<div style={style}><img src="media/images/noChild.svg" style={{ width: "12.5vw", padding: "10%" }} alt="nochild" /><span style={{ height: "10vh" }}></span></div>)
    }
    return cArr;
};
import React, { useContext, useMemo } from "react";
import { useObserver } from "mobx-react-lite";
import { UserContext } from "../ctx/UserContext";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { useHistory } from "react-router-dom";
import "../style/book.scss";
import { useBooks } from "../stores/Books.store";
import usePlayer from "../ctx/PlayerContext";

/* This component renders the book's image and title. It will display the cover when available, 
and a colorful square when not. In the Library and the Home pages, - where no additional information about the
book is required, this component will also display the author. In the other pages, the Book component renders
any other information about the book. */

export default function BookSquare(props) {

  const { isDesktop } = useContext(DetectDeviceContext);
  const { setIfPlayer, setBookId, setNameBook, setNameAuthor, setBookCode, saveLastElement } = usePlayer();
  const BookStore = useBooks();
  const userctx = useContext(UserContext);
  const history = useHistory();
  let color;
  if (props.book.id % 4 === 1) color = 'lightblue-book';
  else if (props.book.id % 4 === 2) color = 'yellow-book';
  else if (props.book.id % 4 === 3) color = 'pink-book';
  else color = 'blue-book';


  const location = useMemo(() => window.location.pathname, [window.location.pathname]);

  const { inHome, inLibrary, inBookshelf, inCatalog, inBookPageOrDashboard } = useMemo(() => ({
    inHome: location === "/" && userctx.profileType === "student",
    inLibrary: location === "/",
    inBookshelf: location === "/mybooks",
    inCatalog: location === "/catalog" || "/elementary-school" || "/middle-school" || "/high-school",
    inBookPageOrDashboard: location.includes("/catalog/") || (location === "/" && userctx.profileType === "teacher")
  }), [location, userctx.profileType]);

  /*This function finds the cover by taking in the book's name and getting the corresponding index from the book store,
    and then calling a function in the book store to find the cover by the index*/

  const findCover = (bookName) => {

    // console.log(location)
    if (location === "/elementary-school" || location === "/middle-school" || location === "/high-school") {
      return props.book.cover;
    }

    const index = BookStore.booksCovers.findIndex((value) => {
      return bookName === value.book_name;
    });


    if (index !== -1) {
      return BookStore.booksCovers[index].cover_url;
    } else return false;
  };

  const onSquareClick = (e) => {
    if ((!isDesktop && inBookshelf) || (isDesktop && props.instantPlay)) {
      e.preventDefault();
      setIfPlayer(true);
      saveLastElement(e.target);
      setBookId(props.book.id);
      setNameBook(props.book.book_name);
      setBookCode(props.book.code);
      setNameAuthor(props.book.author_name);
      props.getBookStudentId && props.getBookStudentId();
      history.push("/mybooks"); // when the user clicks on the book square, he is redirected to the bookshelf page
      return;
    }
    if (props.noClick) return;

    history.push(`/catalog/${props.book.id}`, { ...props.book });
  };


  return useObserver(() => (
    <React.Fragment>
      <div className={`book-square ${props.className}  ${inHome || inLibrary ? (isDesktop ? "web-version" : "") : ""} ${!isDesktop && color}`}>
        <div className={inLibrary ? "category-container" : inHome ? "container no-padding" : inBookshelf || inCatalog || inBookPageOrDashboard ? "" : "container"}>
          <button
            disabled={props.noClick}
            aria-label={props.book.book_name}
            onClick={onSquareClick}
            className="underline-link book-square--button"
            index={props.book.id} >
            {findCover(props.book.book_name) ? (
              <div className={`${inHome ? "home-img" : inBookshelf ? "bsimg img" : inLibrary ? "library-img " : inCatalog ? "catalog-img " : "bp-img"}`}>
                <img src={findCover(props.book.book_name)} alt={props.book.book_name} />
              </div>
            ) : (
              <div className={`${color ?? color} ${inHome ? "home-img" : inBookshelf ? "bsimg img" : inLibrary ? "library-img img" : inCatalog ? "catalog-img img" : "bp-img img"}`}>
                {props.book.book_name}
              </div>
            )}
          </button>


          {(inLibrary || inHome) ? (
            <div className={inLibrary ? "categoryInfo" : "info"}>
              <h4 className={inLibrary ? "categoryTitle" : "title"}>
                {props.book.book_name}
              </h4>

              {isDesktop && <h5 className={inLibrary ? "categoryAuthor" : "author"}>
                {props.book.author_name}
              </h5>}
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  ));
}

import { IconButton } from "@material-ui/core";
import { useState } from 'react';
import { Collapse } from '@mui/material';

const Question = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={`q-a-text ${props.first ? "q-a-first" : ""}`} onClick={() => {
            if (open === false) {
                setOpen(true)
            } else {
                setOpen(false)
            }
        }}>
            <div className="q-a-question">
                <div className={`q-title ${open ? "q-bold" : ""}`} >{props.question}</div>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className="q-answer">{props.answer}</div>
                </Collapse>
            </div>
            <IconButton onClick={() => {
                if (open === false) {
                    setOpen(true)
                } else {
                    setOpen(false)
                }
            }}>
                <img className={`q-a-img ${open ? "turn-up" : ""}`} src="/media/icons/down.svg" alt="arrow for answer" />
            </IconButton>
        </div>
    )
}

export default Question;
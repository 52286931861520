import { action, observable, makeObservable, runInAction, computed } from "mobx";
import Axios from "axios";
import ErrorFunction from "../errorFunction";
import { createMobXContext } from "@hilma/tools";
class BookStore {
  books = [];
  buttonsInfo = [];
  counter = 0;
  genreCounter = 0;
  search = "";
  genre = '';
  hasmore = true;
  myBooks = [];
  bookstudentEmpty = false;
  recommendedBooks = [];
  elementaryBooks = [];
  middleBooks = [];
  highBooks = [];
  newBooks = [];
  lastBooks = [];
  booksCovers = [];
  booksInfo = [];
  ifError = false;
  error = "";
  doneBooks = [];
  progressBooks = [];
  notStartedBooks = [];
  delayTime = null;
  loading = false;

  constructor() {

    makeObservable(this, {
      books: observable,
      counter: observable,
      search: observable,
      genreCounter: observable,
      genre: observable,
      loading: observable,
      hasmore: observable,
      myBooks: observable,
      isBookshelfEmpty: computed,
      recommendedBooks: observable,
      elementaryBooks: observable,
      middleBooks: observable,
      highBooks: observable,
      newBooks: observable,
      lastBooks: observable,
      booksCovers: observable,
      buttonsInfo: observable,
      progressBooks: observable,
      doneBooks: observable,
      bookstudentEmpty: observable,
      notStartedBooks: observable,
      delayTime: observable,
      setBooks: action,
      setSearch: action,
      setGenre: action,
      getBookByID: action,
      setBooksCovers: action,
      fetchLastBooks: action,
      callSetBooks: action,
      callSetGenre: action,
      fetchAgeBooks: action,
      clearSearch: action
    });
  }
  /*Takes in a string value. If it is different from the current search, it sets the value as the new search.*/
  setSearch = (value) => {
    console.log('setSearch');
    if (value !== this.search) {
      this.hasmore = true;
      this.counter = 0;
      this.books = [];
      this.genre = "";
      this.search = value;
      this.loading = true;
    }
  };
  clearSearch = (resetFetch = false) => {
    if (resetFetch && this.search !== "") {
      console.log('fetching again successfully')
      this.fetchMyBooks();
    }
    this.search = "";
    this.genre = "";
    sessionStorage.removeItem("searchInput"); // clear the search Value
    sessionStorage.removeItem("searchType"); // clear the search type
  };

  setGenre = (value) => {
    if (value !== this.genre) {
      this.hasmore = true;
      this.genreCounter = 0;
      this.books = [];
      this.search = "";
      this.genre = value;
      this.loading = true;
      console.log(true);
    }
  };


  /*This function returns the url for the plan an example audio and checks if it is in the bookshelf*/
  setButtons = async (bookID, url) => {
    try {
      if (this.buttonsInfo.length) {
        let info = this.buttonsInfo.find(el => el.id === bookID);
        if (info)
          return info;
      }
      let isIn = await Axios.get(`/api/book-student/isBookInData/${bookID}`);
      // console.log('isIn: ', isIn);
      let urlInfo = "";
      if (!url) {
        urlInfo = await Axios.get(`/api/chapters/getExample/${bookID}`);
      }

      if (url || urlInfo.data.length) {
        let temp = {
          id: bookID,
          is_in: isIn.data,
          audio_url: url || urlInfo.data[0].url
        };

        this.buttonsInfo.push(temp);
        return temp;
      } else {
        return {
          id: bookID,
          is_in: isIn.data,
          audio_url: null
        };
      }

    } catch (error) {
      const err = ErrorFunction(error);
      this.error = err;
      this.ifError = true;
    }
  };

  //this func is for genre
  callSetGenre = async () => {
    let result;
    try {
      if (this.genre) {
        result = await Axios.get(
          `/api/books/searchGenre/?genre=${(this.genre)}&counter=${this.genreCounter}`
        );
      }
      else {
        result = await Axios.get(`/api/books/searchGenre/%20/${this.genreCounter}`);
      }
      runInAction(async () => {
        if (result.data.length < 10) {
          this.hasmore = false;
        }
        let dataRes = result.data;
        let tempBooks = [];

        for (let i = 0; i < dataRes.length; i++) {
          let bookInfo = await dataRes[i];
          let inRes;
          if (bookInfo.chapter.length)
            inRes = await this.setButtons(bookInfo.id, bookInfo.chapter[1].url);
          else {
            inRes = await this.setButtons(bookInfo.id);
          }

          let ratings = bookInfo.book_student;
          let sumRatings = 0;
          let numRatings = 0;

          for (let i = 0; i < ratings.length; i++) {
            if (ratings[i].general_rating > 0) {
              sumRatings += ratings[i].general_rating;
              numRatings++;
            }
          }

          const bookName = this.setBookName(bookInfo.book_name);
          const author = this.setAuthorName(bookInfo.author);
          let temp = {
            id: bookInfo.id,
            book_name: bookName,
            audio_length: bookInfo.audio_length,
            summary: bookInfo.summary.trim(),
            author_name: author,
            avg_rating: sumRatings / numRatings,
            audio_url: inRes?.audio_url,
            code: bookInfo.code,
            is_in: inRes?.is_in,
          };

          tempBooks.push(temp);
          this.setBooksCovers(bookName, temp.author_name);
        }

        this.books = this.books.concat(tempBooks);
        this.loading = false;
        this.genreCounter += 10;
      });
    } catch (error) {
      if (!Axios.isCancel(error)) {
        const err = ErrorFunction(error);
        this.error = err;
        this.ifError = true;
      }
    }
  };

  /*This is the search function. It returns all of the books that match the search input.*/
  callSetBooks = async () => {

    let result;
    try {
      if (this.search) {
        result = await Axios.get(
          `/api/books/searchBooks/${encodeURIComponent(this.search)}/${this.counter}`
        );
      }
      else {
        result = await Axios.get(`/api/books/searchBooks/%20/${this.counter}`);
      }

      runInAction(async () => {
        if (result.data.length < 10) {
          this.hasmore = false;
        }
        let dataRes = result.data;
        let tempBooks = [];

        for (let i = 0; i < dataRes.length; i++) {
          let bookInfo = await dataRes[i];
          let inRes;
          if (bookInfo.chapter.length)
            inRes = await this.setButtons(bookInfo.id, bookInfo.chapter[1].url);
          else {
            inRes = await this.setButtons(bookInfo.id);
          }

          let ratings = bookInfo.book_student;
          let sumRatings = 0;
          let numRatings = 0;

          for (let i = 0; i < ratings.length; i++) {
            if (ratings[i].general_rating > 0) {
              sumRatings += ratings[i].general_rating;
              numRatings++;
            }
          }

          const bookName = this.setBookName(bookInfo.book_name);
          const author = this.setAuthorName(bookInfo.author);
          let temp = {
            id: bookInfo.id,
            book_name: bookName,
            audio_length: bookInfo.audio_length,
            summary: bookInfo.summary.trim(),
            author_name: author,
            avg_rating: sumRatings / numRatings,
            // audio_url: inRes?.audio_url,
            code: bookInfo.code,
            is_in: inRes?.is_in,
          };

          tempBooks.push(temp);
          this.setBooksCovers(bookName, temp.author_name);
        }

        this.books = this.books.concat(tempBooks);
        this.counter += 10;
        this.loading = false;
      });
    } catch (error) {
      if (!Axios.isCancel(error)) {
        const err = ErrorFunction(error);
        this.error = err;
        this.ifError = true;
      }
    }
  };

  setBooks = async () => {
    if (this.delayTime) clearTimeout(this.delayTime);
    this.delayTime = setTimeout(async () => {
      if (this.search) await this.callSetBooks();
      else if (this.genre) await this.callSetGenre();
    }, 300);
  };


  //This function gets recommended books by age
  fetchAgeBooks = async () => {
    try {
      let result = await Axios.get(`/api/age-category-book/get-recommended-books`);
      this.elementaryBooks = result.data.filter((book) => book.age_category.category_name === 'elementary');
      this.middleBooks = result.data.filter((book) => book.age_category.category_name === 'middle');
      this.highBooks = result.data.filter((book) => book.age_category.category_name === 'high');
      return result;
    } catch (err) {
      console.error(err, 'err in book store');
    }

  };

  /*This function gets the user's books from the database*/
  fetchMyBooks = async (setStudentBooks, setClass) => {
    this.bookshelfEmpty = false;
    try {

      this.loading = true;
      let result = await Axios.get(`/api/user/getUserBooks`);

      // const trying = result.data[0].book_student[0].book.book_name
      const trying = (result.data[0].book_student).filter((item) => item.book.book_name.includes(this.search));

      if (!{ ...this.elementaryBooks }[0] || !{ ...this.middleBooks }[0] || !{ ...this.highBooks }[0]) {
        await this.fetchAgeBooks();
      }
      if (setStudentBooks) {
        setStudentBooks(result.data[0].book_student);
      }


      let userBooksInfo = trying;
      // setClass(result.data[0].Class);     לבדיקת איילת
      if (!userBooksInfo.length) {
        this.bookshelfEmpty = true;

        return;
      } else {
        let userBooks = [];
        let finishedBooks = [];
        let inprogressBooks = [];
        let notStartedYetBooks = [];

        for (let i = 0; i < userBooksInfo.length; i++) {

          if (userBooksInfo[i].book !== null) {
            let bookInfo = userBooksInfo[i];
            // let chapterResult = await Axios.get(
            //   `/api/user/getUrlsChapters/${bookInfo.book.code}`
            // );
            const author = this.setAuthorName(bookInfo.book.author);
            const bookName = this.setBookName(bookInfo.book.book_name);
            let temp = {
              id: bookInfo.book.id,
              book_name: bookName,
              audio_length: bookInfo.book.audio_length,
              summary: bookInfo.book.summary.trim(),
              code: bookInfo.book.code,
              current_time: bookInfo.current_time,
              chapter: bookInfo.chapter,
              author_name: author,
              book_finished: bookInfo.book_finished,
              time_chapter: bookInfo.time_chapter,
              cover_image: bookInfo.book.cover || null,
              chapters_number: bookInfo.book.chapters_number
            };
            // if (chapterResult.data.Files) {
            //   temp.chapters_number = chapterResult.data.Files.length;
            // }
            userBooks.push(temp);
            this.setBooksCovers(bookName, temp.author_name);

            if (temp.book_finished) {
              finishedBooks.push(temp);
            }
            else {
              if (temp.time_chapter > 0 || temp.chapter > 1) {
                inprogressBooks.push(temp);
              }
              else {
                notStartedYetBooks.push(temp);
              }
            }
          }
        }
        // sorting books arrays by having cover book
        userBooks.sort((a, b) => a.cover_image ? -1 : 1);

        this.doneBooks = finishedBooks;
        this.myBooks = userBooks;
        this.progressBooks = inprogressBooks;
        this.notStartedBooks = notStartedYetBooks;
      }
    } catch (error) {
      const err = ErrorFunction(error);
      this.error = err;
      this.ifError = true;
    }
    finally {
      this.loading = false;
    }
  };

  get isBookshelfEmpty() {
    return !this.myBooks.length
  }

  /*This function gets the books with the best reviews*/
  fetchRecommendedBooks = async () => {
    try {
      const result = await Axios.get(`/api/books/recommendedBooks`);
      let recommendedResult = result.data;

      if (recommendedResult.length === 0) {
        this.bookstudentEmpty = true;
        return;
      }

      let rBooks = [];
      for (let i = 0; i < recommendedResult.length; i++) {
        let info = recommendedResult[i];
        if (info.author !== null) {
          const author = this.setAuthorName(info.author);
          const bookName = this.setBookName(info.book_name);
          let temp = {
            id: info.id,
            book_name: bookName,
            author_name: author,
          };
          this.setBooksCovers(bookName, temp.author_name);
          rBooks.push(temp);
        }
      }
      this.recommendedBooks = rBooks;
    } catch (error) {

      const err = ErrorFunction(error);
      this.error = err;
      this.ifError = true;
    }
  };

  /*This function gets the books with the most recent publishing date*/
  fetchNewBooks = async () => {
    try {
      const result = await Axios.get(`/api/books/getNewBooks`);
      let newResult = result.data;

      let nBooks = [];
      for (let i = 0; i < newResult.length; i++) {
        let info = newResult[i];
        const author = this.setAuthorName(info.author);
        const bookName = this.setBookName(info.book_name);
        let temp = {
          id: info.id,
          book_name: bookName,
          author_name: author,
          cover_image: info.cover || null,
        };
        nBooks.push(temp);
        this.setBooksCovers(bookName, temp.author_name);
      }
      this.newBooks = nBooks;

    } catch (error) {

      const err = ErrorFunction(error);
      this.error = err;
      this.ifError = true;
    }
  };

  /*This function finds the 3 books that the user most recently listened to*/
  fetchLastBooks = async () => {
    try {
      const result = await Axios.get('/api/book-student/getLastBooks');
      let userBooksInfo = result.data;
      if (!userBooksInfo.length) {
      } else {
        let userBooks = [];
        for (let i = 0; i < userBooksInfo.length; i++) {
          let bookInfo = userBooksInfo[i];
          const bookName = this.setBookName(bookInfo.book.book_name);
          const author = this.setAuthorName(bookInfo.book.author);
          let temp = {
            id: bookInfo.book.id,
            code: bookInfo.book.code,
            book_name: bookName,
            audio_length: bookInfo.book.audio_length,
            summary: bookInfo.book.summary.trim(),
            current_time: bookInfo.current_time,
            chapter: bookInfo.chapter,
            chapters_number: bookInfo.book.chapter.length,
            author_name: author,
            book_finished: bookInfo.book_finished,
            last_heard: bookInfo.last_heard
          };
          userBooks.push(temp);
        }
        this.lastBooks = userBooks;
      }
    } catch (error) {

      const err = ErrorFunction(error);
      this.error = err;
      this.ifError = true;
    }
  };

  /*This function updates the last 3 books that the user listened to*/
  updateLastBook = async (book, type) => {
    const copyLastBooks = JSON.parse(JSON.stringify(this.lastBooks));
    if (type === "new") {
      copyLastBooks.push(book);
    } else if (type === "update") {
      const i = copyLastBooks.findIndex(bo => book.id === bo.id);
      copyLastBooks.splice(i, 1);
      copyLastBooks.splice(0, 0, book);
    } else return;
    this.lastBooks = copyLastBooks.slice(0, 3);
  };

  /*This book sets the covers of the books that appear on the screen*/
  setBooksCovers = async (bookName, authorName) => {
    let title = bookName.split(' - ', 2)[0];
    let h = [];
    title.split(' ')?.forEach(ob => {
      if (!(Number(ob) > 0)) {
        h.push(ob);
      }
    });
    title = h.join(" ");
    let index = this.booksCovers.findIndex((value) => {
      return bookName === value.book_name;
    });
    if (index === -1) {
      try {
        const result = await Axios.get(`/api/books/getCover/${bookName}`);
        const cover = result.data.cover;
        this.booksCovers.push({ book_name: bookName, cover_url: cover });
      } catch (error) {
        console.error('error: ', error);
      }
      let bookId;
      // if (bookName.split(' - ', 2).length !== 1) {
      //   await fetch(
      //     `https://api.allorigins.win/get?url=
      //   ${encodeURIComponent(`http://primo.nli.org.il/PrimoWebServices/xservice/search/brief?institution=NNL&loc=local,scope:(NNL)&query=any,contains,${authorName}&indx=45&bulkSize=50&query=facet_pfilter,exact,books&query=facet_rtype,exact,books&query=facet_lang,exact,heb&json=true`)}`
      //   )
      //     .then(response => {
      //       return response.json()
      //     })
      //     .then(data => {
      //       return JSON.parse(data.contents).SEGMENTS.JAGROOT.RESULT.DOCSET.DOC;
      //     })
      //     .then(books => {
      //       let url;
      //       let book_name;
      //       books.forEach(book => {
      //         if (typeof book.PrimoNMBib.record.browse.title === "string") {
      //           book_name = book.PrimoNMBib.record.browse.title.split('$$D')[1].split('$$E')[0].slice(0, title.length);
      //         } else {
      //           book_name = book.PrimoNMBib.record.browse.title[0].split('$$D')[1].split('$$E')[0].slice(0, title.length);
      //         }
      //         if (book_name === title) {
      //           if (book.PrimoNMBib.record.links.lln05) url = book.PrimoNMBib.record.links.lln05
      //         }
      //       })
      //       if (url) {
      //         if (typeof url !== "string") {
      //           url = url[0]
      //         }
      //       }
      //       const cover = url.slice(3).split('$$D', 1)[0]
      //       this.booksCovers.push({ book_name: bookName, cover_url: cover });
      //     })
      //     .catch((error) => {
      //       return;
      //     });
      // }

      // await fetch(
      //   `https://api.allorigins.win/get?url=
      //   ${encodeURIComponent(`http://primo.nli.org.il/PrimoWebServices/xservice/search/brief?institution=NNL&loc=local,scope:(NNL)&query=title,contains,${title}&indx=45&bulkSize=50&query=facet_pfilter,exact,books&query=facet_rtype,exact,books&query=facet_lang,exact,heb&json=true`)}`
      // )
      //   .then(response => {
      //     return response.json()
      //   })
      //   .then(data => {
      //     console.log('data: ', data);
      //     console.log('JSON.parse(data.contents): ', JSON.parse(data.contents));
      //     return JSON.parse(data.contents).SEGMENTS.JAGROOT.RESULT.DOCSET.DOC;
      //   })
      //   .then(book => {
      //     console.log('book: ', book);
      //     let url;
      //     let book_name;
      //     if (book.length) {
      //       url = book.find(allbook => {
      //         if (typeof allbook.PrimoNMBib.record.browse.title === "string")
      //           book_name = allbook.PrimoNMBib.record.browse.title.split('$$D')[1].split('$$E')[0].slice(0, title.length)
      //         else
      //           book_name = allbook.PrimoNMBib.record.browse.title[0].split('$$D')[1].split('$$E')[0].slice(0, title.length)
      //         if (book_name === title)
      //           return allbook.PrimoNMBib.record.links.lln05
      //       }).PrimoNMBib.record.links.lln05;
      //     } else {
      //       if (typeof book.PrimoNMBib.record.browse.title === "string")
      //         book_name = book.PrimoNMBib.record.browse.title.split('$$D')[1].split('$$E')[0].slice(0, title.length)
      //       else
      //         book_name = book.PrimoNMBib.record.browse.title[0].split('$$D')[1].split('$$E')[0].slice(0, title.length)
      //       if (book_name === title)
      //         url = book.PrimoNMBib.record.links.lln05
      //     }
      //     if (url) {
      //       if (typeof url !== "string") {
      //         url = url[0]
      //       }
      //     } else {
      //       if (typeof book.PrimoNMBib.record.browse.author === "string") {
      //         book_name = book.PrimoNMBib.record.browse.author.split('$$E')[0].split('$$D')[1].split('-')[0]
      //       } else {
      //         book_name = book.PrimoNMBib.record.browse.author[0].split('$$E')[0].split('$$D')[1].split('-')[0]
      //       }
      //       let author = "";
      //       book_name.split(" ").forEach(e => {
      //         if (e.search("1") === -1 && e.search("2") === -1 && e !== "heb")
      //           author += e += " "
      //       })
      //       let author_name = "";
      //       author.split(',').forEach(el => {
      //         if (el !== " ") {
      //           author_name += el;
      //         }
      //       })
      //       if (authorName === author_name) {
      //         url = book.PrimoNMBib.record.links.lln05
      //       }
      //     }
      //     const cover = url.slice(3).split('$$D', 1)[0]
      //     this.booksCovers.push({ book_name: bookName, cover_url: cover });
      //   })
      //   .catch((error) => {
      //     return;
      //   });

      // await fetch(
      //   `https://api.allorigins.win/get?url=
      //     ${encodeURIComponent(`https://api.nli.org.il/openlibrary/search?api_key=pG2dzuj4mXf0LvJTJNR0WcoVIe720hxYWeDTmWvz&query=title,contains,${title},AND;language,exact,heb&output_format=json`)}`)
      //   .then(response => {
      //     return response.json()
      //   })
      //   .then(data => {
      //     return JSON.parse(data.contents)[0]['http://purl.org/dc/elements/1.1/recordid'][0]['@value'];
      //   })
      //   .then(book => {
      //     bookId = book
      //   })
      //   .catch((error) => {
      //     return;
      //   });
      // await fetch(`https://api.allorigins.win/get?url=
      //   ${encodeURIComponent(`https://iiif.nli.org.il/IIIFv21/${bookId}/manifest`)}`
      // )
      //   .then(response => {
      //     return response.json()
      //   })
      //   .then(data => {
      //     return JSON.parse(data.contents).sequences[0].canvases[0].images[0].resource['@id'];
      //   })
      //   .then(cover => {
      //     this.booksCovers.push({ book_name: bookName, cover_url: cover });
      //   })
      //   .catch((error) => {
      //     return;
      //   });
    } return;
  };

  /*This function gets all of the information about the book*/
  getBookByID = async (num) => {
    try {
      let index = num;
      let found = false;

      if (this.booksInfo.length) {
        let res = this.booksInfo.findIndex((value) => {
          return index === value.bookInfo.id;
        });

        if (res !== -1) {
          found = true;
          return this.booksInfo[res];
        }
      }

      if (!found) {
        const result = await Axios.get(`/api/books/getAuthorBook/${index}`);
        const bookAuthorInfo = result.data[0];
        const buttonsInfo = await this.setButtons(bookAuthorInfo.id);

        let ratings = result.data[0].book_student;
        let sumRatings = 0;
        let numRatings = 0;
        let avgRating = 0;
        let allRatings = [];

        for (let i = 0; i < ratings.length; i++) {
          if (ratings[i].general_rating > 0) {
            sumRatings += ratings[i].general_rating;
            numRatings++;
            allRatings.push({
              name: ratings[i].user.name,
              reviewHeadline: ratings[i].feedback_title,
              review: ratings[i].feedback,
              general: ratings[i].general_rating,
              quality: ratings[i].quality,
              story: ratings[i].story,
            });
          }
        }

        if (sumRatings !== 0 && numRatings !== 0) {
          avgRating = sumRatings / numRatings;
        }

        const bookName = this.setBookName(bookAuthorInfo.book_name);
        var bookInfo = {
          id: bookAuthorInfo.id,
          code: bookAuthorInfo.code,
          book_name: bookName,
          audio_length: bookAuthorInfo.audio_length,
          summary: bookAuthorInfo.summary.trim(),
          genre: "",
          translator: bookAuthorInfo.translator,
          publication_house: bookAuthorInfo.publication_house,
          publication_date: bookAuthorInfo.publication_date,
          avgRating: avgRating,
          ratings: allRatings,
          audio_url: buttonsInfo.audio_url,
          is_in: buttonsInfo.is_in,
          chapters_number: bookAuthorInfo.chapters_number
        };

        let genreArray = bookAuthorInfo.genre.split(/@@/g);
        let genreArray2 = [];
        for (let i = 0; i < genreArray.length; i++) {
          const genre = genreArray[i];
          if (isNaN(Number(genre))) {
            genreArray2.push(genre);
          };
        };
        let genreArray1 = genreArray2.join(', ').trim();

        if (genreArray1[genreArray1.length - 1] === ',') {
          bookInfo.genre = genreArray1.substring(0, genreArray1.length - 1).trimEnd() + ".";
        } else {
          bookInfo.genre = genreArray1.trimEnd() + ".";
        }
        const authorInfo = this.setAuthorName(bookAuthorInfo.author);

        this.setBooksCovers(bookAuthorInfo.book_name, authorInfo);
        return { bookInfo, authorInfo };
      }
    } catch (error) {

      const err = ErrorFunction(error);
      this.error = err;
      this.ifError = true;
    }
  };

  setAuthorName = authorName => {
    let author = "";
    const authorArr = authorName.split(/@@/g);
    for (let i = 0; i < authorArr.length; i++) {
      const element = authorArr[i];
      if (element !== "") {
        author += ` ${element},`;
      }
    }
    return author.trim().substring(0, author.length - 2).trimEnd();
  };

  setBookName = name => {
    if (name[name.length - 1] === "." || name[name.length - 1] === "/") {
      return name.substring(0, name.length - 1);
    } else {
      return name;
    }
  };
}

const BooksStore = new BookStore();
export const [BooksContext, BooksProvider, useBooks] = createMobXContext(BooksStore);


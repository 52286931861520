import React from 'react'
import '../style/button.scss'

export const GenericButton = ({ text, onClick, style, className, isLikeUrl, ariaLabel, children, disabled }) => {
    return (
        <button
            aria-label={!disabled ? (ariaLabel || text) : ''}
            className={`generic-button ${isLikeUrl ? 'signup-link' : 'no-link'} ${disabled ? 'disabled' : ''} ${className}`}
            style={style}
            disabled={disabled || false}
            onClick={onClick}>
            {text || children}
        </button>
    )
}

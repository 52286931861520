import React, { useContext, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";
import CircleLoading from "../components/Loader.jsx";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import Book from "../pages/Book.jsx";
import "../style/catalog.scss";
import { useBooks } from "../stores/Books.store";
import { Skeleton } from "@mui/material";
import usePlayer from "../ctx/PlayerContext.js";

/*This page displays the result of the user's search in the Library*/
export default function Catalog() {

  const [audio, setAudio] = useState(null);
  const { isDesktop } = useContext(DetectDeviceContext);
  const [skeletonWidth, setSkeletonWidth] = useState();
  const [skeletonMarginRight, setSkeletonMarginRight] = useState();
  const [skeletonCube, setSkeletonCube] = useState();

  const playerctx = usePlayer();
  const BookStore = useBooks();

  

  useEffect(() => {
    return () => {
      if (BookStore.ifError) {
        BookStore.error = ""
        BookStore.ifError = false
      }
    }
  }, [])

  //for skeleton
  useEffect(() => {
    if (isDesktop) {
      setSkeletonWidth('25vw');
      setSkeletonMarginRight('5.5vw')
      setSkeletonCube('17vw')
    } else {
      setSkeletonWidth('45vw');
      setSkeletonMarginRight('1vh');
      setSkeletonCube('43.5vw')
    }
  }, [isDesktop])

  /*This function makes sure that only one audio is playing at a time. When a user clicks a new audio, the previous audio stops.*/
  function changeAudio(newAudio) {
    if (audio === newAudio) return
    audio && audio.pause();
    setAudio(newAudio);
  }

  /*This function makes sure that if there is a really long word in the  
    search that it doesn't spill out of the borders of the search page.*/
  const DividesTheWord = () => {
    // if (BookStore.genre !== "") { console.log('in if return');return BookStore.genre;}
    // const [value,index] = BookStore.genre ? [BookStore.genre,'genre'] : [BookStore.search,'search'];
    if (BookStore.genre !== "") { return BookStore.genre }
    const indexSpace = BookStore.search.indexOf(' ');
    if (BookStore.search.length > 10 && indexSpace === -1) {
      const searchSlice = BookStore.search.slice(0, 10)
      return (`${searchSlice} ...`)
    } else {
      return (BookStore.search)
    }
  }

  return useObserver(() => (
    <React.Fragment>
      <div className={isDesktop ? "web-version" : (playerctx.littlePlayer ? 'player-phone' : "")} >
        {/* Infinite Scroll is a React library that allows the page to display a limited number of new results at 
            one time. When the user reaches the end of the search results, the library will load more. */}
        <div className="infiniteScroll">

          <InfiniteScroll
            key={1}
            className="init"
            children={BookStore.books}
            pageStart={0}
            loadMore={() => { BookStore.setBooks() }}
            hasMore={BookStore.hasmore}
            loader={(BookStore.counter === 0 || (BookStore.books.length !== 0 && BookStore.hasmore)) &&
              (BookStore.ifError ?
                (
                  <p style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
                    {BookStore.error}
                  </p>
                )
                : (
                  <div style={{ width: '100vw' }}>

                    <div style={{ display: 'flex', width: '100vw', marginTop: '2vh' }}>
                      <Skeleton sx={{ borderRadius: '5px', marginLeft: '3vw', marginRight: skeletonMarginRight, marginBottom: '5vh' }} variant="rectangular" width={skeletonCube} height={skeletonCube} animation='wave' />
                      <div style={{ display: 'flex', marginRight: '3vw', marginTop: '1.5vh', justifyContent: 'space-between', width: '66vw' }}>
                        <div>
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '2vh' }} variant="rectangular" width={skeletonWidth} height={'3.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.4vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.4vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.4vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '2vh' }} variant="rectangular" width={skeletonWidth} height={'1.4vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={'15vw'} height={'1.4vh'} animation='wave' />
                        </div>
                        {isDesktop &&
                          <div style={{ marginBottom: '1vh' }}>
                            <Skeleton sx={{ borderRadius: '20px', marginBottom: '2vh' }} variant="rectangular" width={'17vw'} height={'5vh'} animation='wave' />
                            <Skeleton sx={{ borderRadius: '20px' }} variant="rectangular" width={'17vw'} height={'5vh'} animation='wave' />
                          </div>}
                      </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Skeleton sx={{ borderRadius: '5px', marginLeft: '3vw', marginRight: skeletonMarginRight, marginBottom: '5vh' }} variant="rectangular" width={skeletonCube} height={skeletonCube} animation='wave' />
                      <div style={{ display: 'flex', marginTop: '1.5vh', marginRight: '3vw', justifyContent: 'space-between', width: '66vw' }}>
                        <div>
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '2vh' }} variant="rectangular" width={skeletonWidth} height={'3.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.4vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.4vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.4vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '2vh' }} variant="rectangular" width={skeletonWidth} height={'1.4vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={'15vw'} height={'1.4vh'} animation='wave' />
                        </div>
                        {isDesktop &&
                          <div style={{ marginBottom: '1vh' }}>
                            <Skeleton sx={{ borderRadius: '20px', marginBottom: '2vh' }} variant="rectangular" width={'17vw'} height={'5vh'} animation='wave' />
                            <Skeleton sx={{ borderRadius: '20px' }} variant="rectangular" width={'17vw'} height={'5vh'} animation='wave' />
                          </div>}
                      </div>
                    </div>
                  </div>
                )
              )
            }
          >

            {BookStore.books && BookStore.books.length === 0 && (!BookStore.loading) ?
              (
                <div className="no-results-container">
                  <div>
                    <img className="sad-emoji" src={"/media/images/noResultsWorm.svg"} alt={"אין תוצאות אייקון של תולעת ספרים"} />
                  </div>
                  {/* Credits: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
                  {BookStore.search === "" ?
                    <div className="no-results">לא נמצאו תוצאות</div> :
                    <>
                      <div className="no-results">{`לא הצלחנו למצוא את "${DividesTheWord()}"`}</div>
                      <div className="no-results">בטוחים שהקלדתם נכון?</div>
                    </>
                  }
                </div>
              )
              : (
                BookStore.books.map((title, index) => {
                  return <Book book={title} key={index} index={index} setAudio={changeAudio} />;
                })
              )

            }

            {!BookStore.hasmore && BookStore.books.length > 0 ?
              <div className="no-more-books">
                נראה שעברתם על כל הספרים, מוזמנים לחפש ספרים נוספים <a href="/">בספריה</a>
              </div>
              : ""
            }

          </InfiniteScroll>
        </div>
      </div >
    </React.Fragment >
  ));
}

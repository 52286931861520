import React, { useContext, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { useGenAlert } from "../ctx/GenAlertContext";
import { CATEGORIES } from "../consts/header.const";
import ErrorFunction from "../errorFunction";
import Axios from 'axios';
import { useRef } from "react";
import Header from "./Header";
import { useBooks } from "../stores/Books.store";
import "../style/secondHeader.scss";
import { useHistory } from 'react-router-dom';

/*This component appears at the top of the Library pages (Categories and Catalog). It has a search
input which acts on the BookStore. When in the Categories page, there is also an option to search by genre.*/
const SecondHeader = (props) => {
  const { isDesktop } = useContext(DetectDeviceContext);
  const [searchingNum, setSearchingNum] = useState(0);
  const [width, setWidth] = useState("18vh");
  const [title, setTitle] = useState('ספריה');
  const searchInput = useRef(null);
  const history = useHistory();
  const [tryingClass, setTryingClass] = useState('');
  const isInitialMount = useRef(true);

  const BookStore = useBooks();
  const genAlertCtx = useGenAlert();

  useEffect(() => {

    sessionStorage.removeItem("searchInput"); // clear the unput
    if (BookStore.search) {
      BookStore.clearSearch(true);
    } 
    //! ------------------------------
    const searchValue = sessionStorage.getItem("searchValue");
    const searchType = sessionStorage.getItem("searchType");

    if (searchType === "genre" && searchValue) {
      BookStore.setGenre(searchValue);
      setTryingClass(searchValue)
    } else if (searchType === "input" && searchValue) {
      BookStore.setSearch(searchValue);
      getBooksNum(searchValue);
    }
    if(window.location.pathname === "/"){
      setTryingClass('')
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setTryingClass('');
    }
  }, [window.location.pathname]);

  /*This function gets the user input value and returns the number of search results there are for that input.*/
  async function getBooksNum(searchInput) {
    try {
      if (BookStore.search && window.location.pathname === '/mybooks') {

        let result = await Axios.get(`/api/books/countSearchingResultsMyBooks/${encodeURIComponent(searchInput)}`);
        let countResult = result.data;
        setSearchingNum(countResult);

        countResult >= 100 ? (isDesktop ? setWidth("23vh") : setWidth("25vh")) :
          countResult >= 10 ? setWidth("20vh") : setWidth("18vh");
      } else if (BookStore.search) {
        let result = await Axios.get(`/api/books/countSearchingResults/${encodeURIComponent(searchInput)}`);
        let countResult = result.data;
        setSearchingNum(countResult);

        countResult >= 100 ? (isDesktop ? setWidth("23vh") : setWidth("25vh")) :
          countResult >= 10 ? setWidth("20vh") : setWidth("18vh");
      }

    } catch (error) {
      genAlertCtx.openGenAlert({ text: ErrorFunction(error) });

    }
  }

  /*This function takes in the input value and performs the search. If the value is null, it returns to the 
      Categories page. If the input has a value, it returns the search results as well as the number of search results.*/
  function searching(value) {
    if (window.location.pathname === '/mybooks') {
      BookStore.fetchMyBooks();
    }
    value = value.target.value;
    setTryingClass('');
    if (value === " " || value === ".") {
      value = "";
    }
    if (value === "" && window.location.pathname !== '/mybooks') {
      history.push("/");
      return;
    }
    sessionStorage.setItem("searchValue", value); // save in session the value of the input search and the type of search
    sessionStorage.setItem("searchType", "input");
    BookStore.setSearch(value);
    getBooksNum(value);
    window.location.pathname === "/" && history.push("/catalog"); //happens when a valid search value has been entered
  }

  const genreSearching = async (value, item) => {

    const genreValue = value.target.value;
    
    setTryingClass(item);
    sessionStorage.setItem("searchValue", genreValue); // save in session the value of the genre search and the type of search
    sessionStorage.setItem("searchType", "genre");
    BookStore.clearSearch();
    BookStore.setGenre(genreValue);
    window.location.pathname === "/" && history.push("/catalog"); //happens when a valid search value has been entered   // const result = await axios.get(`/api/books/searchBooks/${encodeURIComponent(genreValue)}/${counter}`)
  };

  const onClearSearchInput = () => {
    setTitle('ספריה');
    if (window.location.pathname === '/mybooks') {
    
      console.log('search now')

      history.push("/mybooks");
    } else {
      history.push("/");
    }
    searchInput.current?.focus();
  };


  return useObserver(() => (
    <React.Fragment>
      <div className="second-header">
        {!isDesktop ?
          <>
            <Header title={props.title} />

            <div className="mobile-search" style={{ marginTop: "2vh", marginBottom: "2vh" }}>
              <img style={{ zIndex: 0 }} className="button" alt="" id="searchButton" src="/media/icons/magnifyingGlass.svg" />
              <label htmlFor="searchInput" />
              <input ref={searchInput} type="text" id="searchInput" placeholder="הקלד שם ספר, סופר או ז'אנר" value={BookStore.search} onChange={searching} onPaste={searching} autoComplete="off" />

              {BookStore.search ? (
                <div className="results-number" style={{ width: width }} aria-live="assertive" >{searchingNum} תוצאות</div>
              ) : null}

              <button style={{ display: BookStore.search ? "initial" : "none" }} type="submit" className="button" form="form1" value="Submit" onClick={onClearSearchInput}>
                <img id="clearButton" src="/media/icons/delete.svg" alt="כפתור ניקוי חיפוש " />
              </button>
            </div>
          </>
          :
          <>
            <div className="header">

              <h1 className="title">
                {props.title}
              </h1>

              <div className="search">
                <img style={{ zIndex: 0 }} className="button" alt="" id="searchButton" src="/media/icons/magnifyingGlass.svg" />
                <label htmlFor="searchInput" />
                <input ref={searchInput} type="text" id="searchInput" placeholder="הקלד שם ספר, סופר או ז'אנר" value={BookStore.search} onChange={searching} onPaste={searching} autoComplete="off" />

                {BookStore.search ? (
                  <div className="results-number" style={{ width: width }} aria-live="assertive" >{searchingNum} תוצאות</div>
                ) : null}

                <button style={{ display: BookStore.search ? "initial" : "none" }} type="submit" className="button" form="form1" value="Submit" onClick={onClearSearchInput}>
                  <img id="clearButton" src="/media/icons/delete.svg" alt="כפתור ניקוי חיפוש " />
                </button>
              </div>
            </div>
          </>
        }

        {(window.location.pathname === "/" || window.location.pathname === '/catalog' || window.location.pathname === 'mybooks') ? (
          <div className="second-header-categories">
            {CATEGORIES.map((item, index) => {
              return (
                <button
                  aria-label={item}
                  className={`second-header-category ${item === tryingClass ? 'pressed' : ''}`}
                  value={item}
                  key={index + 1}
                  index={index}
                  onClick={(value) => { genreSearching(value, item); }}
                >
                  {item}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  ));
};

export default SecondHeader;

import React, { useContext } from 'react';
import usePlayer from "../../ctx/PlayerContext";
import PlayerChapter from "./PlayerChapter.jsx";
import PlayerSpeed from "./PlayerSpeed.jsx";
import '../style/player-speed.scss'
import { useRef } from 'react';
import { FocusTrap } from '../../components/FocusTrap';
import { DetectDeviceContext } from '../../ctx/DetectDeviceContext';

/*This shows the menus and switches between them.*/
export default function PlayerMenus() {
    const dialogRef = useRef(null);
    const { isDesktop } = useContext(DetectDeviceContext);

    // context
    const { speed, setSpeed, chapter, setChapter, showSpeeds, setShowSpeeds, 
        showChapters, setShowChapters, chapters } = usePlayer()

    
    // call player speed componant 
    const timelist = () => {
        if (showSpeeds) {
            return (
                <PlayerSpeed
                    setSpeed={setSpeed}
                    speed={speed}
                    setShowSpeeds={setShowSpeeds}
                    dialogRef={dialogRef} />
            );
        };
    };

    // call player cahpter componant 
    const chapterlist = () => {
        if (showChapters) {
            return (
                <PlayerChapter
                    setChapter={setChapter}
                    chapter={chapter}
                    setShowChapters={setShowChapters}
                    chapters={chapters}
                    dialogRef={dialogRef} />
            );
        };
    };

    return (
        <div role="dialog" className={`${isDesktop ? "browserBig" : ""}`}>
            <FocusTrap trapRef={dialogRef} reFocus={true} >
                {timelist()}
                {chapterlist()}
            </FocusTrap>
        </div>
    );
};

import React, { useState, useEffect, useContext/* , useRef */ } from "react";
import { ContactContext } from "../ctx/ContactContext";
import ContactPopup from "./ButtonsPopup";
import { LoadingBalls } from './LoadingBalls'
import { INPUTS, LARGE_SCREEN_LINKS, LARGE_SCREEN_SIZE, SMALL_SCREEN_LINKS } from "../consts/web-contac-us.const";
import { GenericButton } from "./GenericButton";
import "../style/contact-us.scss";

/*This is the contact us page for web. There is a separate contact us component for browser.*/
const ContactUs = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const contactctx = useContext(ContactContext);
  // const inputs = useRef([]);

  useEffect(() => {
    // inputs.current?.[contactctx.errorField - 1]?.focus();
  }, [contactctx.errorField])

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const setField = (value) => {
    if (value === " " || value === ".") {
      value = ""
    }
    return value
  };

  const onChange = (e, funcName) => {
    contactctx[funcName]?.(setField(e.target.value));
  }

  return (
    <React.Fragment>
      <div className="web-contact contactUsPage" id="contact-us">
        <div className="contactForm">
          {/* <h3>
            <span>נהנה מספריה בהקשבה?</span>
            <span>נתקלת בבעיה וצריך עזרה?</span>
            <span>נשמח לשמוע ולסייע</span>
          </h3>
          <div className="webInputs">
            <div className="small-inputs">
              {INPUTS.map((data, i) => <React.Fragment key={data.label}>
                <label htmlFor={`${data.id} contact-us`} className="input-titles">{data.label}</label>
                <input
                  type={data.type}
                  // ref={el => inputs.current[i] = el} 
                  id={`${data.id} contact-us`}
                  className={`input-flex inputs ${contactctx.errorField === data.errorField ? "fieldError" : "noError"}`}
                  name={`${data.id} contact-us`}
                  placeholder={data.placeHolder}
                  value={contactctx[data.valueName]}
                  onChange={(e) => onChange(e, data.funcName)}
                  autoComplete={data.autoComplete} />
              </React.Fragment>)}
            </div>
            <div>
              <label htmlFor="feedbackInput" className="input-titles">הפנייה שלך</label>
              <textarea
                type="text"
                id="feedbackInput"
                className={`input-flex inputs ${contactctx.errorField === 3 ? "fieldError" : "noError"}`}
                name="search"
                placeholder=""
                value={contactctx.feedback}
                onChange={(e) => onChange(e, "setFeedback")}
                autoComplete="off" />
            </div>
          </div>
          <GenericButton
            className="feedbackButton"
            onClick={() => contactctx.submitFeedback()}
            text={contactctx.loading ? <LoadingBalls /> : "שליחה"} />
          <div>
            {contactctx.submitted ?
              <ContactPopup
                popup={contactctx.submitted}
                setPopup={contactctx.setSubmitted}
                text="הטופס נשלח בהצלחה!"
                imgsrc="/media/images/contactSent.svg" />
              : null}
          </div>
          <div className="errors" aria-live="assertive" >{contactctx.error}</div>
          credits: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}

          {width > LARGE_SCREEN_SIZE ?
            <footer className="bottom-logos">
              {LARGE_SCREEN_LINKS.map((item) => !item.isP ?
                <a {...item.a}>
                  <img {...item.img} />
                </a> : <p className={item.className} key={item.key}>
                  {item.content1} {item.content2}
                </p>
              )}
            </footer>
            :
            <footer className="smaller-cf-logos">
              <div className="bottom-logos">
                {SMALL_SCREEN_LINKS.map((item) =>
                  <a {...item.a}>
                    <img {...item.img} />
                  </a>
                )}
                <p className="contact-footer">
                  כל הזכויות שמורות לספריה לעוורים ולקויי ראייה ולפרוייקט ספריה בהקשבה
                </p>
              </div>
            </footer>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactUs;
import React, { Suspense, useContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider, HomeRoute, PublicOnlyRoute, UserProvider } from "@hilma/auth";
import { provide } from "@hilma/tools";
import Open from "./pages/Open.jsx";
import SignUpPage from "./pages/SignUpPage";
import ForgotPass from "./pages/ForgotPass";
import StudentRoute from "./clientStudent/components/StudentRoute";
import TeacherRoute from "./clientTeacher/components/TeacherRoute";
import About from "./pages/About";
import ScrollToTop from "./components/ScrollToTop";
import { UserContext } from "./ctx/UserContext";
import "./App.scss";
import "./style/style.scss";
import { BooksProvider } from "./stores/Books.store.js";
import { DashboardsProvider } from "./stores/Dashboard.store.js";
import { DetectDeviceProvider } from "./ctx/DetectDeviceContext.js";
import { GenAlertProvider } from "./ctx/GenAlertContext.js";
import { ContactProvider } from "./ctx/ContactContext.js";
import { PlayerProvider } from "./ctx/PlayerContext.js";
import { InputProvider } from "./ctx/InputContext.js";
import ContactUs from "./pages/ContactUs.jsx";
import QAndA from "./pages/QAndA.jsx";
import usePlayer from "./ctx/PlayerContext";
import Player from "./clientStudent/pages/Player"; // to do 
import PopupRating from "./clientStudent/components/PopupRating.jsx";
import { DetectDeviceContext } from "./ctx/DetectDeviceContext.js";
import { InputContext } from "./ctx/InputContext.js";
import PrivacyPage from "./clientStudent/pages/PrivacyPage.tsx";
import TermsOfUse from "./clientStudent/pages/TermsOfUse.tsx";
import CircleLoading from "./components/Loader.jsx";
const SuperadminRoute = React.lazy(() => import("./clientSuperadmin/components/SuperadminRoute"))


function App() {

  const Userctx = useContext(UserContext);
  const playerctx = usePlayer()
  const [ratingPopup, setRatingPopup] = useState(false);
  const { isDesktop } = useContext(DetectDeviceContext);
  const { keyboardOpen } = useContext(InputContext);
  const [rotation, setRotation] = useState(!window.matchMedia("(orientation: portrait)").matches);

  window.onorientationchange = (e) => {
    setRotation(e.target.matchMedia("(orientation: portrait)").matches);
  };

  return (
    <div className="App" style={Userctx.login ? { position: "absolute", top: "0", right: "0", height: "100vh", width: "100vw", overflow: "hidden", } : null}>
      <ScrollToTop />
      <Suspense fallback={<CircleLoading />}>
        <Switch>
          <Route exact path="/signup" component={SignUpPage} />
          <PublicOnlyRoute exact path="/login" component={Open} componentName="Login" />
          <PublicOnlyRoute exact path="/login-superadmin" component={Open} componentName="Login" />
          <PublicOnlyRoute path="/api/forgotPassword" component={ForgotPass} />
          <Route path='/about'><About /></Route>
          <Route exact path="/q-and-a"><QAndA /></Route>
          <Route path="/contact-us"><ContactUs /></Route>
          <Route exact path="/privacy" > <PrivacyPage /></Route>
          <Route exact path="/termsofuse" ><TermsOfUse /></Route>

          <HomeRoute path="/" redirectPath="/login" components={{ StudentRoute, TeacherRoute, SuperadminRoute }} />
        </Switch>
      </Suspense>

      {ratingPopup ? <PopupRating setRatingPopup={setRatingPopup} /> : null}


      <div style={keyboardOpen || rotation && !isDesktop ? { visibility: "hidden", height: "0vh" } : {}} >
        {playerctx.ifPlayer ? <Player setRatingPopup={setRatingPopup} /> : null}
      </div>
    </div>
  );
}

export default provide([AuthProvider, { logoutOnUnauthorized: true, accessTokenCookie: "klokloklo" }],
  Router,
  DetectDeviceProvider,
  BooksProvider,
  DashboardsProvider,
  GenAlertProvider,
  ContactProvider,
  PlayerProvider,
  UserProvider,
  InputProvider
)(App);

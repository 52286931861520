import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChangeTeacherPopup from '../clientTeacher/components/ChangeTeacherPopup';
import { ValidateFields } from "@hilma/tools";
import { InputContext } from "../ctx/InputContext";
import { UserContext } from '../ctx/UserContext';
import ErrorFunction from "../errorFunction";
import { TYPE } from '../consts/setting.const';
import Axios from 'axios';
import Popup from '../components/Popup';
import { GenericButton } from '../components/GenericButton';
import '../style/setting.scss';
import { rankings } from '../consts/books-read.consr';
import Profiles from '../components/Profiles';
import TagsPopup from "../components/ProfilesPopup";
import { isBrowser } from 'react-device-detect';
import ChooseClass from "../clientStudent/components/ChooseClass";
import { useMediaQuery } from '@material-ui/core';
import { useBooks } from '../stores/Books.store';
import { useDashboard } from '../stores/Dashboard.store';
import Header from "../components/Header";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { useGenAlert } from "../ctx/GenAlertContext";
import { useHistory } from "react-router-dom";

/*This is the settings page. It allows the user to change their name, password, 
  and if the user is a teacher, allows them to add or delete the classes they teach.*/
const Setting = () => {
    const { doneBooks } = useBooks()
    const countBookDone = useMemo(() => doneBooks.length, [doneBooks])

    const { isDesktop } = useContext(DetectDeviceContext);
    const mobile = useMediaQuery('(max-width: 768px)');
    const genAlertCtx = useGenAlert();
    const history = useHistory();
    const [profilePopup, setProfilePopup] = useState(false);
    const [rankImg, setRankImg] = useState({ src: "", alt: "" });

    const userctx = useContext(UserContext);

    const { level, rank } = useMemo(() => {
        let rank = "";
        let level = null;
        if (countBookDone < 5) {
            level = 0;
            rank = rankings[0];
        } else if (countBookDone < 8) {
            level = 1;
            rank = rankings[1];
        } else if (countBookDone < 11) {
            level = 2;
            rank = rankings[2];
        } else if (countBookDone < 14) {
            level = 3;
            rank = rankings[3];
        } else if (countBookDone < 17) {
            level = 4;
            rank = rankings[4];
        } else if (countBookDone < 20) {
            level = 5;
            rank = rankings[5];
        } else if (countBookDone < 23) {
            level = 6;
            rank = rankings[6];
        } else {
            level = 7;
            rank = rankings[7];
        }

        return { level, rank }
    }, [doneBooks])

    useEffect(() => {
        if (userctx.profileIndex !== undefined) {
            const { src, alt } = Profiles[userctx.profileIndex]
            setRankImg({ src, alt })
        }
    }, [userctx.profileIndex]);

    const openProfilePopup = () => setProfilePopup(true)

    const { profileType, setName, classId, name } = useContext(UserContext);
    const { setPopupOpen, newVh, setNewVh } = useContext(InputContext);

    const [newName, setNewName] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [oldPType, setOldPType] = useState(TYPE[0]);
    const [newPType, setNewPType] = useState(TYPE[0]);
    const [nameError, setNameError] = useState("");
    const [passwordsError, setPasswordsError] = useState({});
    const [error, setError] = useState();
    const [result, setResult] = useState("");
    const [popup, setPopup] = useState([false]);
    const [classes, setClasses] = useState([]);
    const [changeClassPopup, setChangeClassPopup] = useState(false);
    const [classValue, setClassValue] = useState(classId || "לא שויכת לכיתה");

    const DashboardStore = useDashboard()

    useEffect(() => {
        if (classId) {
            setClassValue(`${classId.grade}${classId.number}`);
        } else {
            setClassValue("לא שויכת לכיתה");
        }
    }, [classId]);

    useEffect(() => {
        if (name) {
            setNewName(name);
        }
    }, [name]);

    /*This function allows the user to toggle whether the password is visible or hidden*/
    const showPassword = (variable, func) => {
        let i = TYPE.indexOf(variable)
        func(TYPE[(i + 1) % 2])
    };

    /*This function makes sure that the user's new name is valid. If it is, it updates the database with the name.*/
    const changeName = async () => {
        const NameError = ValidateFields.validateFullNameInput(newName);
        if (!NameError) {
            setNameError("");
            try {
                const result = await Axios.post(`/api/user/updateName/${newName}`);
                if (result.data) {
                    setResult("הפרטים עודכנו בהצלחה");
                    setName(newName);
                    setPopupOpen(true);
                }
            } catch (error) {
                // setError(ErrorFunction(error));
                genAlertCtx.openGenAlert({ text: ErrorFunction(error) });

            }
        } else {
            setNameError(NameError);
        }
    };

    /*This function makes sure that the user's password is valid. It also checks that the user knows their old  password, 
      which adds security the user's account. If both inputs are valid, it updates the database with the password. */
    const changePassword = async () => {
        const oldPasswordError = ValidateFields.validatePasswordInput(oldPassword);
        const newPasswordError = ValidateFields.validatePasswordInput(newPassword);
        if (!oldPasswordError && !newPasswordError) {
            setPasswordsError({});
            try {
                const result = await Axios.post(`/api/user/changePasswordSetting`, {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                });
                if (result.data) {
                    setResult("הסיסמא עודכנה בהצלחה");
                    setOldPassword("");
                    setNewPassword("");
                    setPopupOpen(true);
                } else {
                    setPasswordsError({ oldError: "הסיסמא שהוכנסה לא נכונה" })
                }
            } catch (error) {
                setError(ErrorFunction(error))
            }
        } else {
            setPasswordsError({ oldError: oldPasswordError, newError: newPasswordError });
        }
    };

    /*This function updates the teacher's classes. It sends both the classes that the teacher added,
      as well as the ones that the teacher deleted to the database. In addition, it updates the
      display of the teacher's classes in the client side.*/
    const changeClasses = async (deleteClasses, newClasses, changeTeacher) => {
        try {
            const result = await Axios.post('/api/class/updateClasses', {
                deleteClass: deleteClasses,
                newClass: newClasses
            });
            if (changeTeacher.length > 0) {
                await updateClassTeacher(changeTeacher);
            }
            const length = DashboardStore.students.length;
            changeTeacher?.forEach(changeClass => {
                let i = 0;
                while (i < DashboardStore.students.length) {
                    const student = DashboardStore.students[i];
                    if (student.class === changeClass.id) {
                        DashboardStore.students.splice(i, 1)
                    } else {
                        i++;
                    };
                };
                let j = 0;
                while (j < DashboardStore.grades.length) {
                    const grade = DashboardStore.grades[j];
                    if (grade.id === changeClass.id) {
                        DashboardStore.grades.splice(j, 1)
                    } else {
                        j++;
                    };
                };
            });
            deleteClasses?.forEach(deleteC => {
                let j = 0;
                while (j < DashboardStore.grades.length) {
                    const grade = DashboardStore.grades[j];
                    if (grade.id === deleteC) {
                        DashboardStore.grades.splice(j, 1)
                    } else {
                        j++;
                    };
                };
            });
            if (length !== DashboardStore.students.length) {
                DashboardStore.setBooks();
            };
            if (result.data) {
                setResult("הכיתות עודכנו בהצלחה");
                setPopupOpen(true);
                result.data[0].NewResult?.forEach(newResult => {
                    const school = DashboardStore.grades.find(frade => frade.school_id === newResult.school)
                    DashboardStore.grades.push({
                        id: newResult.id,
                        grade: newResult.grade,
                        number: newResult.number,
                        school_name: school.school_name,
                        school_id: newResult.school,
                    });
                })
            };
        } catch (error) {
            setError(ErrorFunction(error));
        }
    };

    /*If the teacher deletes a class that has students in it, this function updates the teacher to a new teacher*/
    const updateClassTeacher = async (classes) => {
        try {
            const trueArray = [];
            const classArray = [];
            for (let i = 0; i < classes.length; i++) {
                const singleClass = classes[i];
                const result = await Axios.get(`/api/user/TeachersInSchool/${singleClass.school_id}`);
                if (result.data) {
                    trueArray.push(true);
                    classArray.push({
                        id: singleClass.id,
                        grade: singleClass.grade,
                        number: singleClass.number,
                        teachers: result.data,
                        school: result.data[0].nameSchool,
                    });
                }
            }
            setPopup(trueArray);
            setClasses(classArray);
            setPopupOpen(true);
        } catch (error) {
            setError(ErrorFunction(error))
        }
    };

    const closeChangeTeacherPopUp = (classIndex) => {
        const popupCopy = [...popup];
        popupCopy[classIndex] = false;
        setPopup(popupCopy);
        setPopupOpen(false);
    }

    return (
        <div className="setting">
            {/* <div className="errorServer">{error}</div> */}
            {result ?
                <Popup
                    text={result}
                    delete="/media/icons/delete2.svg"
                    newVh={newVh}
                    setNewVh={setNewVh}
                    close={() => {
                        setResult("");
                        setPopupOpen(false);
                    }}
                    imgsrc="/media/images/thumbsUp.svg" />
                //Credits: <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                : null
            }
            {classes.map((classNum, classIndex) => {
                return popup[classIndex] ?
                    <ChangeTeacherPopup
                        key={classIndex}
                        class={classNum}
                        close={() => closeChangeTeacherPopUp(classIndex)} />
                    : null
            })}

            {profileType === 'teacher' ?
                //need to change teacher side
                <div className="passwordChange">
                    <span className="miniTitle">שינוי סיסמא</span>
                    <div className="password">
                        <div className="pass-input">
                            <label htmlFor="oldPassword">סיסמא קודמת</label>
                            <div className={`verify-pass ${passwordsError.oldError ? "error-border" : "no-error-border"}`}>
                                <input
                                    type={oldPType}
                                    value={oldPassword}
                                    onChange={e => setOldPassword(e.target.value)}
                                    className="input"
                                    name="oldPassword"
                                    id="oldPassword"
                                    data-lpignore="true" />
                                <FontAwesomeIcon
                                    onClick={() => showPassword(oldPType, setOldPType)}
                                    icon={["fas", "eye"]}
                                    color={oldPType === TYPE[0] ? '#787a81' : '#ee5c5c'} />
                            </div>
                        </div>
                        {passwordsError.oldError ? <div className="error" aria-live="assertive" >{passwordsError.oldError}</div> : null}
                    </div>
                    <div className="password">
                        <div className="pass-input">
                            <label htmlFor="newPassword">סיסמא חדשה</label>
                            <div className={`verify-pass ${passwordsError.newError ? "error-border" : "no-error-border"}`}>
                                <input
                                    type={newPType}
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    className="input"
                                    name="newPassword"
                                    id="newPassword"
                                    data-lpignore="true" />
                                <FontAwesomeIcon
                                    onClick={() => showPassword(newPType, setNewPType)}
                                    icon={["fas", "eye"]}
                                    color={newPType === TYPE[0] ? '#787a81' : '#ee5c5c'} />
                            </div>
                        </div>
                        {passwordsError.newError ? <div className="error" aria-live="assertive" >{passwordsError.newError}</div> : null}
                    </div>
                    <button className="settingButton" onClick={changePassword}>החלף סיסמא</button>
                </div>
                :
                <div className="student-profile">
                    {!isDesktop ?
                        <Header title="עריכת פרופיל" />
                        :
                        // <>
                        //     <button className="back-arrow-img-mobile" onClick={() => { history.push('/'); }}>
                        //         <img className='back-button' src='/media/icons/backArrow.svg' />
                        //     </button>
                            <h1 className="title">עריכת פרופיל</h1>
                        // </>
                    }
                    <div className="student-profile-split">
                        <div className="student-profile-right-side">
                            <span className="mini-title">פרטי משתמש:</span>
                            <div className="right-side-inputs">
                                <div className="name-change">
                                    <label htmlFor="nameChange">שם התלמיד:</label>
                                    <span>
                                        <div className="new-name">
                                            <input
                                                type="text"
                                                value={newName}
                                                onChange={e => setNewName(e.target.value)}
                                                className={`input ${nameError ? "error-border" : "no-error-border"}`}
                                                name="nameChange"
                                                id="nameChange"
                                                data-lpignore="true"
                                                maxLength={30} />
                                            <div className="setting-error" aria-live="assertive" >{nameError || ""}</div>
                                        </div>
                                    </span>
                                </div>

                                {/* <div className="class-change">
                                    <span>
                                        <div className="new-class">
                                            <label htmlFor="classChange">כיתה:</label>
                                            <input

                                                type="text"
                                                value={classValue}
                                                className={`input no-error-border`}
                                                name="classChange"
                                                id="classChange"
                                                readOnly={true}
                                                data-lpignore="true"
                                                maxLength={30}
                                                onClick={() => {
                                                    setChangeClassPopup(true)
                                                }}
                                            />
                                        </div>
                                    </span>
                                    {changeClassPopup ? <ChooseClass popup={setChangeClassPopup} setClassValue={setClassValue} /> : null}
                                </div> */}
                                <GenericButton className="settingButton" onClick={changeName} text='שמירת פרטים' />
                            </div>
                        </div>

                        <div className="student-profile-left-side">
                            <span className="mini-title">{mobile ? "פרטי משתמש:" : "תמונת פרופיל:"}</span>
                            <div className="profileLevel left-side-inputs">
                                {mobile ?
                                    <h3 className="rank-profile">
                                        דירוג:
                                        <h3 className="the-rank">{rank} ספרים</h3>
                                    </h3>
                                    :
                                    <h3 className="rank-profile">דירוג: {rank} ספרים</h3>
                                }
                                <button aria-label="עריכה של תמונת פרופיל, מעבר לדיאלוג" className="edit-profile-img" onClick={openProfilePopup}>
                                    <div className="profileLevelImg">

                                        <img src={rankImg.src} alt={rankImg.alt} className={`ranking ${isBrowser ? "rankingBrowser" : ""}`} />


                                    </div>
                                    <img src={"/media/icons/pencil.svg"} className={`pencil ${isBrowser ? "pencilBrowser" : ""}`} alt="אייקון של עריכה" />
                                </button>
                            </div>
                            <TagsPopup level={level} profilePopup={profilePopup} setProfilePopup={setProfilePopup} doneBooks={countBookDone} />
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Setting
import React, { useState } from "react";
import { ValidateFields } from "@hilma/tools";
import Axios from "axios";
import ErrorFunction from "../errorFunction";
import {useGenAlert} from "./GenAlertContext"

export const ContactContext = React.createContext();


export const ContactProvider = ({ children }) => {
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState("");
    const [error, setError] = useState("");
    const [errorField, setErrorField] = useState(0);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const genAlertCtx = useGenAlert()    

    const [popup, setPopup] = useState(false)

    /*Validates that all of the contact fields are correct. Uses Hilma's validation for Hebrew name and 
      valid email, and separately checks that the feedback is existent but not too long. Also does not
      allows semicolons because they could cause issues in the SQL.*/
    function validation() {

        if(ValidateFields.validateFullNameInput(name)){
            setError(ValidateFields.validateFullNameInput(name))
            setErrorField(1);
            return false;
        }
        if (ValidateFields.validateEmailInput(email, true)) {
            setError(ValidateFields.validateEmailInput(email, true))
            setErrorField(2);
            return false;
        }
        if (feedback.length === 0) {
            setError("נא למלא פנייה");
            setErrorField(3);
            return false;
        }
        if (feedback.length > 400) {
            setError("הפנייה ארוכה מדי. ניתן מקסימום של 255 תווים");
            setErrorField(3);
            return false;
        }
        if (feedback.includes(";")) {
            setError("נא להכניס פנייה תקינה. השימוש ב';' אסור");
            setErrorField(3);
            return false;
        }
        return true;
    }

    /*If there are no errors, it sends the feedback to the audiobooks email, clears all the fields, and gives confirmation.*/
    const submitFeedback = async () => {
        if (validation()) {
            setLoading(true);
            setError("");
            setErrorField(0);
            try {
                let result = await Axios.post("/api/contactUs", {
                    name: name, email: email, feedback: feedback
                });
                let res = JSON.parse(result.config.data);
                if (res.name.length > 0 && res.email.length > 0 && res.feedback.length > 0){
                    setSubmitted(true);
                    setName("");
                    setEmail("");
                    setFeedback("");
                }
                setLoading(false);
            }
            catch(err){
                setLoading(false);
                genAlertCtx.openGenAlert({text:ErrorFunction(err)})
            }
        }
    }


    const ctxValue = {
        name,
        setName,
        email,
        setEmail,
        feedback,
        setFeedback,
        error,
        setError,
        errorField,
        setErrorField,
        loading,
        setLoading,
        submitted,
        setSubmitted,
        submitFeedback,
        popup, 
        setPopup,
    };

    return (
        <ContactContext.Provider value={ctxValue}>{children}</ContactContext.Provider>
    );
};

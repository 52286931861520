import { NO_BOOK } from '../../consts/home.const';
import { useObserver } from 'mobx-react-lite';
import BookSquare from '../../components/BookSquare';
import { useContext, useEffect, useState } from 'react';
import { DetectDeviceContext } from '../../ctx/DetectDeviceContext';
import { useHistory } from 'react-router-dom';
import '../../style/recommended-age-books.scss';
import { useBooks } from '../../stores/Books.store';
import "../../style/book-slider.scss"
import { SubjectTitle } from '../../components/SubjectTitle';


interface BookSliderProps {
  bookArray: {
    book_name: string;
    author_name: string;
  }[]
  title: string;
  path: string;
}
const BookSlider: React.FC<BookSliderProps> = (props) => {

  const { isDesktop } = useContext(DetectDeviceContext)
  const BookStore = useBooks();
  const history = useHistory();
  const [newBooks, setNewBooks] = useState([{}]);

  const bookPerView = (isDesktop) ? 5 : 4

  const findCover = (bookName: string) => {
    const index = BookStore.booksCovers.findIndex((value) => {
      return bookName === value.book_name;
    });
    if (index !== -1) {
      return BookStore.booksCovers[index].cover_url;
    } else return false;
  };

  useEffect(() => {
    const books = [...props.bookArray];

    const bookTitlesThatExist: string[] = [];

    const filterBooks: Array<{ [key: string]: any }> = [];
    books.map((element) => {
      if (!bookTitlesThatExist.includes(element?.book_name)) {
        bookTitlesThatExist.push(element?.book_name)
        filterBooks.push(element);
      }
    });

    setNewBooks(filterBooks);
  }, [props.bookArray])
/// indexxx


  return useObserver(() => (
    <div>
      <div className="container-slide">
        <div className="container-title">
          <SubjectTitle title={props.title} path={props.path} />
        </div>
        <div className="flex-big">
          {newBooks && newBooks.length ?
            isDesktop ?

              newBooks.slice(0, bookPerView).map((book, i) => {
                return (
                  <BookSquare key={i} book={book} index={i} instantPlay={props.path === 'mybooks'} />
                );
              })
              :
              <button className="responsive-box-button" aria-label='' onClick={() => { history.push(props.path) }}>
                <div className={`gray-background no-curve ${isDesktop ? "web-version" : ''}`}>
                  <div className="grid-inner-container">

                    {(newBooks as any[]).slice(0, bookPerView).map((book, i) => {
                      const cover = findCover(book.book_name);
                      return (
                        cover ?
                          <img src={cover} key={i} className='book-image' />
                          :
                          <BookSquare book={book} key={i} index={i} className='book-image' />

                      )
                    })}
                  </div>

                </div>

              </button>
            : <>
              <div className="grey-book home-img">
                {NO_BOOK}
              </div>
              <div className="grey-book home-img"></div>
              <div className="grey-book home-img"></div>
            </>}
        </div>
      </div>

    </div>
  ));
};

export default BookSlider;

import { Link, useLocation } from "react-router-dom";
import React, { useContext, useState } from "react";
import { InputContext } from "../ctx/InputContext";
import { FOOTER_LINKS, FOOTER_WEB_LINKS } from '../consts/footer.const';
import { isIOS } from "react-device-detect";
import { useMediaQuery } from "@material-ui/core";
import usePlayer from "../ctx/PlayerContext";

/*This is the mobile navbar component. It allows the user to toggle between the library, bookshelf, home, and profile.*/
export default function Footer(props) {

  const location = useLocation().pathname;
  const playerctx = usePlayer();
  const { popupOpen } = useContext(InputContext);

  const mobile = useMediaQuery('(max-width: 768px)');

  /*If the player is open, pressing one of the footer icons minimizes the player.*/
  // const minimizePlayer = (e) => {
  //   if (playerctx.ifPlayer) {
  //     playerctx.setLittlePlayerAnimtion(true);
  //     setTimeout(() => {
  //       playerctx.setLittlePlayer(true);
  //     }, 399);
  //   }
  //   if (popupOpen) e.preventDefault();
  // };

  // const getClassName = (to) => {
  //   // if (to === "/") return `${location === "/" ? "currentPage" : ""}`;
  //   if (to === "/") return `${location.startsWith("/catalog") || location === "/" ? "currentPage" : ""}`;
  //   else if (to === "/mybooks") return `${location === "/mybooks" ? "currentPage" : ""}`;
  //   else if (to === "/profile") return `${location === "/profile" || location === "/contactus" || location === "/settings" ? "currentPage" : ""} `;
  // };

  return (
    <React.Fragment>

      <div className={`footer-web ${playerctx.littlePlayer ? "footer-web-with-bottom-audio" : ""} ${props.contactUs ? "contactUs-footer-audio" : ""} ${props.noPositionFixed ? "" : "footer__position-fixed"}`}>
        <div className="logos">
          {FOOTER_WEB_LINKS.map((item) =>
            <a {...item.a}>
              <img {...item.img} className="img-footer-web" />
            </a>
          )}
        </div>
        <p className="text-footer-web">
          כל הזכויות שמורות לספריה לעוורים ולקויי ראייה ולפרוייקט ספריה בהקשבה
        </p>
        <div style={{ width: "10vw", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <a className="link-footer-web" href="/privacy" key="Privacy" >
            פרטיות
          </a>

          <a className="link-footer-web" href="/termsofuse" key="Terms of Use" >
            תנאי שימוש
          </a>
        </div>
      </div>

    </React.Fragment >
  );
}

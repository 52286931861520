import React, { useEffect, useRef, useState } from 'react';

/*Function that takes in text and box size and keeps the text inside the box.*/
function Box(props) {
    const [em, setEm] = useState(1);

    const outside = useRef();
    const inside = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (outside.current) {
            if (outside.current.getBoundingClientRect().height < inside.current.getBoundingClientRect().height) {
                updateFontSize()
            }}
        }, 100)
    }, [em])

    const updateFontSize = () => {
        setEm(prev => { return prev - 0.07 })
    }

    return (
        <div className={props.containerClassName} ref={outside}
            style={{
                width: props.width,
                height: props.height,
                fontSize: props.fontSize,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <div ref={inside} className={props.className} style={{ transition: '0.1s linear', fontSize: em + 'em', width: 'fit-content' }} >{props.text}</div>
        </div>
    )
}

export default Box;
import React, { useContext, useEffect, useRef, useState } from 'react'
import ChapterChangeArrows from "../components/ChapterChangeArrows";
import { ClosePlayer } from "../components/ClosePlayer";
import CircleControls from "react-player-circle-controls"; // circle player// circle player
import { FocusTrap } from '../../components/FocusTrap';
import ChangeTime from "../components/ChangeTime"
import Cover from "../components/Cover";
import Move from "../components/Move.jsx";
import "react-player-circle-controls/dist/styles.css";
import "../style/player.scss";
import { DetectDeviceContext } from '../../ctx/DetectDeviceContext';
import CircleLoading from "../../components/Loader.jsx";
import { LoadingBalls } from '../../components/LoadingBalls';
import GenericLoading from '../../components/GenericLoading';


/*This page displays the player when it is in full screen mode.*/
export default function BigPlayer(props) {
    const popup = useRef(null);
    const { isDesktop } = useContext(DetectDeviceContext);
    const [loadingTime, setLoadingTime] = useState(true);

    useEffect(() => {
        if (props.getTime("getDuration") !== "NaN:NaN") {
            setLoadingTime(false);
        }
    }, [props.getTime("getDuration")])

    return (
        <>
            {/* <FocusTrap trapRef={popup} reFocus={true} activationTrigger={props.trapActivationTrigger} > */}
                {/* <div className={!props.littlePlayer ? "opacity" : ""}></div> */}
                <div ref={popup} role="dialog" className={`all ${!props.littlePlayerAnimtion ? "scale-in-ver-bottom" : ""}`}>
                    <ClosePlayer
                        setUrlChapters={props.setUrlChapters}
                        // firstTime={props.firstTime}
                        loading={!props.littlePlayer || props.miniLoading }
                        player={props.player}
                        setBookIdCopy={props.setBookIdCopy}
                        setChapterCopy={props.setChapterCopy} />

                    <button aria-label='הקטנת נגן' className="arrow-button" onClick={!props.miniLoading ? props.littleScreen : undefined} >
                        <img src="/media/icons/arrowDown.svg"
                            alt="חץ כלפי מטה"
                            className="arrow-button--img"
                            style={!props.miniLoading ? { cursor: "pointer" } : {}} />
                    </button>
                    {/* <FocusTrap trapRef={popup} reFocus={true} activationTrigger={props.trapActivationTrigger} > */}

                    <div className="book-contain">
                        <img src={isDesktop ? "/media/backgroundImgs/redBoxWeb.png" : "/media/backgroundImgs/redBox.png"}
                            alt=""
                            className="redBox"
                            id="redBox" />
                        <div className="book">
                            {isDesktop ? (
                                <h4 className={`bTitle ${props.nameBook.length > 30 ? 'spotifyAnimation' : ''}`}>{props.nameBook}</h4>
                            ) : (
                                <h4 className={`bTitle ${props.nameBook.length > 24 ? 'spotifyAnimation' : ''}`}>{props.nameBook}</h4>
                            )}
                            {isDesktop ? (
                                <h5 className="bAuthor">{props.nameAuthor}</h5>
                            ) : (
                                <h5 className="bAuthor">
                                    <Move rounds="0" speed="4" text={props.nameAuthor} timeToStart={2000} width={"70vw"} textClassName="" />
                                </h5>
                            )}
                            <div className={`book-img1 lightblue`}>
                                <Cover />
                            </div>
                            {/* {console.log("props", props.leftTime())} */}
                            <p className="bTime">{props.leftTime()}</p>
                        </div>
                    </div>
                    <div className="bar">
                        <button className="chapter-con"
                            aria-label='מעבר לרשימת הפרקים'
                            onClick={() => !props.miniLoading ? props.setShowChapters(true) : undefined}
                            disabled={props.miniLoading}
                            style={!props.miniLoading ? { cursor: "pointer" } : {}}>
                            <img src="/media/icons/chapter.svg"
                                alt="כפתור לפתיחת רשימת הפרקים בספר"
                                className="chapter"
                                style={!props.miniLoading ? { cursor: "pointer" } : {}} />
                            <span className="tinySpeed" style={!props.miniLoading ? { cursor: "pointer" } : {}}>
                                קטעים
                            </span>
                        </button>
                        <div className="pre" style={!props.miniLoading ? { cursor: "pointer" } : {}}>
                            <ChapterChangeArrows miniLoading={props.miniLoading} move={"next"} />
                            <p> קטע {props.chapter} </p>
                            <ChapterChangeArrows miniLoading={props.miniLoading} move={"previous"} />
                        </div>
                        <button className="speed"
                            aria-label="מעבר לבחירת מהירות השמע"
                            onClick={() => props.setShowSpeeds(true)}>
                            <span className="number">{props.speed}x</span>
                            <span className="tinySpeed">מהירות</span>
                        </button>
                    </div>
                    <div className="goto">
                        <ChangeTime
                            player={props.player}
                            setPlaying={props.setPlaying}
                            setRatingPopup={props.setRatingPopup}
                            setPopupOpen={props.setPopupOpen}
                            move={"forward"} />
                        <div className="play-and-time">
                            <p aria-label='אורך הפרק' className="center_player mb" >{loadingTime ? <GenericLoading big={true} /> : props.getTime("getDuration")}</p>
                            <div className={props.miniLoading ? "noIconPlayer" : undefined}>
                                <CircleControls
                                    played={props.played}
                                    loaded={props.loaded}
                                    playing={props.playing}
                                    onSeek={props.onSeek}
                                    onTogglePlaying={loadingTime ? () => props.setPlaying(false) : () => props.setPlaying(!props.playing)}
                                />
                            </div>
                            <p role='timer' aria-label='הזמן הנוכחי בפרק' className="pink center_player">{props.getTime("getCurrentTime")}</p>
                        </div>
                        <ChangeTime
                            player={props.player}
                            setPlaying={props.setPlaying}
                            setRatingPopup={props.setRatingPopup}
                            setPopupOpen={props.setPopupOpen}
                            move={"rewind"} />
                    </div>
                    {/* </FocusTrap> */}

                </div>
            {/* </FocusTrap> */}
        </>
    )
}

import React, { useState, useContext } from "react";
import usePlayer from "../../ctx/PlayerContext";
import { InputContext } from "../../ctx/InputContext";
import { useGenAlert } from "../../ctx/GenAlertContext";
import ErrorFunction from "../../errorFunction";
import Axios from "axios";
import Stars from "./Stars";
import "../style/popup-rating.scss";
import { GenericButton } from "../../components/GenericButton";
import { DetectDeviceContext } from "../../ctx/DetectDeviceContext";

/*This component displays a popup when the student finishes listening to a book with 
5 fields to fill in- a header (describing how they felt about the book in short), 
a full review, and star ratings for story, quality, and general (can rate from 0.5-5).*/
function PopupRating(props) {
  const { isDesktop } = useContext(DetectDeviceContext);

  const { setPopupOpen } = useContext(InputContext);

  const [genRating, setGenRating] = useState(0);
  const [qualityRating, setQualityRating] = useState(0);
  const [storyRating, setStoryRating] = useState(0);

  const [ratingHeader, setRatingHeader] = useState("");
  const [ratingReview, setratingReview] = useState("");

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);


  const playerctx = usePlayer();
  const genAlertCtx = useGenAlert();

  /*Makes sure the student added a general rating.*/
  const validateGen = () => {
    if (genRating === 0) {
      return "אנא הכנס דירוג";
    }
  };

  /*Makes sure the student added a quality rating.*/
  const validateQuality = () => {
    if (qualityRating === 0) {
      return "אנא הכנס דירוג";
    }
  };

  /*Makes sure the student added a story rating.*/
  const validateStory = () => {
    if (storyRating === 0) {
      return "אנא הכנס דירוג";
    }
  };

  /*Validates the header by making sure that is is neither empty or too long.*/
  const validateHeader = () => {
    if (ratingHeader.length === 0) {
      return "אנא הכנס כותרת לביקורתך";
    }
    else if (ratingHeader.length > 25) {
      return "הכותרת ארוכה מדי";
    }
  };

  /*Validates the full review by making sure that is is neither empty or too long.*/
  const validateReview = () => {
    if (ratingReview.length === 0) {
      return "אנא הכנס ביקורתך";
    }
    else if (ratingReview.length > 500) {
      return "הביקורת ארוכה מדי";
    }
  };

  /*Checks if there are any errors in any of the fields. If there are not, it adds 
    the student's rating to the database, and it will show up on the book page.*/
  const validateAll = async () => {
    setSubmitted(true);
    let genE = validateGen();
    let qualityE = validateQuality();
    let storyE = validateStory();
    let headerE = validateHeader();
    let reviewE = validateReview();

    setErrors({
      gen: genE,
      quality: qualityE,
      story: storyE,
      header: headerE,
      review: reviewE,
    });


    if (!genE && !qualityE && !storyE && !headerE && !reviewE) {
      props.setRatingPopup(false);
      try {
        await Axios.post("/api/book-student/rate-book", {
          bookStudentId: playerctx.bookStudentId,
          header: ratingHeader,
          review: ratingReview,
          genRating: genRating,
          qualityRating: qualityRating,
          storyRating: storyRating,
        });
      } catch (error) {
        genAlertCtx.openGenAlert({ text: ErrorFunction(error) });
      }
    }
  };

  const setField = (value) => {
    if (value === " " || value === ".") {
      value = "";
    }
    return value;
  };

  return (
    <div className={isDesktop ? "" : "phone-version"}>
      <div className="popup-background rating-popup">
        {/* <div className="errorServer">{error || playerctx.error}</div> */}
        <div className="bounce-7">
          <div className="saved-popup">
            <GenericButton
              style={{ backgroundColor: "inherit" }}
              ariaLabel="סגור"
              className="close-popup"
              onClick={() => { props.setRatingPopup(false); setPopupOpen(false); }}>
              <img
                src="/media/icons/xButton.svg"
                alt="סגור"
                className="popup-icon"
              />
            </GenericButton>
            <div className="schoolTitle">דירוג</div>
            <div className="popup-content">
              <p className="input-titles">אנא תדרג את הספר</p>
              {isDesktop ?
                //To the phone
                <div className="review-fields">
                  <div className="ratings-flex">
                    <div>
                      <div className="popup-input">
                        <div className="errors-flex">
                          <div className="header-flex">
                            <label htmlFor="titleInput" className="input-titles">כותרת:</label>
                            <input type="text" id="titleInput"
                              className={`inputs ${errors.header ? "error-border" : "no-error-border"}`}
                              name="search" placeholder="" value={ratingHeader}
                              onChange={(e) => { setRatingHeader(setField(e.target.value)); }}
                              autoComplete="off" />
                          </div>
                          <div className="error review" aria-live="assertive" >{submitted ? errors.header : ""}</div>
                        </div>
                      </div>
                      <Stars gen={errors.gen} genRating={genRating} setGenRating={setGenRating} submitted={submitted} quality={errors.quality} qualityRating={qualityRating} setQualityRating={setQualityRating} story={errors.story} storyRating={storyRating} setStoryRating={setStoryRating} />
                    </div>
                    <div className="popup-input big-flex">
                      <p className="input-titles">הביקורת שלך:</p>
                      <div className="errors-flex">
                        <textarea type="text" id="feedbackInput"
                          className={`inputs ${errors.review ? "error-border" : "no-error-border"}`}
                          name="search" placeholder="" value={ratingReview}
                          onChange={(e) => { setratingReview(setField(e.target.value)); }}
                          autoComplete="off" />
                        <div className="error review" aria-live="assertive" >{submitted ? errors.review : ""}</div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                //To computer
                <div className="review-fields">
                  <div className="popup-input big-flex">

                    <div className="errors-flex">
                      <label htmlFor="titleInput" className="input-titles">כותרת:</label>
                      <input
                        type="text"
                        id="titleInput"
                        className={`inputs ${errors.header ? "error-border" : "no-error-border"}`}
                        name="titleInput"
                        placeholder="" value={ratingHeader}
                        onChange={(e) => { setRatingHeader(setField(e.target.value)); }}
                        autoComplete="off"
                      />
                      <div className="error review" aria-live="assertive" >{submitted ? errors.header : ""}</div>
                    </div>
                  </div>
                  <div className="popup-input big-flex">
                    <p className="input-titles">הביקורת שלך:</p>
                    <div className="errors-flex">
                      <textarea type="text" id="feedbackInput"
                        className={`inputs ${errors.review ? "error-border" : "no-error-border"}`}
                        name="search" placeholder="" value={ratingReview}
                        onChange={(e) => { setratingReview(setField(e.target.value)); }}
                        autoComplete="off" />
                      <div className="error review" aria-live="assertive" >{submitted ? errors.review : ""}</div>
                    </div>
                  </div>
                  <Stars
                    gen={errors.gen}
                    genRating={genRating}
                    setGenRating={setGenRating}
                    submitted={submitted}
                    quality={errors.quality}
                    qualityRating={qualityRating}
                    setQualityRating={setQualityRating}
                    story={errors.story}
                    storyRating={storyRating}
                    setStoryRating={setStoryRating} />
                </div>
              }
              <GenericButton
                ariaLabel="שלח"
                text="שלח"
                className="popup-button"
                onClick={() => { validateAll(); setPopupOpen(false); }}>
              </GenericButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PopupRating;
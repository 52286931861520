import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useContext, useState } from 'react';
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import "../style/qAndA.scss";
import Question from "../components/Question";

const QAndA = () => {

    const { isMobile } = useContext(DetectDeviceContext);

    const QAndATexts = [
        {
            title: "כללי",
            color: "red",
            questions:
                [{
                    first: true,
                    question: "מי זכאי להיות מנוי לספריה בהקשבה?",
                    answer: "כל ילד/ה שאובחנו עם קושי בקריאה. הקושי יכול לנבוע מלקות בקריאה, קושי פיזי הקשור בקריאה כגון קשיי קשב וריכוז או עיוורון. האבחנה צריך להתבצע על ידי פסיכולוג חינוכי, רופא, או יועץ חינוכי. הגורם המקצועי חייב לחתום על טופס ההרשמה"
                },
                {
                    first: false,
                    question: "מה התרומה הייחודית של ספריה בהקשבה?",
                    answer: "ספריה בהקשבה היא פלטפורמה הממוקדת בתלמידים המתקשים בקריאה.  האתר צבעוני וידידותי לילדים ופשוט מאד לניווט. הוא מאפשר חיפוש, מעקב  אחרי קריאה והמלצה על ספרים חדשים. נוסף לכך,  מתוכנן גם מודול בקרה אשר יאפשר  למורים לעקוב אחרי התקדמות התלמידים על מנת לעודד ולסייע בצורה מדויקת ויעילה. "
                }]
        },
        {
            title: "הליכי הרשמה",
            color: "yellow",
            questions:
                [{
                    first: true,
                    question: "למה צריך למלא גם טופס מקוון וגם טופס פיזי?",
                    answer: "הטופס המקוון נדרש לצורך מעקב ורישום נח בספריה. הטופס הפיזי החתום מהווה מסמך קביל המאשר שהתלמיד זכאי על פי חוק לגישה לתוכן של הספריה. "
                },
                {
                    first: false,
                    question: "מי צריך לשלוח את הטופס המלא לספריה?",
                    answer: "לאחר שהטופס מלא וחתום על ידי ההורה וגורם המאבחן ניתן לשלוח לספריה דרך מספר ה   whatsapp  054-866-3452 או למייל  info@clfb.org.il. זה יכול להישלח על ידי ההורה או הצוות החינוכי."
                },
                {
                    first: false,
                    question: "אחרי שאני ממלא/ת את הטופס המקוון ושולח/ת את הטופס הפיזי לספריה מה השלב הבאה?",
                    answer: "נציג הספריה ייצור איתך קשר במייל לקבלת שם תקבל סיסמא וקישור לספריה בהקשבה"
                },
                {
                    first: false,
                    question: "מלאתי את הטופס המקוון מזמן ועכשיו יש לי טופס פיזי. מה עלי לעשות?",
                    answer: "שלחו את הטופס המלא וצרו קשר עם הספריה לוודא שהכל מסודר. ניתן לסרוק את הקוד או לשלוח למספר  054-866-3452"
                },
                {
                    first: false,
                    question: "יש לי אבחון שמוכיח את זכאות הילד/ה שלי לספריה. האם ניתן לשלוח את זה במקום להחתים את גורם המקצועי המאבחן?",
                    answer: "כן. אם קל יותר לשלוח את האבחון, תמלאו רק את החלק של ההורה ותחתימו עליו ואז שלחו את הטופס הפיזי ביחד עם האבחון לספריה."
                }]
        },
        {
            title: "שימוש שוטף",
            color: "blue",
            questions:
                [{
                    first: true,
                    question: "האם צריך להכניס סיסמא בכל פעם שנכנסים לספריה בהקשבה?",
                    answer: "לא. צריך להכניס  שם וסיסמא רק פעם אחת לכל מכשיר (טלפון או מחשב) , ואז בהתאם להגדרות המכשיר, הוא יזכור אותך גם בעתיד."
                },
                {
                    first: false,
                    question: "האם יש הגבלה לכמות המכשירים שאיתם אפשר להיכנס?",
                    answer: "לא, אין הגבלה"
                },
                {
                    first: false,
                    question: "האם אפשר למסור את הסיסמה שלי למישהו אחר?",
                    answer: "לא, אסור. הסיסמא היא אישית ולשימושך בלבד."
                }]
        }
    ]

    return (
        <div className="q-a-page">
            <Header title="שאלות ותשובות" />
            {isMobile ? "" : <div className="q-a-title">שאלות ותשובות</div>}
            <div className="q-a-texts">
                {QAndATexts.map((item) => {
                    return (
                        <div className="q-a-part">
                            <div className="q-a-text-title">
                                {item.title}
                                <div className={`q-a-${item.color}`}></div>
                            </div>

                            {item.questions.map((q) => {
                                return (
                                    <Question question={q.question} answer={q.answer} first={q.first} />
                                )
                            })}

                        </div>
                    )
                })}
            </div>

            {isMobile ? "" : <Footer publicR={true}/>}
        </div >
    );
};

export default QAndA;
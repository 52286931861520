import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import { provide } from "@hilma/tools";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas as icons } from '@fortawesome/free-solid-svg-icons';
import { UserProvider } from "./ctx/UserContext";
import { unregister } from "./serviceWorker";

const currVH = window.innerHeight / 100;
const currVW = window.innerWidth / 100;
document.documentElement.style.setProperty("--vh", `${currVH}px`);
document.documentElement.style.setProperty("--vw", `${currVW}px`);

window.addEventListener("orientationchange", function () {
  let vh = document.documentElement.style.getPropertyValue("--vh");
  let vw = document.documentElement.style.getPropertyValue("--vw");
  document.documentElement.style.setProperty("--vh", vw);
  document.documentElement.style.setProperty("--vw", vh);
});
library.add(icons);

ReactDOM.render(
  <UserProvider>
    <App />
  </UserProvider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();

import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../ctx/UserContext";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { useObserver } from "mobx-react-lite";
import { Link } from "react-router-dom";
import CircleLoading from "../components/Loader.jsx";
import Book from "./Book";
import "./../style/bookshelf.scss";
import { BOOKS_PAGE_PARTS, CATEGORIES, SET_STATE_PAGE_PARTS } from "../consts/book-shelf.const";
import { LogOut } from "../components/LogOut";
import { Skeleton } from "@mui/material";
import { GenericButton } from "../components/GenericButton";
import { IconButton } from "@material-ui/core";
import Axios from "axios";
import GenericPopup from "../components/GenericPopup";
import { useBooks } from "../stores/Books.store";
import Header from "../components/Header";
import SecondHeader from "../components/SecondHeader";
import usePlayer from "../ctx/PlayerContext";
import { isWindows } from "react-device-detect";


/*This is the bookshelf page, both for students and for teachers. If the pages is in browser, it will have a play button
that opens the player, while clicking the book image opens the book page. On a phone, clicking on the image opens the player.*/
export default function Bookshelf(props) {
  const { isDesktop } = useContext(DetectDeviceContext);
  const [pressed, setPressed] = useState(0);
  const [boldCategory, setBoldCategory] = useState(0);
  let currentCategory = CATEGORIES[pressed];

  const playerctx = usePlayer();
  const userctx = useContext(UserContext);
  const BookStore = useBooks();
  const [, setMyBooks] = useState(BookStore.myBooks);
  const [open, setOpen] = useState(false);
  const [currentDeleteBookId, setCurrentDeleteBookId] = useState(null);

  const [skeletonWidth, setSkeletonWidth] = useState();
  const [skeletonMarginRight, setSkeletonMarginRight] = useState();
  const [skeletonCube, setSkeletonCube] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (BookStore.myBooks.length !== 0) {
      setLoading(false);
    }

  }, [BookStore.myBooks?.length])

  useEffect(() => {
    sessionStorage.removeItem("searchInput"); // clear the unput
    if (BookStore.search) {
      BookStore.clearSearch(true);
    }
    //
    setMyBooks(BookStore.myBooks);
    return () => {
      if (BookStore.ifError) {
        BookStore.error = "";
        BookStore.ifError = false;
      }
    };
  }, []);


  //for skeleton
  useEffect(() => {
    if (isDesktop) {
      setSkeletonWidth('25vw');
      setSkeletonMarginRight('15vh');
      setSkeletonCube('16vw');
      // setSkeletonTitle('35vw')
    } else {
      setSkeletonWidth('45vw');
      setSkeletonMarginRight('1vh');
      setSkeletonCube('42vw');
      // setSkeletonTitle('55vw')
    }
  }, [isDesktop]);


  useEffect(() => {
    if (playerctx.isFinished) {
      let index = BookStore.progressBooks.findIndex(
        (item) => item.id === playerctx.bookId
      );
      if (BookStore.progressBooks[index]) {
        let book = BookStore.progressBooks[index];
        book.book_finished = true;

        let restProgress = BookStore.progressBooks.filter(
          (item) => item.id !== playerctx.bookId
        );

        BookStore.progressBooks = restProgress;
        BookStore.doneBooks.push(book);
      }
    }
  }, [playerctx.isFinished]);

  useEffect(() => {
    if (playerctx.startPlay) {
      let index = BookStore.notStartedBooks.findIndex(
        (item) => item.id === playerctx.bookId
      );
      if (BookStore.notStartedBooks[index]) {
        let book = BookStore.notStartedBooks[index];

        let restNotStarted = BookStore.notStartedBooks.filter(
          (item) => item.id !== playerctx.bookId
        );

        BookStore.notStartedBooks = restNotStarted;
        BookStore.progressBooks.push(book);
      }
    }

  }, [playerctx.startPlay]);


  /*This function allows the student to filter books by status; They can choose to see the books that they have finished, 
    the books that they are in the middle of, and the books that they have yet to begin, or all of their books together.*/
  function setCategory(item) {
    if (item === 1) {
      setPressed(1);
    } else {
      if (item === 2) {
        setPressed(2);
      } else {
        if (item === 3) {
          setPressed(3);
        }
        if (item === 0) {
          setPressed(0);
        }
      }
    }
    setBoldCategory(item);
  }

  //removes book from book shelf
  async function removeBook() {

    try {

      //deletes from data base
      await Axios.post('/api/user/removeBook', {
        book: currentDeleteBookId
      });


      //removes from store (either from notStartedBooks , progressBooks or doneBooks)

      if (BookStore.notStartedBooks.some((item) => item.id === currentDeleteBookId)) {
        let newBookStore = BookStore.notStartedBooks.filter((item) => item.id !== currentDeleteBookId);
        BookStore.notStartedBooks = newBookStore;

      } else if (BookStore.progressBooks.some((item) => item.id === currentDeleteBookId)) {
        let newBookStore = BookStore.progressBooks.filter((item) => item.id !== currentDeleteBookId);
        BookStore.progressBooks = newBookStore;

      } else {
        let newBookStore = BookStore.doneBooks.filter((item) => item.id !== currentDeleteBookId);
        BookStore.doneBooks = newBookStore;
      }

      if (BookStore.myBooks.some((item) => item.id === currentDeleteBookId)) {
        let myBooks = BookStore.myBooks.filter((item) => item.id !== currentDeleteBookId);
        BookStore.myBooks = myBooks;
      }

      setOpen(false);
      setCurrentDeleteBookId(null);

    }
    catch (error) {
      console.error(error, 'error');
    }
  }

  const [newDoneBooks, setNewDoneBooks] = useState([{}]);

  useEffect(() => {
    const books = [...BookStore.doneBooks];

    const bookTitlesThatExist = [];

    const filterBooks = [];
    books.map((element) => {
      if (!bookTitlesThatExist.includes(element?.book_name)) {
        bookTitlesThatExist.push(element?.book_name)
        filterBooks.push(element);
      }
    });

    setNewDoneBooks(filterBooks);
  }, [BookStore.doneBooks])


  return useObserver(() => (
    <React.Fragment>
      <div className={`bs ${isDesktop ? "web-version" : undefined}`} /*style={{ overflow: "auto", overflow: "overlay" }}*/>

        <div className={playerctx.littlePlayer ? "player-phone" : ""}>
          <SecondHeader type='mybooks' title="מדף הספרים שלי" />
          {/* {loading ? */}
          {/* 
            <div style={{ display: 'flex', marginTop: '2vh', marginBottom: '7vh', marginRight: '8vw' }}>
              <Skeleton sx={{ borderRadius: '5vh', marginLeft: "1vw" }} variant="rectangular" width={'8vw'} height={'6.5vh'} animation='wave' />
              <Skeleton sx={{ borderRadius: '5vh', marginLeft: "1vw" }} variant="rectangular" width={'8vw'} height={'6.5vh'} animation='wave' />
              <Skeleton sx={{ borderRadius: '5vh', marginLeft: "1vw" }} variant="rectangular" width={'8vw'} height={'6.5vh'} animation='wave' />
              <Skeleton sx={{ borderRadius: '5vh', marginLeft: "1vw" }} variant="rectangular" width={'8vw'} height={'6.5vh'} animation='wave' />
            </div>
            : */}
          {userctx.profileType === 'student' ?
            <div className="second-header-categories">
              {CATEGORIES.map(item =>
                <button key={item.value}
                  className='second-header-category'
                  id={boldCategory === item.value ? "bold" : "non-bold"}
                  value={item.value}
                  aria-label={item.text}
                  onClick={() => setCategory(item.value)}>
                  {item.text}
                </button>
              )}
            </div>
            :
            null

          }

          {BookStore.isEmpty ? (
            <div className="bs-no-books">
              <div className="no-books-text">
                עדיין אין לך ספרים על המדף.
                <br />
                <Link to={"/"} className="no-books-link"> בקר בספריה</Link>
                &nbsp;ובחר לך את ספר השמע הראשון שלך.
              </div>
            </div>)
            : BookStore.ifError ? (
              <div className="no-results-container">
                <img
                  className="sad-emoji"
                  src={"/media/images/noResultsWorm.svg"}
                  alt="אין תוצאות, אייקון של תולעת ספרים"
                />
                {/* Credits: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
                <p className="no-result-error">
                  {BookStore.error}
                </p>
              </div>
            ) :
              BookStore.loading
                ? (
                  <div style={{ marginBottom: '7vh', marginTop: '4vh' }}>

                    <div style={{ display: 'flex' }}>
                      <Skeleton sx={{ borderRadius: '5px', marginLeft: '3vw', marginRight: skeletonMarginRight, marginBottom: '5vh' }} variant="rectangular" width={skeletonCube} height={skeletonCube} animation='wave' />
                      <div style={{ display: 'flex', width: '50vw', justifyContent: 'space-between', alignItems: 'center', marginTop: '-5vh' }}>
                        <div>
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '2vh' }} variant="rectangular" width={skeletonWidth} height={'3.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '2vh' }} variant="rectangular" width={skeletonWidth} height={'1.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={'15vw'} height={'1.5vh'} animation='wave' />

                        </div>
                        {isDesktop &&
                          <Skeleton sx={{ borderRadius: '100px' }} variant="circle" width={'4vw'} height={'4vw'} animation='wave' />
                        }
                      </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Skeleton sx={{ borderRadius: '5px', marginLeft: '3vw', marginRight: skeletonMarginRight, marginBottom: '5vh' }} variant="rectangular" width={skeletonCube} height={skeletonCube} animation='wave' />
                      <div style={{ display: 'flex', width: '50vw', justifyContent: 'space-between', alignItems: 'center', marginTop: '-5vh' }}>
                        <div>
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '2vh' }} variant="rectangular" width={skeletonWidth} height={'3.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={skeletonWidth} height={'1.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '2vh' }} variant="rectangular" width={skeletonWidth} height={'1.5vh'} animation='wave' />
                          <Skeleton sx={{ borderRadius: '5px', marginBottom: '1vh' }} variant="rectangular" width={'15vw'} height={'1.5vh'} animation='wave' />
                        </div>
                        {isDesktop &&
                          <Skeleton sx={{ borderRadius: '100px' }} variant="circle" width={'4.5vw'} height={'4.5vw'} animation='wave' />
                        }
                      </div>
                    </div>
                  </div>)
                :
                userctx.profileType === 'student' ? (
                  <div style={{ paddingBottom: "14vh" }}>
                    {/* 1 */}

                    {!BookStore.isBookshelfEmpty ?
                      (BOOKS_PAGE_PARTS.map(item =>
                        (item.operator === ">" ? BookStore[item.bookStorePart].length > 0 : BookStore[item.bookStorePart].length !== 0)
                          && (pressed === item.pressed1 || pressed === item.pressed2) ? (
                          <div key={item.title}/*  style={{ marginBottom: '20vh' }} */>
                            <p className="bs-title">{item.title}</p>
                            <div className={item.cla}>

                              {(item.bookStorePart === "doneBooks" ? newDoneBooks : BookStore[item.bookStorePart]).map((book, index) => (
                                < div className="book-and-trash-container">
                                  {book.book_finished ? '' :
                                    <IconButton onClick={() => { setCurrentDeleteBookId(book.id); setOpen(true); }} className='icon-button-mui' aria-label="remove book from my book shelf">
                                      <img className='book-shelf-trash' alt='remove book from my book shelf' src='/media/icons/bin.svg'></img>
                                    </IconButton>
                                  }
                                  <div className='bookshelf-book'>
                                    <Book
                                      book={book}
                                      key={index + 1}
                                      index={index}
                                      progress={item.progress}
                                      lastBook={item.lastBook ? BookStore[item.lastBook].length - 1 === index : undefined}
                                      {...BookStore} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null

                      )) :
                      <div className="no-results-container">
                        <img
                          className="sad-emoji"
                          src={"/media/images/noResultsWorm.svg"}
                          alt="אין תוצאות, אייקון של תולעת ספרים"
                        />
                        {/* Credits: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
                        <p style={{ fontSize: '2.5vh' }} className="no-result-error">
                          לא נמצאו תוצאות לחיפוש
                        </p>
                      </div>
                    }


                    {

                      (!Boolean(BookStore[currentCategory.part].length) && !BookStore.loading) && /* check if in the current category there is books in mobx */
                      <div className="bs-no-books">
                        <div className="no-books-text">{currentCategory.emptyMessage}
                          <br />
                          <span>
                            <Link to={"/"} className="no-books-link">חזור לספריה</Link>
                          </span>
                        </div>
                      </div>
                    }

                    {/* 2 */}
                    {/* {BookStore.notStartedBooks.length === 0 && pressed === 1 ?
                      <div className="bs-no-books">
                        <div className="no-books-text">
                          אין ספרים שעוד לא התחלת.
                          <br />
                          <span>
                            <Link to={"/"} className="no-books-link"> בקר בספריה</Link>
                          </span> ובחר לך ספרים חדשים.
                        </div>
                      </div> : null
                    } */}

                    {/* 3 */}

                    {SET_STATE_PAGE_PARTS.map(item =>
                      BookStore[item.bookStorePart].length === 0 && pressed === 2 ?
                        <div className="bs-no-books" key={item.content1} item={item.pressed}>
                          <div className="no-books-text">
                            {item.content1}
                            <br />
                            {item.content1}
                            <button className="no-books-button" onClick={() => setCategory(item.onClickValue)}>
                              <a className="no-books-link">{item.linkText}</a>
                            </button>{item.content3}
                          </div>
                        </div> : null
                    )}
                  </div>
                ) :
                  <div className="done">
                    {BookStore.myBooks.map((book, index) => (
                      <div key={index}>
                        <Book book={book} key={index + 1} index={index} progress={false} {...BookStore} />
                      </div>
                    ))}
                  </div>
          }
          <GenericPopup open={open}>
            <h3 className='bookshelf-popup-title'>אתה בטוח שברצונך להסיר <br /> ספר זה ממדף הספרים שלך?</h3>
            <div className='bookshelf-popup-buttons-container'>
              <GenericButton style={{ background: '#23CDFD' }} className='bookshelf-popup-button' onClick={() => { removeBook(); }}>אני בטוח</GenericButton>
              <GenericButton className='bookshelf-popup-button' onClick={() => { setOpen(false); }}>התחרטתי</GenericButton>
            </div>
          </GenericPopup>
        </div>
      </div>

    </React.Fragment >
  ));
}

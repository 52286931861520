import { useContext } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { DetectDeviceContext } from '../../ctx/DetectDeviceContext';
import "../../style/privacyAndTerms.scss";


const PrivacyPage = () => {

    const { isMobile } = useContext(DetectDeviceContext);

    const TermsOfUseTexts = [
        {
            title: "מבוא",
            color: "red",
            texts:
                [{
                    numb: "",
                    par: `"ספריה בהקשבה" (להלן: "הספריה"),  מבית הספריה המרכזית לעיוורים ובעלי לקויות קריאה (ע"ר) מבקשת לשמור על פרטיותם של המשתמש באתר האינטרנט המופעל על  ידה באמצעות הכתובת –  www.israelaudiobookproject.org.il  (להלן: "האתר") בהתאם למדיניות הפרטיות המפורטת להלן`,
                }]
        },
        {
            title: "כללי",
            color: "yellow",
            texts:
                [{
                    numb: 1,
                    par: "שימוש והרשמה לאתר הינם בהתאם למדיניות הפרטיות המוגדרת של הספריה ומעידים כי המשתמש קיבל על עצמו את כל תנאיה.",
                },
                {
                    numb: 2,
                    par: "מדיניות הפרטיות מנוסחת בלשון זכר מטעמי נוחות בלבד ומתייחסת לגברים ולנשים כאחד.",
                },
                {
                    numb: 3,
                    par: "הספריה שומרת לעצמה את הזכות לשנות מדיניות פרטיות זו מעת לעת, מכל סיבה וללא כל הודעה מראש.",
                }]
        },
        {
            title: "איסוף מידע",
            color: "blue",
            texts:
                [{
                    numb: 4,
                    par: `במסגרת שימוש המשתמש באתר ו/או מתן שירותי הספריה למשתמש, ייאסף אצל הספריה מידע אודות המשתמש לרבות, אך לא רק, שם מלא, כתובת מגורים, כתובת דוא"ל, מספרי טלפון,  מידע אודות הספרים שהושמעו, קצב שמיעה וכו.`,
                },
                {
                    numb: 5,
                    par: `בנוסף, האתר עושה שימוש ב"עוגיות" (cookies) שנועדו להבטיח רציפות שימוש באתר, וכן אוסף מידע מקוון בסיסי אודות פעילות המשתמש לרבות כתובת IP, דפדפן ופעולות שבוצעו באתר.`,
                },
                {
                    numb: 6,
                    par: "המידע אשר ייאסף כאמור, נועד על מנת לאפשר לספריה לספק את שירותיה למשתמש לרבות מתן תמיכה  ושירות עבור פעולות ההרשמה של המשתמש לאתר, ביצוע פעולות מגוונות באתר, ניהול עדיפויות המשתמש  וסל הקריאה שלו,  פיתוח שירותים המוצעים ו/או אשר יוצעו על ידי הספריה למשתמש.",
                }]
        },
        {
            title: "שמירת מידע וצדדים שלישיים",
            color: "orange",
            texts:
                [{
                    numb: 7,
                    par: "הספריה נוקטת בכל האמצעים הסבירים לשמירת פרטיותם של המשתמשים והגנה על המידע אשר נמסר על ידם או נאסף אודותיהם.",
                },
                {
                    numb: 8,
                    par: "הספריה לא עושה כל שימוש במידע כאמור, אלא לצורך ביצוע פעולות הקשורות עם פעילות המשתמש באתר.",
                },
                {
                    numb: 9,
                    par: `הספריה לא תספק לצד שלישי פרטים אשר ייאספו אודות המשתמש או נמסרו על ידו, למעט לצד שלישי הנוגע ו/או הקשור ישירות למתן שירותיה או קיום מטרותיה של פעילות הספריה או לפי דרישה על פי כל דין. יובהר כי לענין זה,  יימסר מידע לספריה המרכזית לעיוורים ולבעלי מוגבלויות קריאה (ע"ר) , וכן יימסר מידע רלוונטי אודות משתמש לאנשי חינוך, מורים וצוות הוראה הקשורים עמו  ישירות, על פי הרשאתם למיזם ספריה בהקשבה, ובהתאם להסכמת הוריו או אפוטרופסו של  המשתמש כפי שניתנה בעת הרישום לאתר הספריה.`,
                },
                {
                    numb: 10,
                    par: "אתר הספריה עשוי לכלול קישורים לאתרי אינטרנט אחרים המופעלים ומתוחזקים על ידי צדדים שלישיים בהתאם למדיניות השימוש והפרטיות המוגדרות על ידם ולפיכך, הספריה לא תישא בכל אחריות שהיא, ללא יוצא מן הכלל, בגין כל קישור, מידע, שימוש ו/או אחר הנוגע להם מצד המשתמש.",
                },
                {
                    numb: 11,
                    par: `בעת ההרשמה לאתר הספריה, נותן המשתמש את הסכמתו לספריה להשתמש בפרטיו לצורך שליחת פרסומים שיווקיים, אלא אם סימן בטופס הרישום כי אינו מעוניין בדיוור כאמור. המשתמש יהא רשאי להודיע לספריה, בכל עת, על חזרתו מהסכמתו לכך וזאת באמצעות פנייה לדוא"ל info@clfb.org.il.`,
                },
                {
                    numb: 12,
                    par: `בכל שאלה ו/או הבהרה הדרושה למשתמש לעניין מדיניות הפרטיות, ניתן ליצור קשר עם ספריה באמצעות דוא"ל -  info@clfb.org.il`,
                }]
        }
    ]

    return (
        <>
            <Header title="מדיניות פרטיות" />

            <div className="privacy-terms-page">
                {isMobile ? "" : <div className="privacy-terms-title">מדיניות פרטיות</div>}
                <div className="privacy-terms-texts privacy-width">
                    {TermsOfUseTexts.map((item) => {
                        return (
                            <div className="privacy-terms-part">
                                <div className="privacy-terms-text-title">
                                    <span>{item.title}</span>
                                    <div className={`privacy-terms-${item.color}`}></div>
                                </div>

                                <div className="privacy-terms-inside">
                                    {item.texts.map((t) => {
                                        return (
                                            <span className="part"><span style={{ fontWeight: "bold" }}>{t.numb}{t.numb !== "" ? ". " : <></>}</span>{t.par}</span>)
                                    })}
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
            {isMobile ? "" : <Footer />}

        </>
    )
}

export default PrivacyPage;


export const FOOTER_LINKS = [
    {
        to: "/",
        img: "url(/media/icons/magnifyingGlass.svg)",
        content: "ספריה"
    }, {
        to: "/mybooks",
        img: "url(/media/icons/books.svg)",
        content: "הספרים שלי"
    }, {
        to: "/profile",
        img: "url(/media/icons/goodEmployee.svg)",
        content: "פרופיל"
    }
]

export const FOOTER_WEB_LINKS = [
    {
        a: {
            href: "https://www.hilma.tech/",
            target: "_blank",
            rel: "noopener noreferrer",
            key: "logo of Hilma organization"
        },
        img: {
            src: "/media/images/logoHilma.svg",
            alt: "logo of Hilma organization",
            className: "cfimg-hilma",
            style: { cursor: "pointer" }
        }
    }, {
        a: {
            href: undefined,
            target: "",
            rel: "",
            key: "Website logo"
        },
        img: {
            src: "/media/icons/lightBlueIcon.png",
            alt: "Website logo",
            className: "cfimg",
            style: {}
        }
    },
    {
        a: {
            href: "https://www.clfb.org.il/heb/main/",
            target: "_blank",
            rel: "noopener noreferrer",
            key: "blind library logo"
        },
        img: {
            src: "/media/images/blindLibrary.png",
            alt: "blind library logo",
            className: "cfimg",
            style: { cursor: "pointer" }
        }
    }
]
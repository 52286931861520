import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
//CSS for the profile bar 
const DesignedProgressBar = withStyles((theme) => ({
    root: theme.breakpoints.up(768) ? {
        height: 10,
        borderRadius: 10,
        width: "10vw",
    } : {
        height: 10,
        borderRadius: 10,
        width: "30vw",
    },
    colorPrimary: {
        backgroundColor: "#D9D6D6",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#EE5C5C",
    },
    bar1Determinate: {
        backgroundColor: "#EE5C5C",
    }
}))(LinearProgress);

// This function gets a value from each book that determines the progress percentage 
export default function LinearProgressBar(props) {

    let MIN = 0;
    let MAX = props.max;
    const normalise = value => (value - MIN) * 100 / (MAX - MIN);

    return (
        <div className="color">
            <DesignedProgressBar variant="determinate" value={props.value === 1 ? 0 : props.value === props.max ? 100 : normalise(props.value - 1)}
                style={{ transform: "rotate(180deg)", width: '100%' }} />
        </div>
    );
}
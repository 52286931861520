import { useContext } from "react";
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import { useHistory } from "react-router-dom";
import "../style/about.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import downloadServerFile from "../fucntions/download-server-file";

/*This page explains all about the project, including the name, client, credits, creators, etc.*/
export default function About() {

  const { isDesktop, isMobile } = useContext(DetectDeviceContext);
  const history = useHistory()

  const handleDownloadPDF = async () => {
    try {
      return downloadServerFile("file/signup.docx", 'טופס הרשמה  לספריה בהקשבה ');
    }
    catch (error) {
      throw error;
    }
  }

  return (
    // <div className={`open-${isDesktop ? "web" : "phone"}`}>
    <div className="about-page" >
      <Header title="אודות הפרוייקט" />
      <div id="about-container">
        <div tabIndex={0}>
          {!isMobile ?
            <h3 className='project-title'>אודות הפרוייקט</h3>
            : <></>
          }
          <div className='about-content first-part-text'>
            ספריה בהקשבה הינה אתר ובו מאגר עצום של ספרי שמע (אודיו), שפותח במיוחד עבור ילדים המתקשים בקריאה. מטרת המיזם הינה לאפשר לכל ילדה וילד להינות באופן נח ונגיש מהיתרונות הרבים והמוכחים של ספרי אודיו: העשרת  אוצר מילים, פיתוח  חשיבה, הרחבת אופקים והנגשת תוכן ברמה התואמת תלמידים עם לקויי קריאה. בנוסף, הפלטפורמה מאפשרת לתלמידים להאזין  לספר בקלות תוך כדי ביצוע משימות אחרות כגון בזמן הסעה, בהליכה, במשימות בבית או בכל זמן אחר בו הידיים והרגלים עסוקות אבל המוח פנוי.
          </div>
          <div className='worms-container'>
            {[1, 2, 3].map(() => {
              return <img className='worm-img' src='/media/icons/happy-worm.svg' />
            })}
          </div>
        </div>
        <div tabIndex={0} className='about-content'>
          האתר מאפשר לכל ילד.ה בעל לקות קריאה*, לפתוח חשבון אישי בספריה המרכזית, בו ינוהל סל הקריאה שלהם. זוהי פלטפורמה צבעונית וידידותית, המזמינה את המנויים לבוא ולבחור מבין הכותרים הרבים המצויים בה, באופן  הכי פשוט שאפשר, תוך מעקב אחרי ספרים שנקראו, המלצה על ספרים חדשים וכו'.
          <br />
          <br />
          אנו מאמינים כי חשיפה זו  אל עולם הספר המופלא, מרחיבה את הדעת ומגבירה באופן משמעותי את הסיכויי להצלחה, הן בבית הספר והן בחיים.
          <br />
        </div>
        <div className='worms-container'>
          {[1, 2, 3].map(() => {
            return <img className='worm-img' src='/media/icons/happy-worm.svg' />
          })}
        </div>
        <div tabIndex={0} className='about-content'>
          בקרוב האתר ייכלול גם יחידה שלמה המיועדת לאפשר למורים שהורשו לכך לעקוב אחרי התקדמות הילדים, לעודד אותם, ולסייע להם במשימת הקריאה באופן פרטני, איכותי ויעיל.
          <br />
          <br />
          אנו פונים להורים ומורים לקחת חלק פעיל בחשיפת התלמידים לעולם הספר ולהשריש הרגל של שימוש מושכל בזמנם הפנוי  על מנת להפוך אותם  ללומדים נצחיים- life long learners!
          <br />
          <br />
          נשמח שתצטרפו אלינו לעולם הספר המופלא!
          <br />
          <br />
          לקבלת טופס הרשמה
          <a className='link-to-hilma' onClick={handleDownloadPDF}>לחצו כאן</a>.
          <br />
          <br />
          אם יש בידיכם כבר טופס הרשמה,
          <a className='link-to-hilma' href='/signup'>לחצו כאן</a>.
          <br />
          <br />
          *על פי חוק, נדרש מסמך חתום על ידי ההורה ופסיכולוג/יועץ חינוכי המאשר את זכאות התלמיד, על  מנת לאפשר לתלמידים גישה לאלפי ספרי האודיו שיש לנו באתר.
        </div>
        {/* <div className="credits-title">
          זכויות יוצרים:
        </div>
        <div className="credits-list">
          {'Icons made by: '}
          {urls.map((link, i) => (
            <a key={i} target="_blank" href={`https://www.flaticon.com/${link.link}`} title={`${link.name}`} className="link-to-hilma">
              {`${link.name} ${i !== urls.length - 1 ? ',' : ''} `}
            </a>))}
          {"from "}
          <a target="_blank" href="https://www.flaticon.com/" title="Flaticon" className="link-to-hilma">www.flaticon.com</a>
        </div> */}
        {/* <GenericButton isLikeUrl className="link-to-login" onClick={history.goBack} text="חזרה להתחברות" /> */}
      </div>
      {isMobile ? "" : <Footer /*contactUs={true}*/ />}
    </div>
  );
}

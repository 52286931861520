import React, { useContext } from 'react';
import { DetectDeviceContext } from '../ctx/DetectDeviceContext';
import '../style/loader.scss';

/*This component displays a loading circle in various colors, depending on the props handed down to it.*/
export default function CircleLoading(props) {
    const { isDesktop } = useContext(DetectDeviceContext);
    const location = window.location.pathname;
    const inHome = location === "/";

    return (
        <div className="loader-container" style={{ ...props.style }}>
            <div key="loading" className={props.color === "pink" ? "pinkLoader" : props.color === "white" ? "whiteLoader" : "loader"}
                style={{ borderLeftColor: (!isDesktop && inHome) ? "#eeedec" : "white" }}>
            </div>
        </div>
    );
}
import { useMediaQuery } from "@material-ui/core";
import React from "react";

const DetectDeviceContext = React.createContext(); 

const DetectDeviceProvider = ({ children }) => {

    //every time the window is resized, update the device context
    const isMobile = useMediaQuery("(max-width: 768px)");
    const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");
    const isDesktop = useMediaQuery("(min-width: 1025px)");
    
    return (
        <DetectDeviceContext.Provider value={{ isMobile, isTablet, isDesktop }}>
            {children}
        </DetectDeviceContext.Provider>
    );

};

export { DetectDeviceContext, DetectDeviceProvider };


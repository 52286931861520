import React, { useState } from "react";

export const InputContext = React.createContext();

export const InputProvider = ({ children }) => {

  const [keyboardOpen, setKeyboardOpen] = useState(false);

  /*When popups are open, there is sometimes a scroll in the back, which should not appear.
     This gives the page and absolute position, when prevents it from moving completely.*/
  const [popupOpen, setPopupOpen] = useState(false)

  /*This is for when the iPhone toolbar is open- sometimes causes issues with inner height.*/
  const [newVh, setNewVh] = useState(window.innerHeight / 100)

  const ctxValue = {
    keyboardOpen,
    setKeyboardOpen,
    popupOpen,
    setPopupOpen,
    newVh,
    setNewVh,
  };

  return (
    <InputContext.Provider value={ctxValue}>{children}</InputContext.Provider>
  );
};
